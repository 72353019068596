<template>

  <div id="app">
    
    <div v-if="isLoading" class="loader w-100 h-100 d-flex justify-content-center align-items-center position-fixed">
      <img width="100" height="100" src="/static/img/preloader.gif" alt="Loading...">
    </div>
    <!-- <AppSpinner /> -->
    <Header :user="user" @logout="logout" :v-if="!isLoading"/>
    <Courses :courses="courses" :v-if="!isLoading" v-if='this.$route.name !== "registration" && this.$route.name !== "login" && this.$route.name !== "recovery"'/>
    <router-view
      :v-if="!isLoading"
      @buy="buyCrypto"
      @sale="saleCrypto"
      @concl="conclCrypto"
      @deposit="deposit"
      @cash="cashCrypto"
      @convert="convert"
      @verify="verify"
      @del_wallet="delete_wallet"
      @check="getChecks"
      @new_page_one="changePageOne"
      @new_page_lot="changePageLot"
      @new_buy="newBuy"
      @new_deposit="newDeposit"
      @login_user="login_user"
      :courses="courses"
      :user="user"
      :cards="cards"
      :wallets="wallets"
      :cards_count="cardsCount"
      :wallets_count="walletsCount"
      :user_load="onLoadUser"
      :balances="balances"
      :cryptos="crypto"
      :checks="checks"
      :page_one_count="pageOneCount"
      :page_lot_count="pageLotCount"
      :page_one="pageOne"
      :page_lot="pageLot"
      :for_buy="forBuyCrypto"
      :for_deposit="forDeposit"
      :history="history"
      :bank_list="bankList"
      :invoice="invoice"
      :currencies="currencies"
      :currenciess="currencies"
      :courses_list="courses"
      :crypto="crypto"
      />
    <Footer v-if="this.$route.name !== 'registration' && this.$route.name !== 'recovery' && this.$route.name !== 'login'"/>
    <section class="account_menu" v-if="isAccountPage" :v-if="!isLoading">
      <div class='mobile-bottom-menu'>
          <a href="#MyActives">Активы</a>
          <a href="#myChecks">Cчета</a>
          <a href="#conversion-id">Обмен</a>
          <a href="#cardsList">Карты</a>
          <a href="#WalletsList">Кошельки</a>
          <a href="#operation-history-id">История</a>
      </div>
  </section>
    <EnterModal @login_user="login_user" />
    <RegistrationModal @login_user="login_user" />
    <Supportmodal/>

  </div>
</template>

<script>
import axios from "axios";
import "./css/main.css";
import AppSpinner from "@/components/General/AppSpinner.vue";
import Courses from "@/components/CoursesComponent.vue";
import Header from "@/components/General/HeaderComponent.vue";
import Footer from "@/components/General/FooterComponent.vue";
import RegistrationModal from "@/components/Index/RegistrationComponent.vue";
import EnterModal from "@/components/Index//EnterComponent.vue";
import Supportmodal from '@/components/Support-modal.vue'
import {eventBus} from "@/eventBus";
import Vue from "vue";

export default {
  name: "App",
  components: {
    AppSpinner,
    Courses,
    Header,
    Footer,
    RegistrationModal,
    EnterModal,
    Supportmodal,
  },

  data: () => ({
    isLoading: true,
    user: [],
    history: [],
    page: 1,
    forBuyCrypto: {
      transaction_id: 0,
      crypto_id: 0,
      amount: 0,
      status: "",
    },
    forDeposit: {
      deposit_id: 0,
      crypto_id: 0,
      amount: 0,
      status: "",
    },
    pageLot: 1,
    pageOne: 1,
    pageCount: 1,
    pageLotCount: 1,
    pageOneCount: 1,
    historyCount: 0,
    pageLimit: 1000,
    pageLotLimit: 1000,
    pageOneLimit: 1000,
    courses: [],
    cards: [],
    balances: [],
    crypto: [],
    checks: {
      one: {},
      lot: {},
    },
    invoice: "",
    cardsCount: 0,
    wallets: [],
    walletsCount: 0,
    onLoadCards: false,
    onLoadWallets: false,
    onLoadUser: false,
    onLoadCourses: false,
    onLoadHistory: false,
    userData: {},
    bankList: {
      'ru': [],
      'uz': [],
      'kz': [],
      'eu': [],
    },
    currencies: [],
  }),
  methods: {
    // getInvoiceUrl() {
    //   this.getInvoice(parseUrl[2])
    // },
    login_user() {
      this.$router.push("/account");
      this.getInfo();
    },
    logout() {
      this.user = null;
      sessionStorage.clear();
      this.$router.push("/");
    },
    async getInfo() {
      this.isLoading = true;
      document.body.classList.add('no_scroll');
      this.$user_ID.value = sessionStorage.getItem('user_ID');
      this.$status_id.value = sessionStorage.getItem('status_id');
      this.$user_token.value = sessionStorage.getItem('user_token');

      // if (this.$route.path !== "/") {
        await this.getHistory(this.page);
        await this.getCurrencies();
        await this.getChecks();
        await this.getBanks();
        await this.getCourses();
        await this.getCryptos();
        await this.getUser();
      // }
      document.body.classList.remove('no_scroll');
      // setTimeout(() => {
        this.isLoading = false;
      // }, 1500);

    },

    //Получаем счета
    getChecks() {
       axios
        .get(this.$api_address + "/checks",{
          params: {
            type: 'Единоразовый',
            paginate_number: this.pageLotLimit,
            user_id: sessionStorage.getItem('user_ID'),
            token: sessionStorage.getItem('user_token'),
          }
        })
        .then((response) => {
          this.checks["one"] = response.data.data;
        });

      axios
      .get(this.$api_address + "/checks",{
          params: {
            type: 'Многоразовый',
            paginate_number: this.pageLotLimit,
            user_id: sessionStorage.getItem('user_ID'),
            token: sessionStorage.getItem('user_token'),
          }
        })
        .then((response) => {
          this.checks["lot"] = response.data.data;
        });
        
    },

    //Получаем инвойс
    getInvoice(id) {
      return axios
      .get(this.$api_address + "/checks/" + id).then((response) => {
        this.invoice = response.data.data;
        console.log('invoiceee', this.invoice );
      });
    },

    //Получаем крипту
    getCryptos() {
      return axios
        .get(this.$api_address + "/cryptocurrencies")
        .then((response) => {
          this.crypto = response.data.data;
          console.log("crypto: ", this.crypto);
        })
        .catch((error) => {
          console.error("Error fetching crypto:", error);
        })
    },

    //Получаем валюты
    getCurrencies() {
      return axios
        .get(this.$api_address + "/currencies")
        .then((response) => {
          this.currencies = response.data.data;
          this.$globalCurrencies = this.currencies;
          console.log("globalcurrencies: ", this.$globalCurrencies);
        })
        .catch((error) => {
          console.error("Error fetching crypto:", error);
        });
    },

    //getBanks
    async getBanks(){
      const countries = ['ru', 'uz', 'kz', 'eu'];
      try {
        const bankLists = await Promise.all(countries.map(country => 
          axios.get(`${this.$api_address}/banks`, { params: { country } })
        ));
        bankLists.forEach((response, index) => {
          this.bankList[countries[index]] = response.data;
        });
        this.$globalBanks = this.bankList;
      } catch (error) {
        console.error("Ошибка при получении данных: ", error);
      }
    },
    //getUser
    getUser() {
      if (sessionStorage.getItem('user_ID')) {
        eventBus.$emit('turnOnPusher', true);
        this.onLoadUser = true;
        return axios
          .get(this.$api_address + "/users/" + sessionStorage.getItem('user_ID'), {
            params: {
              user_id: sessionStorage.getItem('user_ID'),
              token: sessionStorage.getItem('user_token'),
              status_id: this.$status_id.value
            },
          })
          .then((response) => {
            this.user = response.data.data;
            this.$status_id.value = this.user.status.id;
            this.balances = response.data.data.balances;
            this.cards = response.data.data.cards;
            this.cardsCount = this.cards.length;
            this.wallets = response.data.data.wallets;
            this.walletsCount = this.wallets.length;
            this.onLoadUser = false;
          })
          .catch((error) => {
            console.error("Error fetching user:", error);
            this.onLoadUser = false;
          });
      }
    },

    //getHistory
    getHistory() {
      this.onLoadHistory = true;
      return axios
        .get(this.$api_address + "/history", {
          params: {
            user_id: sessionStorage.getItem('user_ID'),
            token: sessionStorage.getItem('user_token'),
            paginate_number: 100,
          },
        })
        .then((response) => {
          this.history = response.data.data;
          console.log("История: ", this.history);
          this.onLoadHistory = false;
        })
        .catch((error) => {
          console.error("Error fetching history:", error);
          this.onLoadHistory = false;
        });
    },

    // getHistoryCount() {
    //   axios
    //     .get("/history/count")
    //     .then(
    //       (response) => (
    //         (this.historyCount = response.data),
    //         (this.pageCount = Math.ceil(this.historyCount / this.pageLimit))
    //       )
    //     );
    // },

    //getCourses
    getCourses() {
      this.onLoadCourses = true;
      return axios
        .get(this.$api_address + "/courses")
        .then((response) => {
            this.courses = response.data.data;
            this.onLoadCourses = false;
          });
    },

    buyCrypto(data) {
      this.getHistory(this.page);
    },
    conclCrypto(data) {
      this.getHistory(this.page);
    },
    updCourses() {
      this.getCourses();
    },
    deposit() {
      this.getHistory(this.page);
    },
    cashCrypto() {
      this.getHistory(this.page);
    },
    convert() {
      this.getHistory(this.page);
    },
    saleCrypto(data) {
      this.getHistory(this.page);
    },
    newBuy() {
      this.forBuyCrypto.crypto_id = 0;
      this.forBuyCrypto.amount = 0;
      this.forBuyCrypto.status = "";
    },
    newDeposit() {
      this.forDeposit.crypto_id = 0;
      this.forDeposit.amount = 0;
      this.forDeposit.status = "";
    },
 
  delete_wallet(index) {
    this.onLoadWallets = true;
    axios
    .delete(this.$api_address + "/wallets/"+this.wallets[index].id, {
      params: {
        user_id: sessionStorage.getItem('user_ID'),
        token: sessionStorage.getItem('user_token'),
      },
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    })
    .then((response) => {
      this.wallets.splice(index, 1);
      this.walletsCount--;
      this.onLoadWallets = false;
    })
    .catch((error) => {
      // Handle any errors here
      console.error(error);
      this.onLoadWallets = false;
    });
  },

    changePage(page) {
      this.page = page;
      this.getHistory(this.page);
    },
    changePageOne(page) {
      this.pageOne = page;
      this.getChecks();
    },
    changePageLot(page) {
      this.pageLot = page;
      this.getChecks();
    },
    verify() {
      this.getUser();
    },
    invoicePayment(data) {
      if (this.invoice.type == "Единоразовый") {
        this.invoice.status = data;
      } else {
        if (data == "Успех") {
          this.invoice.pay_count++;
        }
      }
    },
    listenChange() {
      this.getHistory();
    },

  },

  computed: {
    isAccountPage() {
        return this.$route.path === '/account';
    }
  },

  //created
  created() {
    this.$watch(
      () => this.$user_ID.value,
      (newValue) => {
        sessionStorage.setItem("user_ID", newValue);
      },
      () => this.$status_id.value,
      (newValue) => {
        sessionStorage.setItem("status_id", newValue);
      },
      () => this.$user_token.value,
      (newValue) => {
        sessionStorage.setItem("user_token", newValue);
      },
      () => this.$session_id.value,
      (newValue) => {
        sessionStorage.setItem("session_id", newValue);
      },

    );

    console.log("$user_ID: ", sessionStorage.getItem('user_ID'));
    console.log("$status_id: ", this.$status_id.value);
    console.log("$user_token: ", sessionStorage.getItem('user_token'));

    if(!sessionStorage.getItem('user_token') && this.$route.name !== 'invoice' && this.$route.name !== 'login' && this.$route.name !== 'registration' && this.$route.name !== 'recovery'
    && this.$route.name !== 'cash' && this.$route.name !== 'api' && this.$route.name !== 'invoices' && this.$route.name !== 'payment-link'  && this.$route.name !== 'contacts' && this.$route.name !== 'terms_of_use' && this.$route.name !== 'aml-kyc' && this.$route.name !== 'pay-link' && this.$route.name !== 'privacy-police' && this.$route.name !== 'cash-exchange') {
      this.logout();
    }

    if(sessionStorage.getItem('user_token')) {
      this.getInfo();
    } else {
      this.getCourses();
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    }

    // if (window.location.pathname.indexOf("invoice") != -1) {
    //   let parseUrl = window.location.pathname.split("/");
    //   this.getInvoice(parseUrl[2]);
    // }

    //Обновление баланса
    eventBus.$on("balanceUpdate", (balance) => {
      let index = this.balances.findIndex(b => b.id == balance.id);
      if(index == -1) {
        return;
      }
      this.balances[index].value = balance.value;
      this.balances[index].frozen_value = balance.frozen_value;
    });

    eventBus.$on("historyUpdate", (operation) => {
      let index = this.history.findIndex(h => h.operation.id == operation.id);
      if(index == -1) {
        return;
      }
      this.history[index].operation = operation;
    });

    eventBus.$on("coursesUpdate", (courses) => {
      this.getCourses();
    });

    eventBus.$on("checkUpdate", (id) => {
      let check;
      axios.get(this.$api_address + "/checks/" + id).then((response) => {
         check = response.data.data;
        if(this.invoice !== undefined) {
          if(this.invoice.id == check.id){
            this.invoice = check;
          }
        };
        if (this.$route.path == "/account") {
            if(check.type == 'Единоразовый') {
              let index = this.checks['one'].findIndex(h => h.id == check.id);
              if(index == -1) {
                return;
              }
              this.checks['one'].splice(index, 1, check);
            } else {
              let index = this.checks['lot'].findIndex(h => h.id == check.id);
              if(index == -1) {
                return;
              }
              this.checks['lot'].splice(index, 1, check);
          }
        }
      });
    });

    //Добавление карты
    eventBus.$on("add_card", (data) => {
      console.log('eventBus', data);
      this.onLoadCards = true;
      this.cards.push(data);
      this.cardsCount++;
      this.onLoadCards = false;
    });

    //Добавление кошелька
    eventBus.$on("add_wallet", (data) => {
      this.onLoadWallets = true;
      this.wallets.push({
        id: data.id,
        network_id: data.network_id,
        value: data.wallet_hash,
        description: data.description,
        user_id: data.user_id,
      });
      this.walletsCount++;
      this.onLoadWallets = false;
    });

    eventBus.$on("userUpdate", (data) => {
      console.log(data);
      if(data.id == this.user.id) {
        this.user = data;
        if(this.user.status.id == 5) {
          this.logout();
        }
      }
    });

    //Удаление карты
    eventBus.$on("card_delete", (cardId) => {
      this.onLoadCards = true;
      axios.delete(this.$api_address + "/cards/"+cardId, {
        params: {
            user_id: sessionStorage.getItem('user_ID'),
            token: sessionStorage.getItem('user_token'),
          },
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      }})
      .then((response) => {
        // Найти индекс карты с данным id в массиве cards
        const index = this.cards.findIndex((card) => card.id === cardId);
        if (index !== -1) {
          // Удалить карту из массива cards
          this.cards.splice(index, 1);
          this.cardsCount--;
        }
        this.onLoadCards = false;
      })
      .catch(function (error){
        console.log(error.response);
      });

    });

    if(this.$route.name == 'invoice') {
      // Получаем URL из адресной строки
      const url = window.location.pathname;
      const match = url.match(/(\d+)$/);
      const number = match ? match[1] : null;
      console.log('number', number);
      this.getInvoice(number);
      eventBus.$emit('turnOnPusher', true);
    }

  },
};
</script>

<style>
.account_menu {
    position: sticky;
    bottom:0;
    display: none;
    z-index:3;
    border-radius: 5px;
    -webkit-box-shadow: 0px -5px 15px -5px rgba(34, 60, 80, 0.6);
    -moz-box-shadow: 0px -5px 15px -5px rgba(34, 60, 80, 0.6);
    box-shadow: 0px -5px 15px -5px rgba(34, 60, 80, 0.6);
    overflow: hidden;
}
.account_menu .mobile-bottom-menu {
  display: grid; /* Устанавливаем контейнер как grid */
  grid-gap: 1px; /* Задаем разрыв между элементами grid */
  grid-template-columns: repeat(6, 1fr);
  background-color: black; /* Фон за разрывами, который будет выглядеть как границы */
}
.account_menu .mobile-bottom-menu a {
    font-size: 11px;
    padding: 15px 5px;
    color: white;
    text-align: center;
    text-decoration: none;
    background-color: #4AA485;
}

@media(max-width:991px) {
    .account_menu {
        display: block;
    }
}
@media(max-width:320px) {
    .account_menu .mobile-bottom-menu {
        grid-template-columns: repeat(5, 1fr);
    }
    .account_menu .mobile-bottom-menu a:last-child {
        grid-column: 1 / -1;
    }
}
.no_scroll {
  overflow: hidden;
}
.loader {
  background:rgb(0, 0, 0);
  z-index: 999999;
}
</style>
