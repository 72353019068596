<template>
    <fragment>
        <div v-if="load_item" style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%">
            <div class="spinner"></div>
        </div>
        <div v-if="load_page && isLoadPage == false" class='modal fade custom-modal' style='display: block; opacity: 1;background: linear-gradient(180deg, rgba(10,12,29,1) 0%, rgba(32,44,80,1) 100%);' aria-hidden='true' tabindex='-1'>
            <div style="display: flex; justify-content: center; align-items: center; height: 100%">
                <div class="identifier"></div>
            </div>
        </div>
    </fragment>
</template>
<style>
.spinner {
    min-height: 30px;
    min-width: 30px;
    border-left: 3px solid #4AA485;
    border-bottom: 3px solid #4AA485;
    border-right: 3px solid #4AA485;
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spinner 1s linear infinite normal;
}
.identifier {
    height: 10px;
    width: 10px;
    animation: identifier 2s infinite normal;
}
@keyframes identifier {
    0% {
        content: url('data:image/svg+xml,%3Csvg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Crect width="8" height="8" fill="white"/%3E%3C/svg%3E ');
        height: 30px;
        width: 10px;
        margin-left: 0;
    }
    10% {
        content: url('data:image/svg+xml,%3Csvg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Crect width="8" height="8" fill="white"/%3E%3C/svg%3E ');
        height: 30px;
        width: 20px;
        margin-left: 0;
    }
    30% {
        content: url('data:image/svg+xml,%3Csvg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Crect width="8" height="8" fill="white"/%3E%3C/svg%3E ');
        height: 30px;
        width: 30px;
        margin-left: 0;
    }
    50% {
        content: url('data:image/svg+xml,%3Csvg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M0 0H8H16V8H8V16V24H0V16V8V0Z" fill="white"/%3E%3Crect x="16" y="8" width="8" height="8" fill="white"/%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M40 0H32V8V16L24 16V24H32H40V16V8V0Z" fill="white"/%3E%3C/svg%3E');
        height: 30px;
        width: 30px;
        margin-left: 0;
    }
    70% {
        content: url('data:image/svg+xml,%3Csvg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M0 0H8H16V8H8V16V24H0V16V8V0Z" fill="white"/%3E%3Crect x="16" y="8" width="8" height="8" fill="white"/%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M40 0H32V8V16L24 16V24H32H40V16V8V0Z" fill="white"/%3E%3C/svg%3E');
        height: 30px;
        width: 30px;
        margin-left: -80px;
    }
    90% {
        content: url('data:image/svg+xml,%3Csvg viewBox="0 0 152 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M0.225586 0H8.22559H16.2256V8H8.22559V16V24H0.225586V16V8V0Z" fill="white"/%3E%3Crect x="16.2256" y="8" width="8" height="8" fill="white"/%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M40.2256 0H32.2256V8V16L24.2256 16V24H32.2256H40.2256V16V8V0Z" fill="white"/%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M43.7271 24H67.727V0H43.7271V24ZM59.727 7.99999V16H51.727V7.99999H59.727Z" fill="white"/%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M99.3059 24V0H123.306V24H99.3059ZM115.306 16V7.99999H107.306V16H115.306Z" fill="white"/%3E%3Cpath d="M79.5165 24V7.99999H95.5165V0H79.5165L79.5165 7.99999H71.5165V24H79.5165Z" fill="white"/%3E%3Cpath d="M135.095 16H127.095V24H143.095V7.99999H151.095V0H135.095V16Z" fill="white"/%3E%3C/svg%3E');
        width: 140px;
        height: 30px;
        margin-left: 0px;
    }
    100% {
        content: url('data:image/svg+xml,%3Csvg viewBox="0 0 152 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M0.225586 0H8.22559H16.2256V8H8.22559V16V24H0.225586V16V8V0Z" fill="white"/%3E%3Crect x="16.2256" y="8" width="8" height="8" fill="white"/%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M40.2256 0H32.2256V8V16L24.2256 16V24H32.2256H40.2256V16V8V0Z" fill="white"/%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M43.7271 24H67.727V0H43.7271V24ZM59.727 7.99999V16H51.727V7.99999H59.727Z" fill="white"/%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M99.3059 24V0H123.306V24H99.3059ZM115.306 16V7.99999H107.306V16H115.306Z" fill="white"/%3E%3Cpath d="M79.5165 24V7.99999H95.5165V0H79.5165L79.5165 7.99999H71.5165V24H79.5165Z" fill="white"/%3E%3Cpath d="M135.095 16H127.095V24H143.095V7.99999H151.095V0H135.095V16Z" fill="white"/%3E%3C/svg%3E');
        width: 140px;
        height: 30px;
        margin-left: 0;
    }

}
@keyframes spinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>
<script>

export default {
    props: ['load_item', 'load_page'],
    created: function(){
        setTimeout(() => {
            this.isLoadPage = true;
        }, 1000);
    },
    data() {
        return {
            isLoadPage: false,
        }
    },
    methods: {
    }
}

</script>
