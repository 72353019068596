<template>
    <footer>
        <section class='section section--secondary pt-4 pb-0'>
            <footer class='footer'>
                <div class='container'>
                    <div class='row pb-5'>
                        <div class='col-12 col-lg-6'>
                            <div class='d-flex align-items-center flex-wrap gap-3 gap-lg-5'>
                                <div class='cursor-pointer' @click="$router.push('/')"><img src='/static/img/logo.svg' /></div>

                                <a @click="tgRedirect"  href="https://t.me/valutix" target="_blank"><img src='/img/ico/ico-telegram.svg'/></a>
                                
                                <div @click="trustpilotRedirect"  data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="6515316b2e96691670510cb0" data-style-height="24px" data-style-width="305px" data-theme="dark" data-min-review-count="10" data-without-reviews-preferred-string-id="1" data-style-alignment="center"> 
                                    <a href="https://www.trustpilot.com/review/valutix.io" target="_blank" rel="noopener">
                                        <img class="trustpilot" alt="Trustpilot reviews" width="140" src="https://cdn.trustpilot.net/brand-assets/4.3.0/logo-white.svg">
                                    </a> 
                                </div>
                            </div>
                           <span class="fs-12 color-ACAFFF" >v 1.4.5</span>
                            <div class="footer_text">
                                <span>BSB CAPITAL INC</span>
                                <span>Certificate Verification number 3033102954162 | Tax ID 155745073-2-2023</span>
                                <span>2022 - 2024</span>
                                <span>© Все права защищены</span>
                            </div>
                        </div>
                        <div class='col-6 d-lg-block d-none'>
                            <ul>
                                <li class='footer__item'><a class='footer__link' href='https://t.me/valutix_bot' target="_blank">Поддержка</a></li>
                                <li class='footer__item'><router-link class="footer__link" to="/aml-kyc">Политика KYC & AML</router-link></li>
                                <li class='footer__item'><router-link class="footer__link" to="/terms_of_use">Пользовательское соглашение</router-link></li>
                                <li class='footer__item'><router-link class="footer__link" to="/privacy-police">Политика конфиденциальности</router-link></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </footer>
        </section>
    </footer>
</template>
<script
    src="https://code.jquery.com/jquery-3.7.0.min.js"
    integrity="sha256-2Pmvv0kuTBOenSvLm6bvfBSSHrUJ+3A7x6P5Ebd07/g="
    crossorigin="anonymous">
</script>
<script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async>
</script>
<!-- <script src='vendor/mmenu-js/dist/mmenu.js'></script> -->
<script src='vendor/bootstrap/dist/js/bootstrap.bundle.min.js'></script>


<!-- initialize-->
<!-- <script src='js/mmenu.js'></script> -->
<!-- <script src='/js/main.js'></script> -->
<script>
export default {
    data() {
        return {}
    },
    methods: {
        trustpilotRedirect() {
            if(this.$metrika !== undefined) {
                this.$metrika.reachGoal('trustpilot_redirect');
            }
        },
        tgRedirect() {
            if(this.$metrika !== undefined) {
                this.$metrika.reachGoal('telegram_redirect');
            }
        },
    }
}
</script>

<style scoped>
    .footer_text {
        margin-top: 10px;
    }
    .footer_text span {
        display: block;
        font-size: 10px;
    }
</style>


