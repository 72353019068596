<template>
    <fragment>
        <div class="modalCards mb-3">
            <div @click="putNumber(data)" v-for="(data, index) in formattedCards" class="custom-modal__select-card-wrapper"
                :class="data == card ? 'custom-modal__select-card-wrapper--selected' : ''">
                <div class="custom-modal__select-card-container align-items-start"
                    :style="data.active == 0 ? 'border-color: gray;' : ''">
                    <img class="custom-modal__select-card-ico" :src="'/img/banks/' + imgTitle(data) + '.png'" width="16px"
                        style="object-fit: contain;" />
                    <div class="custom-modal__select-card-right">
                      <span v-show="data.name != 'no_title'" style="font-size: 10px;">{{data.sbp_bank != null ? 'СБП на ' + data.sbp_bank.title : data.name}}</span>
                      <span style="font-size: 10px;">{{ '**** ' + data.card_number.substr(data.card_number.length - 4) }}</span>
                    </div>
                </div>
            </div>
            <div class="custom-modal__select-card-wrapper">
                <div @click="newCard"
                    class="custom-modal__select-card-container align-items-center d-flex justify-content-center">
                    <span style="font-size: 12px; color: #3EA075;">+ Добавить карту</span>
                </div>
            </div>
        </div>
    </fragment>
</template>

<script>
export default {
    props: ['cards', 'title', "bank_list"],
    data() {
        return {
            card: '',
            active: 0,
        }
    },
    filters: {
        format: val => `${val}`.replace(/(\d)(?=(\d{4})+([^\d]|$))/g, '$1 '),
    },
    methods: {
        newCard() {
            this.$emit('new_card');
        },
        imgTitle: function (data) {
            if(data.sbp_bank) {
                return data.sbp_bank.title;
            } else {
                return data.name;
            }
        },
        putNumber(data) {
            if (data.active == 0) {
                return;
            }
            this.card = data;
            console.log('data', data);
            this.$emit('put', { "number": data.card_number, "bank_id": data.bank_id, "bank_name": (data.sbp_bank !== null ? data.sbp_bank.title : data.name), "country": data.country, 'sbp_bank_id': (data.sbp_bank !== null ? data.sbp_bank.id : null) });
        },

    },
    watch: {
        cards: function () {
            let tempCards = [];
            let noTitleBank = {
                'name': 'no_title'
            };

            this.cards.forEach((card) => {
                let tempBank = [];
                let bankk = this.bank_list[card.country].find(bank => bank.id == card.bank_id);
                if (bankk) {
                    tempBank = bankk;
                }
                else {
                    tempBank = noTitleBank;
                }
                let netCard = {
                    ...tempBank,
                    ...card
                }
                tempCards.push(netCard);
            });

            this.formattedCards = tempCards;
        },

    },
    computed: {
        formattedCards : function () {
            let tempCards = [];
            let noTitleBank = {
                'name': 'no_title'
            };

            this.cards.forEach((card) => {
                let tempBank = [];
                let bankk = this.bank_list[card.country].find(bank => bank.id == card.bank_id);
                if (bankk) {
                    tempBank = bankk;
                }
                else {
                    tempBank = noTitleBank;
                }
                let netCard = {
                    ...tempBank,
                    ...card
                }
                tempCards.push(netCard);
            });

            return tempCards;
        },
    },

}
</script>

<style scoped>
.modalCards {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(5, 1fr);
}

@media(max-width:992px) {
    .modalCards {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media(max-width:428px) {
    .modalCards {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media(max-width:300px) {
    .modalCards {
        grid-template-columns: repeat(1, 1fr);
    }
}
</style>


