<template>

<div class="container">
    <section class="my-4">
        <div class="row">

            <div class="col-12 col-lg-6 order-2 order-lg-1">
                <div class="description">
                    <div class="d-none d-sm-block">
                        <h6 class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseDescription" role="button" aria-expanded="true" aria-controls="collapseDescription">Как это работает?</h6>
                        <div class="collapse show" id="collapseDescription">
                            <p>Сервис “Оплата по ссылке” позволяет пользователям криптобанка Valutix оплачивать выставленные счета или инвойсы, доступные по отдельной ссылке. При оформлении заявки на оплату средства, которые необходимы для оплаты, списываются с Вашего баланса кошелька USDT.</p>
                            <p>Для того, чтобы совершить оплату, заполните все данные в форме. Указывайте точную сумму в фиатной валюте, на которую необходимо провести оплату. В случае несоответствия суммы, заявка будет отменена. В комментарии укажите все необходимые данные, которые нужно заполнить при оплате, а также срок, отведенный на оплату, если он имеет временные рамки.</p>
                            <p class="mb-4">При возникновении вопросов или трудностей Вы можете обратиться в поддержку.</p>
                        </div>
                    </div>
                    <div class="d-block d-sm-none">
                        <h6 class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseDescription" role="button" aria-expanded="false" aria-controls="collapseDescription">Как это работает?</h6>
                        <div class="collapse" id="collapseDescription">
                            <p>Сервис “Оплата по ссылке” позволяет пользователям криптобанка Valutix оплачивать выставленные счета или инвойсы, доступные по отдельной ссылке. При оформлении заявки на оплату средства, которые необходимы для оплаты, списываются с Вашего баланса кошелька USDT.</p>
                            <p>Для того, чтобы совершить оплату, заполните все данные в форме. Указывайте точную сумму в фиатной валюте, на которую необходимо провести оплату. В случае несоответствия суммы, заявка будет отменена. В комментарии укажите все необходимые данные, которые нужно заполнить при оплате, а также срок, отведенный на оплату, если он имеет временные рамки.</p>
                            <p class="mb-4">При возникновении вопросов или трудностей Вы можете обратиться в поддержку.</p>
                        </div>
                    </div>
                </div>

                <div v-if="status == 3" class="active_payments mb-3">
                    <h6>Активные оплаты</h6>
                    <div class="accordion" id="active_pays_accordeon">
                        <div v-for="item in myHistory" v-if="item.status === 'В ожидании' || item.status === 'В процессе'" class="accordion-item bg-transparent border_green">
                            <h2 class="accordion-header" :id="'headingOne' + item.id">
                                <button class="accordion-button shadow-none flex-row align-items-baseline bg-transparent text-light flex-nowrap collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseOne' + item.id" aria-expanded="false" :aria-controls="'collapseOne' + item.id">
                                    <div class="row m-0 justify-content-between align-items-center flex-row gap-1 gap-xl-2 active_pays_header flex-lg-nowrap">
                                        <div class="p-0 col col-lg-4 order-1">{{ item.currency_amount + ' ' + currencies.find(c => c.id === item.currency_id).short_title }}</div>
                                        <div class="p-0 col-12 col-lg-5 order-3 order-lg-2">Создана {{ moment(item.created_at).format("DD-MM-YYYY HH:mm") }}</div>
                                        <div class="p-0 col order-2 order-lg-3 text-end me-2 text-nowrap">{{ item.status }}</div>
                                </div>
                                </button>
                            </h2>
                            <div :id="'collapseOne' + item.id" class="accordion-collapse collapse" :aria-labelledby="'headingOne' + item.id" data-bs-parent="#active_pays_accordeon">
                                <div class="accordion-body bg_green text-light">
                                    <div class="row m-0 mb-1">
                                        <div class="p-0 col-12 col-lg-4 fz-16">ID</div>
                                        <div class="p-0 col-12 col-lg fz-16">{{ item.outer_id }}</div>
                                    </div>
                                    <div class="row m-0 mb-1">
                                        <div class="p-0 col-12 col-lg-4 fz-16">Ссылка</div>
                                        <div class="p-0 col-12 col-lg fz-16"><a target="_blank" class="text-decoration-none text-light my_link" :href="item.payment_link">{{ item.payment_link }}</a></div>
                                    </div>
                                    <div class="row m-0 mb-1">
                                        <div class="p-0 col-12 col-lg-4 fz-16">Комментарий</div>
                                        <div class="p-0 col-12 col-lg fz-16">{{ item.comment }}</div>
                                    </div>
                                    <div class="row m-0 mb-1" v-if="item.status === 'В процессе'">
                                        <div class="p-0 col-12 col-lg-4 fz-16">Взято в работу</div>
                                        <div class="p-0 col-12 col-lg fz-16">{{ localTime(item.hired_at) }}</div>
                                    </div>
                                    <div class="row m-0 mb-1">
                                        <div class="p-0 col-12 col-lg-4 fz-16">Списано</div>
                                        <div class="p-0 col-12 col-lg fz-16">{{ item.usdt_amount }} USDT</div>
                                    </div>
                                    <div class="row m-0 mb-1">
                                        <div class="p-0 col-12 col-lg-4 fz-16">Баланс до</div>
                                        <div class="p-0 col-12 col-lg fz-16">{{ item.balance_before }} USDT</div>
                                    </div>
                                    <div class="row m-0 mb-1">
                                        <div class="p-0 col-12 col-lg-4 fz-16">Баланс после</div>
                                        <div class="p-0 col-12 col-lg fz-16">{{ item.balance_after }} USDT</div>
                                    </div>
                                    <div class="row m-0 pt-1">
                                        <div class="p-0 col-12 col-lg-4" v-if="item.status === 'В ожидании'">
                                            <button class="btn btn-outline-danger" @click="cancelPay(item.id)">Отменить</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!hasActiveItems">
                        <p class="text-light fz-16 no-active text-center p-3">Нет активных оплат</p>
                    </div>
                </div>
                <div v-else class="card p-2 text-center bg_green mb-3">
                    <span>Для использования функции “оплата по ссылке” пройдите верификацию</span>
                </div>
            </div>

            <div class="col-12 col-lg-6 order-1 order-lg-2">
                <div class="balance card mb-3 d-flex flex-row border-0 overflow-hidden">
                        <div class="d-flex flex-column balance_info p-2 p-sm-3" :class="showPayLinks ? 'd-none' : 'd-flex'">
                            <span class="mb-2">Доступный баланс</span>
                            <span>{{ parseFloat(myBalance).toFixed(cryptos.find(c => c.short_title == 'USDT').count_after_point) }} USDT</span>
                            <span>({{parseFloat(myBalance * course_sale).toFixed(currencies.find(c => c.short_title == currency).count_after_point) + ' ' + currency }})</span>
                        </div>
                        <button class="bg_lightgreen ms-auto justify-content-center gap-2 p-2 p-sm-3 balance_info_btn" :class="showPayLinks ? 'd-none' : 'd-flex'" @click="showPayLinks = true">
                            <img width="30" height="31" src="/static/img/operation-arrow.svg" class="ms-auto">
                            <span class="add_balance">Пополнить баланс</span>
                        </button>
                    <div class="row m-0 links p-2 bg_lightgreen w-100 position-relative" :class="showPayLinks ? 'd-flex' : 'd-none'">
                        <button class="left col-6" @click='showBuy'>
                            <img src="/static/img/pay_link/buy.svg" width="16" heigth="16">
                            <span class="text-light">Покупка</span>
                        </button>
                        <button class="right col-6" @click='showDeposit'>
                            <img src="/static/img/pay_link/deposit.svg" width="16" heigth="16">
                            <span class="text-light">Депозит</span>
                        </button>
                        <button type="button" class="btn-close btn-close-white position-absolute end-0 px-2 shadow-none" aria-label="Close" @click="showPayLinks = false"></button>
                    </div>
                </div>

                <div class="payment_form mb-4 p-4 card">
                    <h4>Провести оплату</h4>
                    <form>
                        <h6 class="mb-2">Валюта оплаты</h6>
                        <select class="form-select shadow-none border-0 mb-3 cursor-pointer" v-model="currency" @change="paymentSumm = 0">
                            <option value="EUR" selected>EUR</option>
                            <option value="RUB">RUB</option>

                        </select>
                        <h6 class="mb-2">Сумма счета</h6>
                        <div class="input-group">
                            <input type="number" class="form-control shadow-none border-0" @focus="errorText.amount = 0; errorText.message = 0" v-model="paymentSumm">
                            <span class="input-group-text bg-white">{{currency}}</span>
                        </div>
                        <div class="mb-3 text-light d-flex gap-4 fz-16">
                            <span>Min — {{currency == 'EUR' ? 10 + " " + currency : 1000 + " " + currency }}</span>
                            <span>Max — {{currency == 'EUR' ? 3000 + " " + currency : 300000 + " " + currency}}</span>
                        </div>
                        <div class="error-block-text" v-if="errorText.amount">
                            <p>{{ errorText.amount }}</p>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm">
                                <div class="d-flex align-items-center gap-1 mb-2">
                                    <h6 class="m-0 text-nowrap">Платежная ссылка</h6>
                                    <img class="cursor-pointer" src="/static/img/pay_link/tooltip.svg" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
                                </div>
                            </div>
                            <div class="col-12 col-sm">
                                <div class="position-relative" v-if="showTooltip">
                                    <div class="tooltip-custom pay_link_tooltip">
                                        Пожалуйста, указывайте корректную ссылку, по которой можно перейти и совершить оплату.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control shadow-none border-0" id="clipboardInput" placeholder="Вставьте платежную ссылку" @focus="errorText.payment_link = 0" v-model="payment_link">
                            <span class="input-group-text bg-white cursor-pointer">
                                <img src="/static/img/pay_link/content_paste.svg" @click="clipboardPaste">
                            </span>
                        </div>
                        <div class="error-block-text" v-if="errorText.payment_link">
                            <p>{{ errorText.payment_link }}</p>
                        </div>
                        <h6 class="mb-2">Комментарий к счету</h6>
                        <div class="mb-3">
                            <textarea class="form-control shadow-none border-0" placeholder="Ваш комментарий" id="floatingTextarea" @focus="errorText.comment = 0" v-model="comment"></textarea>
                        </div>
                        <div class="error-block-text" v-if="errorText.comment">
                            <p>{{ errorText.comment }}</p>
                        </div>
                        <div class="mb-3 text-light">
                            <span>Будет списано {{  parseFloat(paymentSumm / course_sale).toFixed(cryptos.find(c => c.short_title == 'USDT').count_after_point) }} USDT</span>
                        </div>
                        <button @click.prevent="createPayment" type="submit" class="btn text-light w-100 bg_lightgreen" v-if="status == 3">Оплатить</button>
                        <div class="error-block-text" v-if="errorText.message">
                            <p>{{ errorText.message }}</p>
                        </div>
                      </form>
                </div>
            </div>

            <div class="col-12 mt-2 order-3">
                <div class="payment_history">
                    <h6>История оплат</h6>
                    <div v-if="paginatedElements.length == 0">
                        <p class="text-light fz-16 no-active text-center p-3">Вы не проводили оплаты</p>
                    </div>
                    <div class="accordion" id="pays_history_accordeon">
                        <div v-for="item in paginatedElements" class="accordion-item bg-transparent border_green">
                          <h2 class="accordion-header" :id="'heading' + item.id">
                            <button class="accordion-button shadow-none flex-row align-items-baseline bg-transparent text-light flex-nowrap collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + item.id" aria-expanded="false" :aria-controls="'collapse' + item.id">
                                <div class="row m-0 justify-content-between align-items-center flex-row gap-1 gap-xl-3 active_pays_header flex-lg-nowrap">
                                    <div class="p-0 col col-lg-2 order-1">{{ item.currency_amount + ' ' + currencies.find(c => c.id === item.currency_id).short_title }}</div>
                                    <div class="p-0 col-12 col-lg-7 order-3 order-lg-2">Создана {{ moment(item.created_at).format("DD-MM-YYYY HH:mm") }}</div>
                                    <div class="p-0 col order-2 order-lg-3 text-end me-2" :class="item.status == 'Отмена' ? 'danger' : 'success'">{{ item.status }}</div>
                               </div>
                            </button>
                          </h2>
                          <div :id="'collapse' + item.id" class="accordion-collapse collapse" :aria-labelledby="'heading' + item.id" data-bs-parent="#pays_history_accordeon">
                            <div class="accordion-body bg_green text-light">
                                <div class="row m-0 mb-1">
                                    <div class="p-0 col-12 col-lg-2 fz-16">ID</div>
                                    <div class="p-0 col-12 col-lg fz-16">{{ item.outer_id }} </div>
                                </div>
                                <div class="row m-0 mb-1">
                                    <div class="p-0 col-12 col-lg-2 fz-16">Ссылка</div>
                                    <div class="p-0 col-12 col-lg fz-16"><a target="_blank" :href="item.payment_link" class="text-decoration-none text-light my_link">{{ item.payment_link }}</a></div>
                                </div>
                                <div class="row m-0 mb-1">
                                    <div class="p-0 col-12 col-lg-2 fz-16">Комментарий</div>
                                    <div class="p-0 col-12 col-lg fz-16">{{ item.comment }} </div>
                                </div>

                                <div class="row m-0"  v-if="item.status === 'Отмена'">
                                    <div class="p-0 col-12 col-lg-2 fz-16">Отмена</div>
                                    <div class="p-0 col-12 col-lg fz-16">{{ item.cancellation_message }}</div>
                                </div>
                                <template v-if="item.status === 'Успех'">
                                    <div class="row m-0 mb-1">
                                        <div class="p-0 col-12 col-lg-2 fz-16">Списано</div>
                                        <div class="p-0 col-12 col-lg fz-16">{{ item.usdt_amount }} USDT</div>
                                    </div>
                                    <div class="row m-0 mb-1">
                                        <div class="p-0 col-12 col-lg-2 fz-16">Баланс до</div>
                                        <div class="p-0 col-12 col-lg fz-16">{{ item.balance_before }} USDT</div>
                                    </div>
                                    <div class="row m-0 mb-1">
                                        <div class="p-0 col-12 col-lg-2 fz-16">Баланс после</div>
                                        <div class="p-0 col-12 col-lg fz-16">{{ item.balance_after }} USDT</div>
                                    </div>
                                    <div class="row m-0 mb-1">
                                        <div class="p-0 col-12 col-lg-2 fz-16">Взято в работу</div>
                                        <div class="p-0 col-12 col-lg fz-16">{{ localTime(item.hired_at) }}</div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="p-0 col-12 col-lg-2 fz-16">Завершено</div>
                                        <div class="p-0 col-12 col-lg fz-16">{{ moment(item.updated_at).format("DD-MM-YYYY HH:mm") }}</div>
                                    </div>
                                    <div class="row mt-2 m-0">
                                        <div class="p-0 col-12 fz-16"><a download :href="item.proof">Скачать чек об оплате</a></div>
                                    </div>
                                </template>

                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="history_pagen-block">
                        <pagination2
                            v-if="totalChecksCount > 10"
                            v-model="page"
                            :records="totalChecksCount"
                            @paginate="clickCallback"
                            :per-page="perPage"
                            :options="{
                                 chunk: 4,
                                 edgeNavigation: true,
                                 texts: {
                                    first: '1',
                                    last: totalPages.toString(),
                                 }
                                }"
                        />
                    </div>
                </div>
            </div>

        </div>
    </section>
    <buy-crypto v-show='isBuyVisible' @new_card="newCard" @buy='buyTether' @close='closeBuy' @new_buy="newBuy" :for_buy="for_buy.crypto_id == cryptos.find(c => c.short_title == 'USDT').id ? for_buy : false" :user="user" :cards="cards" :courses="courses" :crypto="cryptos.find(c => c.short_title == 'USDT')" :courses_list="courses" :bank_list="bank_list" :currencies="currencies"/>

    <deposit-crypto v-show='isDepositVisible' @new_deposit="newDeposit" @deposit="deposit" @close='closeDeposit' :for_deposit="for_deposit.crypto_id == cryptos.find(c => c.short_title == 'USDT').id ? for_deposit : false" :balance="myBalance" :courses="courses" :crypto="cryptos.find(c => c.short_title == 'USDT')" />

    <add-card v-show='isAddCardVisible' @close='closeAddCard' :bank_list="bank_list"/>   

</div>
</template>

<script>
    import BuyCrypto from '@/components/Actives/BuyCrypto.vue';
    import DepositCrypto from "@/components/Actives/DepositCrypto.vue";
    import AddCard from '@/components/Cards/AddCard.vue';
    import AppSpinner from "@/components/General/AppSpinner.vue";
    import Pagination2 from 'vue-pagination-2';
    import moment from "moment";
    export default {
    props: [
        'courses',
        'user',
        'cards',
        'wallets',
        'cards_count',
        'wallets_count',
        'user_load',
        'cryptos',
        'balances',
        'checks',
        'page_one',
        'page_lot',
        'for_buy',
        'for_deposit',
        'bank_list',
        'cryptos',
        'currencies',
    ],
    components: {
        BuyCrypto,
        DepositCrypto,
        AddCard,
        AppSpinner,
        Pagination2,
    },
    filters: {
        format: val => `${val}`.replace(/(\d)(?=(\d{4})+([^\d]|$))/g,
            '$1 '),
    },
    data: () => ({
        page: 1,
        perPage: 10,
        showPayLinks: false,
        showTooltip: false,
        isBuyVisible: false,
        isDepositVisible: false,
        currency: 'EUR',
        paymentSumm: 0,
        status: sessionStorage.getItem('status_id'),
        isAddCardVisible: false,
        myHistory: [],
        payment_link: "",
        comment: "",
        moment: moment,
        errorText: {
            amount: '',
            comment: '',
            payment_link: '',
            message: '',
        },
    }),

    created() {
        this.getPayments();
        eventBus.$on("paymentViaLinkUpdate", (data) => {
            console.log('paymentViaLinks2', data);
            this.getPayments();
        });
    },

    computed: {
        course_sale() {
            let text = (this.currency == 'EUR' ? 'USDT2EUR_sale' : 'USDT2RUB_sale');
            if (this.courses && this.courses['Продажа']) {
                const course = Object.values(this.courses['Продажа']).find(course => course.title == text);
                return course ? course.rate : 0;
            }
            return 0;
        },
        course_buy() {
            let text = (this.currency == 'EUR' ? 'USDT2EUR_buy' : 'USDT2RUB_buy');
            if (this.courses && this.courses['Покупка']) {
                const course = Object.values(this.courses['Покупка']).find(course => course.title == text);
                return course ? course.rate : 0;
            }
            return 0;
        },
        myBalance() {
            return this.balances && this.balances.length !== 0 ? this.balances.find(b => b.title === 'USDT').value : 0;
        },
        canceledHistory() {
            let canceledHistory = [];
            this.myHistory.forEach((element) => {
                if(element.status === 'Отмена' || element.status === 'Успех') {
                    canceledHistory.push(element);
                }
            });
            return canceledHistory;
        },
        totalChecksCount() {
            return Object.values(this.canceledHistory).length
        },
        paginatedElements() {
            const start = (this.page - 1) * this.perPage;
            const end = start + this.perPage;
        return Object.values(this.canceledHistory).slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.totalChecksCount / this.perPage);
        },
        hasActiveItems() {
            return this.myHistory.some(item => item.status === 'В ожидании' || item.status === 'В процессе');
        }
    },

    methods: {
        localTime(time){
            var testDateUtc = moment.utc(time);
            var localDate = moment(testDateUtc).local();
            return localDate.format("DD-MM-YYYY HH:mm");
        },
        showBuy() {
            this.isBuyVisible = true;
            document.body.style.overflow = 'hidden';
        },
        closeBuy() {
            this.isBuyVisible = false;
            document.body.style.overflow = 'auto';
        },
        newBuy() {
            this.$emit('new_buy');
        },
        buyTether(data) {
            this.$emit('buy', data);
        },
        buyCash() {
            this.isCashVisible = true;
            this.cashBuy = true;
            this.cashSale = false;
        },
        showDeposit() {
            this.isDepositVisible = true;
            document.body.style.overflow = 'hidden';
        },
        closeDeposit() {
            this.isDepositVisible = false;
            document.body.style.overflow = 'auto';
        },
        newDeposit() {
            this.$emit('new_deposit');
        },
        deposit() {
            this.$emit('deposit');
        },
        newCard() {
            this.isAddCardVisible = true;
            document.body.style.overflow = 'hidden';
        },
        closeAddCard() {
            this.isAddCardVisible = false;
            document.body.style.overflow = 'auto';
            this.$emit('close');
        },
        async clipboardPaste() {
            try {
                const clipboardText = await navigator.clipboard.readText();
                this.payment_link = clipboardText;
            } catch (error) {
                console.error('Failed to read from clipboard:', error);
            }
        },
        //Создаём оплату
        createPayment() {
            if(this.currency == 'RUB' && (this.paymentSumm < 1000 || this.paymentSumm > 300000)){
                this.errorText.amount = 'Введите сумму в указанном диапазоне'
                return;
            }
            if(this.currency == 'EUR' && (this.paymentSumm < 10 || this.paymentSumm > 3000)){
                this.errorText.amount = 'Введите сумму в указанном диапазоне'
                return;
            }
            let data = {
                currency_id: this.currencies.find(c => c.short_title === this.currency).id,
                amount: this.paymentSumm,
                payment_link: this.payment_link,
                comment: this.comment,
                user_id: sessionStorage.getItem('user_ID'),
                token: sessionStorage.getItem('user_token'),
            };
            axios
            .post(this.$api_address + "/payment_via_links", data, {
                validateStatus: function (status) {
                    return status < 500;
                }
            }).then((response) => {
                let resp = response.data.errors;
                if (resp) {
                    if(resp.amount){
                        this.errorText.amount = resp.amount[0];
                    }
                    if(resp.payment_link){
                        this.errorText.payment_link = resp.payment_link[0];
                    }
                    if(resp.comment){
                        this.errorText.comment = resp.comment[0];
                    }
                } else if (response.data.message) {
                    this.errorText.message = response.data.message;
                    setTimeout(() => {
                        this.errorText.message = '';
                    }, "2000");
                } else {
                    alert('Отправлено');
                    this.myHistory.unshift(response.data.data);
                }
                
            });
        },

        //Отменяем оплату
        cancelPay(id) {
            axios.delete(this.$api_address + '/payment_via_links/' + id, {
            params: {
                token: sessionStorage.getItem('user_token'),
            },
            }).then(response => {
                alert('Операция успешно отменена')
            }).catch(function (error) {
                alert('Что-то пошло не так, попробуйте снова')
            });
        },

        //Получаем список оплат
        getPayments() {
            axios
            .get(this.$api_address + "/payment_via_links",{
                params: {
                    user_id: sessionStorage.getItem('user_ID'),
                    token: sessionStorage.getItem('user_token'),
                }
            })
            .then((response) => {
                this.myHistory = response.data.data;
            });
        }
    }
    }
</script>

<style scoped>
    .bg_lightgreen {
        background:#4AA485;
    }

    .bg_green {
        background: #35634F;
    }

    .bg_black {
        background: #212121;
    }

    .border_green {
        border-color: #35634F;
    }

    .description h6 {
        text-decoration: underline;
        text-underline-offset: 5px;
    }

    #active_pays_accordeon .border_green {
        border-top: 1px solid #35634F;
        border-radius: 5px;
    }

    .add_balance {
        max-width: 93px;
        text-align: center;
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .balance {
        background: left / cover no-repeat url('/public/static/img/pay_link/balance.png');
    }

    .balance_info {
        color: #000;
    }

    .balance_info {
        color: #FFF;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .balance_info span:nth-child(2) {
        font-size: 30.5px;
    }

    .balance_info span:last-child, .balance_info span:first-child {
        font-size: 20px;
    }

    h6 {
        display: block;
    }

    .payment_form {
        color: #000;
        background: #35634F;
    }

    .payment_form textarea {
        height: 92px;
    }

    .accordion-button:not(.collapsed)::after, .accordion-button::after {
        background-image: url(/public/static/img/ico/ico-accordeon.svg);
        width: 30px;
        height: 15px;
    }

    .active_pays_header {
        width: 90%;
    }

    .active_pays_header :not(:first-child) {
        font-size: 16px;
    }

    #active_pays_accordeon {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    
    .fz-16 {
        font-size: 16px;
    }

    .form-select {
        background-image: url(/public/static/img/pay_link/select.svg);
    }

    .links {
        height: 135px;
        border-radius: 5px;
    }

    .links .left {
        border-right: 1px solid #35634F;
    }

    .btn-outline-danger:hover {
        background-color: #FF6D6D;
    }

    .btn-outline-danger {
        color: #fff;
        border-color: #FF6D6D;
        font-size: 16px;
        width: -moz-fit-content;
        width: fit-content;
    }

    .danger {
        color: #FF6D6D;
    }

    .success {
        color: #74ED79;
    }

    .description h6 {
        display: flex;
        align-items: flex-end;
        width: fit-content;
        gap: 10px;
    }

    .pay_link_tooltip {
        z-index: 9999;
    }

    .my_link {
        display: block;
        width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
    }

    .no-active {
        border-radius: 5px;
        border: 1px solid #35634F;
        font-size: 20px;
        line-height: normal;
    }

    @media(max-width:500px) {
        .balance_info span:nth-child(2) {
            font-size: 20px;
        }
    
        .balance_info span:last-child, .balance_info span:first-child {
            font-size: 16px;
        }

    }

</style>
