import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/components/Index/IndexComponent.vue'
import InvoicePayment from '@/components/InvoicePayment.vue'
import Account from '../views/AccountView.vue'
import PayLink from '../views/Pay-link.vue'
import CashExchange from '../views/Сash-exchange.vue'
import Amlkyc from '../views/Aml-kyc.vue'
import Terms_of_use from '../views/Terms_of_use.vue'
import PrivacyPolice from '../views/Privacy-police.vue'
import Registration from '@/components/Index/Registration.vue'
import Login from '@/components/Index/Enter.vue'
import Recovery from '@/components/Index/Recovery.vue'
import Api from '../views/API.vue'
import Cash from '../views/Cash.vue'
import Invoice from '../views/Invoice.vue'
import Contacts from '../views/Contacts.vue'
import Paymentlink from '../views/Payment-link.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Index,
    meta: { 
      title: 'Мгновенный обмен криптовалют в Криптобанке Valutix, бизнес счета в криптовалюте и оплата по заграничной ссылке.',
      description: 'Покупка и продажа USDT, BTC, ETH и других криптовалют. Наличные. Работаем в 8 странах. Заграничные карты. Криптопроцессинг для бизнеса. Москва, Санкт-Петербург, Новосибирск, Ташкент, Кишинев, Варшава.',
  }
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: { title: 'Личный кабинет - Valutix' }
  },
  {
    path: '/pay-link',
    name: 'pay-link',
    component: PayLink,
    meta: { 
      title: 'Оплата по ссылке онлайн. Криптобанк Valutix.',
      description: 'Оплатить в зарубежном банке сервис онлайн из РФ. Оплата по ссылке быстро и эффективно. Простое и безопасное решение для вашего удобства.',
    }
  },
  {
    path: '/aml-kyc',
    name: 'aml-kyc',
    component: Amlkyc,
    meta: { title: 'Политика KYC / AML' }

  },
  {
    path: '/terms_of_use',
    name: 'terms_of_use',
    component: Terms_of_use,
    meta: { title: 'Пользовательское соглашение' }
  },
  {
    path: '/privacy-police',
    name: 'privacy-police',
    component: PrivacyPolice,
    meta: { title: 'Политика конфиденциальности' }
  },

  {
    path: '/registration',
    name: 'registration',
    component: Registration,
    meta: { title: 'Регистрация' }
  },

  {
    path: '/login',
    name: 'login',
    component: Login,
    props: true, // Включает передачу params как props
    meta: { title: 'Вход' }
  },

  {
    path: '/recovery',
    name: 'recovery',
    component: Recovery,
    meta: { title: 'Восстановление пароля' }
  },
  
  {
    path: '/invoice/:id1?',
    name: 'invoice',
    component: InvoicePayment,
    meta: { 
      title: 'Счет в криптовалюте. Криптобанк Valutix.',
      description: 'Оплатить инвойс криптовалютой. Выставить счет бизнес партнеру в криптовалюте. Через криптобанк Valutix оплатить инвойс в Китай, Японию, Корею.',
  }
  },

  {
    path: '/cash',
    name: 'cash',
    component: Cash,
    meta: { 
      title: 'Обмен криптовалют на наличные в Криптобанке Valutix.',
      description: 'Покупка и продажа USDT, BTC за наличные. Москва, Санкт-Петербург, Новосибирск, Тверь, Ташкент, Кишинев, Варшава. Безопасно купить или продать криптовалюту за наличные.',
    }
  },
  {
    path: '/api',
    name: 'api',
    component: Api,
    meta: { 
      title: 'Криптопроцессинг под ключ. IT продукт Криптобанка Valutix.',
      description: 'Процессинг платежей по API. Прием выплат, конвертация в криптовалюту, автоматизация. Современное решение для вашего бизнеса.',
    }
  },
  {
    path: '/contact',
    name: 'contacts',
    component: Contacts,
    meta: { 
      title: 'Контакты. Криптобанк Valutix.',
      description: 'Каналы связи с технической поддержкой, службой PR и командой сотрудничества Криптобанка Valutix. Поможем с возникшей проблемой и рады будем успешному сотрудничеству.',
    }
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: Invoice,
    meta: { 
      title: 'Счет в криптовалюте. Криптобанк Valutix.',
      description: 'Оплатить инвойс криптовалютой. Выставить счет бизнес партнеру в криптовалюте. Через криптобанк Valutix оплатить инвойс в Китай, Японию, Корею.',
    }
  },
  {
    path: '/payment-link',
    name: 'payment-link',
    component: Paymentlink,
    meta: { 
      title: 'Оплата зарубежных сервисов через Криптобанк Valutix.',
      description: 'Оплатить зарубежный сервис, подписку, услугу онлайн из РФ. Виртуальная карта. Платежное решение для предпринимателей. Оплата в европейских банках.',
    }
  },
  {
    path: '/cash-exchange',
    name: 'cash-exchange',
    component: CashExchange,
    meta: { 
      title: 'Оплата зарубежных сервисов через Криптобанк Valutix.',
      description: 'Оплатить зарубежный сервис, подписку, услугу онлайн из РФ. Виртуальная карта. Платежное решение для предпринимателей. Оплата в европейских банках.',
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})


function updateMetaDescription(content) {
  let metaTag = document.querySelector('meta[name="description"]');
  if (!metaTag) {
    metaTag = document.createElement('meta');
    metaTag.setAttribute('name', 'description');
    document.head.appendChild(metaTag);
  }
  metaTag.setAttribute('content', content);
}

router.afterEach((to, from) => {
  // Используйте мета-тег title, если он есть, иначе установите заголовок по умолчанию
  document.title = to.meta && to.meta.title ? to.meta.title : 'Valutix - покупка, продажа и обмен криптовалюты';
  document.description = to.meta && to.meta.description ? to.meta.description : 'Valutix - покупка, продажа и обмен криптовалюты';
  updateMetaDescription(document.description);
})

export default router
