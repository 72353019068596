<template>
        <div class="modal fade new_custom_modal" id="regModal" tabindex="-1" aria-labelledby="regModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="regModalLabel">Регистрация</h5>
                        <button class="btn-close btn-close-white shadow-none" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div class="modal-body register_block" id="register_block">

                        <!-- Email -->
                        <input @focus="clearError('email')" v-if="emailConfirm == false" @change="checkMail" :readonly="showCode == true ? true : false" class="o-input mb-2" v-model="email" type="email" name="email" placeholder="Email" />
                        <span v-if="emailConfirm == true" class='custom-file__text emailConfirm_span'>{{email}}</span>
                        <div class="error-block-text" v-if="errorText.email"><p>{{this.errorText.email}}</p></div>

                        <!-- Код из email -->
                        <input @focus="clearError('verCode')" @change="checkCode" v-if="showCode != false" class="o-input mb-2" v-model="verCode" type="email" name="vercode" placeholder="Код из email" />
                        <div class="error-block-text" v-if="errorText.verCode"><p>{{this.errorText.verCode}}</p></div>

                        <!-- Никнейм -->
                        <input @focus="clearError('nickname')" @change="checkNickname" class="o-input mb-2" v-model="nickname" type="text" name="nickname" placeholder="Никнейм" />
                        <div class="error-block-text" v-if="errorText.nickname"><p>{{this.errorText.nickname}}</p></div>

                        <!-- Реферальный код -->
                        <input @focus="clearError('referral_code')" @change="checkReferal" class="o-input mb-2" v-model="referral_code" type="text" name="referral_cod" autocomplete="one-time-code" placeholder="Реферальный код" />
                        <div class="error-block-text" v-if="errorText.referral_code"><p>{{this.errorText.referral_code}}</p></div>

                        <!-- Пароль -->
                        <div class="position-relative">
                            <img @click="showPassword" class="reg_show_password" src="/img/ico/eye.png">
                        </div>
                        <input @focus="clearError('password')"  autocomplete='new-password' class="o-input mb-2" v-model="password" :type="this.showPass ? 'text' : 'password'" name="password" placeholder="Пароль" />
                        <div class="error-block-text" v-if="errorText.password"><p>{{this.errorText.password}}</p></div>

                        <!-- Пароль повторно -->
                        <div class="position-relative">
                            <img @click="showPassword" class="reg_show_password" src="/img/ico/eye.png">
                        </div>
                        <input @focus="clearError('password_repeat')" autocomplete='new-password' class="o-input mb-2" v-model="password_repeat" :type="this.showPass ? 'text' : 'password'" name="password_repeat" placeholder="Пароль еще раз" />
                        <div class="error-block-text" v-if="errorText.password_repeat"><p>{{this.errorText.password_repeat}}</p></div>

                        <!-- Кнопки -->
                        <button :disabled="nickname == '' || password == '' || password_repeat == ''" style='margin-top: 10px;' @click="register" class="button button--full-width mb-3" href="#">Зарегистрироваться</button>
                        <div class="custom-modal__text mb-2">Нажимая на кнопку “Зарегистрироваться”, вы соглашаетесь с <router-link class="custom-modal__link" to="/terms_of_use" @click.native="$close_modal('regModal')">правилами сервиса</router-link> и <router-link class="custom-modal__link" to="/privacy-police" @click.native="$close_modal('regModal')">политикой конфиденциальности</router-link></div>
                        <div class="custom-modal__text">Уже зарегистрированы? <a class="custom-modal__link" href="#enterModal" data-bs-toggle="modal">Войти</a></div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>

export default {
    data() {
        return {
            nickname: '',
            email: '',
            emailConfirm: false,
            referral_code: '',
            password: '',
            password_repeat: '',
            verCode: '',
            showPass: false,
            showCode: false,
            errorText: {
                email: '',
                nickname: '',
                referral_code: '',
                password: '',
                password_repeat: '',
                verCode: '',
            },
            session_id: ''
        }
    },
    methods: {

        //Показать пароль
        showPassword() {
            if(this.showPass == true) {
                this.showPass = false;
            } else {
                this.showPass = true;
            }
        },

        //Проверка почты
        checkMail() {
            let data = { email: this.email }
            axios.post(this.$api_address + '/users/register_email', data, {
                validateStatus: function (status) {
                    return status < 500;
                }
            }).then(response => {
                if(response.data.data) {
                    this.errorText.email = 'На этот email был отправлен код подтверждения';
                    this.showCode = true;
                    this.session_id = response.data.data.session_id;
                } else {
                    console.log('Ошибочка мыла');
                    this.$show_or_clean_error('email', response, this);
                }
            }).catch(function (error){
                console.log(error.response);
            });
        },

        //Проверка кода
        checkCode() {
            let data = {
                code: this.verCode,
                session_id: this.session_id
            }
            axios.post(this.$api_address + '/users/check_code', data, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            }).then(response => {
              if(typeof(response.data.data.response) != "undefined" && response.data.data.response !== null) {
                    this.emailConfirm = true;
                    this.showCode = false;
                    this.errorText.verCode = '';
                    this.errorText.email = '';
                    sessionStorage.setItem('session_id', this.session_id);
                } else {
                    if(response.data.error){
                        this.errorText.verCode = response.data.error
                    }
                }
            })
        },

        //Проверка никнейма
        checkNickname() {
            let data = { nickname: this.nickname }
            axios.post(this.$api_address + '/users/check_nickname', data, {
                validateStatus: function (status) {
                    return status < 500; 
                }
            }).then(response => {
              if(typeof(response.data.message) == "undefined" && response.data.message == null) {
                    console.log(response);
                    this.$show_or_clean_error('nickname', response, this);
                }
            }).catch(function (error){
                console.log(error.response);
            });
        },

        //Проверка реферального кода
        checkReferal() {
            if(this.referral_code == '' || this.referral_code == null) {
              return;
            }
            let data = { referral_code: this.referral_code }
            axios.post(this.$api_address + '/users/check_referral', data, {
                validateStatus: function (status) {
                    return status < 500; 
                }
            }).then(response => {
              if(typeof(response.data.message) != "undefined" && response.data.message !== null) {
                    this.$show_or_clean_error('referral_code', response, this);
                }
            }).catch(function (error){
                console.log(error.response);
            });
        },

        // //Проверка пароля
        // checkPassword() {
        //     let data = { password: this.password }
        //     axios.post(this.$api_address + '/users', data, {
        //         validateStatus: function (status) {
        //             return status < 500; 
        //         }
        //     }).then(response => {
        //         if(response.data !== true) {
        //             this.$show_or_clean_error('password', response, this);
        //         }
        //     }).catch(function (error){
        //         console.log(error.response);
        //     });
        // },

        // //Проверка второго пароля
        // check2Password() {
        //     let data = { password_repeat: this.password_repeat }
        //     axios.post(this.$api_address + '/users', data, {
        //         validateStatus: function (status) {
        //             return status < 500; 
        //         }
        //     }).then(response => {
        //         if(response.data !== true) {
        //             this.$show_or_clean_error('password_repeat', response, this);
        //         }
        //     }).catch(function (error){
        //         console.log(error.response);
        //     });
        // },

        //Регистрация
        register() {
            console.log(this.emailConfirm);
            if(this.nickname == ''
                || this.password == ''
                || this.password_repeat == ''
                || this.emailConfirm == false
                || this.errorText.nickname != ''
                || this.errorText.referral_code != ''
                || this.errorText.password != ''
                || this.errorText.password_repeat != '') {
                return
            }
            let data = {
                nickname: this.nickname,
                email: this.email,
                password: this.password,
                password_repeat: this.password_repeat
            }
            if (this.referral_code !== '') {
                data.referral_code = this.referral_code;
            }
            axios.post(this.$api_address + '/users', data, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            })
            .then(response => {
                if(response.data.data) {
                    console.log('Ответ при регистрации: ', response.data.data);
                    if(this.$metrika !== undefined) {
                        this.$metrika.reachGoal('registration_success');
                    }
                    sessionStorage.setItem('user_ID', response.data.data.id);
                    sessionStorage.setItem('user_token', response.data.data.token['token']);
                    sessionStorage.setItem('status_id', response.data.data.status['id']);
                    console.log('status_id из регистрации', this.$status_id.value);
                    this.$emit('login_user');
                    this.$close_modal('regModal');
                } else {
                    if(response.data.errors.password) {
                        this.errorText.password = response.data.errors.password[0];
                    }
                    if(response.data.errors.password_repeat) {
                        this.errorText.password_repeat = response.data.errors.password_repeat[0];
                    }

                }
            })
            .catch(function (error){
                console.log('Error', error);
            });
            //this.close();
        },

        //Очистка ошибок
        clearError(error){
            if(error == 'email') {
                this.errorText.email = '';
            }
            if(error == 'nickname') {
                this.errorText.nickname = '';
            }
            if(error == 'referral_code') {
                this.errorText.referral_code = '';
            }
            if(error == 'password') {
                this.errorText.password = '';
            }
            if(error == 'password_repeat') {
                this.errorText.password_repeat = '';
            }
            if(error == 'verCode') {
                this.errorText.verCode = '';
            }
        },
    }
}
</script>


