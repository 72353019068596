<template>
    <transition name='modal-fade'>
        <div class='modal fade new_custom_modal' id='addWallet' style='display: block; opacity: 1; background: rgba(29, 28, 43, 0.9);' aria-hidden='true' tabindex='-1'>
            <div class='modal-dialog modal-md modal-dialog-centered'>
                <div class='modal-content'>
                    <div class='modal-header'>
                        <h5 class='modal-title'>Добавить кошелек</h5>
                        <button class='btn-close btn-close-white shadow-none' type='button' data-bs-dismiss='modal' @click='close' aria-label='Close'></button>
                    </div>
                    <div class='modal-body'>
                        <div class="error-block-text" v-if="error_text"><p>{{error_text}}</p></div>
                        <div class='custom-modal__text mb-3 o-text-primary text-start'>
                            Валюта кошелька
                            <div class='d-flex my-2 flex-wrap justify-content-between align-content-between align-items-center'>
                                <div @click="clearNetworks" :key="index" v-for="(data, index) in cryptos"  style="min-width: 30%">
                                    <label  class='control control-radio'>
                                        {{data.title}}
                                        <input :id="data.title" type='radio' v-model='crypto' :value="data.short_title" name='radio' />
                                        <div class='control_indicator'></div>
                                    </label>
                                </div>
                            </div>

                        </div>
                        <input class='o-input mb-2' type='text' v-model='value' name='address_wallet' placeholder='Адрес кошелька' />
                        <select class='o-input mb-2 form-select' name='web_wallet' v-model='network' required=''>
                            <option disabled='' selected='' value="0">Выберите сеть...</option>
                            <option v-for='net in networksList' :value="net.id">
                                {{net.title}}
                            </option>
                        </select>
                        <input class='o-input mb-2' type='text' v-model='description' name='desc_wallet' placeholder='Описание кошелька' /><button class='button button--full-width mb-3' @click="add">Добавить кошелек</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Wallet from './Wallet.vue'
export default {
    props: ['networks', 'title', 'cryptos'],
    components: {
        Wallet,
    },
    data() {
        return {
            crypto: 'USDT',
            network: 0,
            value: '',
            description: '',
            error_text: '',
        }
    },
computed: {
    networksList: function () {
        let data = [];
        if(this.networks !== undefined) {
            for (const [key, value] of Object.entries(this.networks)) {
                // Проверяем, что crypto_id корректно ссылается на элемент в массиве cryptos
                if(value.crypto_id - 1 >= 0 && value.crypto_id - 1 < this.cryptos.length) {
                    if(this.crypto === this.cryptos[value.crypto_id - 1].short_title) {
                        data.push(value);
                    }
                }
            }
        }
        return data;
    }
},
    methods: {
        clearNetworks() {
          this.network = 0;
        },
        close() {
            this.$emit('close');
        },
        add() {
            // Формирование данных для отправки
            let data = {
                wallet_hash: this.value,
                description: this.description,
                network_id: this.network, //здесь в будущем может быть ошибка
                user_id: this.$user_ID.value,
                token: sessionStorage.getItem('user_token'),
            }
            console.log('wallet_data: ', data);

            // Отправка данных на сервер
            axios
                .post(this.$api_address+'/wallets', data)
                .then(response => {
                    if(typeof(response.data.error) != "undefined" && response.data.error !== null) {
                        this.error_text = response.data.error;
                    } else {
                        if(this.$metrika !== undefined) {
                            this.$metrika.reachGoal('adding_wallet_success');
                        }
                        eventBus.$emit('add_wallet', response.data.data);
                        this.close();
                    }
                })
                .catch(function (error){
                    console.log('Ошибка добавления кошелька: ', error.message);
                    this.error_text  = 'Что-то пошло не так';
                });

        },
    }
}
</script>


