<template>
        <div class="modal fade new_custom_modal" id="enterModal" tabindex="-1" aria-labelledby="enterModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content" v-if="forgotPass == false">
                    <div class="modal-header">
                        <h5 class="modal-title" id="enterModalLabel">Вход</h5>
                        <button class="btn-close btn-close-white shadow-none" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body register_block">

                        <!-- Общая ошибка -->
                        <div class="modal-body error-block-text" v-if="errorText.general"><p>{{this.errorText.general}}</p></div>

                        <!-- Вводим email -->
                        <input @focus="clearError('email');clearError('emailForget');" class="o-input mb-2" type="email" v-model="email" name="email" placeholder="Email" />
                        <div class="error-block-text" v-if="errorText.email"><p>{{this.errorText.email}}</p></div>
                        <div class="error-block-text" v-if="errorText.emailForget"><p>{{this.errorText.emailForget}}</p></div>
                        <div style="position: relative;">
                            <img @click="showPassword" class="enter_modal_img" src="/img/ico/eye.png">
                        </div>

                        <!-- Вводим пароль -->
                        <input @focus="clearError('password')" class="o-input mb-2" :type="this.showPass ? 'text' : 'password'" v-model="password" autocomplete='new-password' name="password" placeholder="Пароль" />
                        <div class="error-block-text" v-if="errorText.password"><p>{{this.errorText.password}}</p></div>

                        <!-- Кнопки -->
                        <button type="submit" class="button button--full-width mb-3 mt-2" @click="enter">Войти</button>
                        <div v-if="email" class="custom-modal__text mb-2">
                            <button class="custom-modal__link" @click="forgot_pass" id="forgetPassButton">Забыли пароль?</button>
                        </div>
                        <div class="custom-modal__text">
                            <a class="custom-modal__link" href="#regModal" data-bs-toggle="modal">Регистрация</a>
                        </div>
                    </div>
                </div>

                <!-- Восстановление пароля -->
                <div class="modal-content no-transition" v-else>
                    <div class="modal-header">
                        <h5 class="modal-title" id="enterModalLabel">Восстановление пароля</h5>
                        <button class="btn-close btn-close-white shadow-none" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body register block" v-if="step == 1">
                        <p v-if="step == 1" style="font-size: 14px; margin-top: 15px;">На указанный email придет код для восстановления пароля</p>
                        <input @focus="clearError('vercode')" class="o-input mb-2" type="email" v-model="vercode" name="email" placeholder="Код" />
                        <div class="error-block-text" v-if="errorText.vercode"><p>{{this.errorText.vercode}}</p></div>
                        <button style="margin-top: 10px;" type="submit" class="button button--full-width mb-3" @click="thirdStep">Подтвердить</button>
                        <div class="custom-modal__text">
                            <a class="custom-modal__link" href="#" @click="clickReg" data-bs-target="#signUp" data-bs-toggle="modal" data-bs-dismiss="modal">Регистрация</a>
                        </div>
                    </div>
                    <div class="modal-body" id="register_block" v-if="step == 2">
                        <div style="position: relative;"><img @click="showPassword" class="enter_modal_img" src="/img/ico/eye.png"></div>
                        <input @focus="clearError('password')" class="o-input mb-2" :type="this.showPass ? 'text' : 'password'" v-model="password" autocomplete='new-password' name="password" placeholder="Пароль" />
                        <div class="error-block-text" v-if="errorText.password"><p>{{errorText.password}}</p></div>
                        <div style="position: relative;"><img @click="showPassword" class="enter_modal_img" src="/img/ico/eye.png"></div>
                        <input @focus="clearError('password_repeat')" class="o-input mb-2" :type="this.showPass ? 'text' : 'password'" v-model="password_repeat" autocomplete='new-password' name="password" placeholder="Пароль ещё раз" />
                        <div class="error-block-text" v-if="errorText.password_repeat"><p>{{errorText.password_repeat}}</p></div>
                        <button style="margin-top: 10px;" type="submit" class="button button--full-width mb-3" @click="lastStep">Подтвердить</button>
                        <div class="custom-modal__text"><a class="custom-modal__link" href="#regModal" data-bs-toggle="modal">Регистрация</a></div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    data() {
        return {
            step: 1,
            forgotPass: false,
            email: '',
            emailForget: '',
            vercode: '',
            password: '',
            showPass: false,
            password_repeat: '',
            sessionId: 0,
            errorText: {
                email: '',
                emailForget: '',
                password: '',
                password_repeat:'',
                general:'',
                vercode: '',
            },
        }
    },
    methods: {
        showPassword() {
            if(this.showPass == true) {
                this.showPass = false;
            } else {
                this.showPass = true;
            }
        },
        clickReg() {
          return
        },
        clearError(error){
            if(error == 'email') {
                this.errorText.email = '';
            }
            if(error == 'emailForget') {
                this.errorText.emailForget = '';
            }
            if(error == 'vercode') {
                this.errorText.vercode = '';
            }
            if(error == 'password') {
                this.errorText.password = '';
            }
            if(error == 'password_repeat') {
                this.errorText.password_repeat = '';
            }
        },
        thirdStep() {
            let data = {
                code: this.vercode,
                session_id: this.sessionId,
            }

            axios.post(this.$api_address + '/users/check_code', data, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            }).then(response => {
                if(typeof(response.data.data.response) != "undefined" && response.data.data.response !== null) {
                  console.log(123);
                    this.sessionId = 0;
                    this.step = 2;
                } else {
                  console.log(321);
                  if(typeof(response.data.message) != "undefined" && response.data.message !== null) {
                    if(typeof(response.data.errors) != "undefined" && response.data.errors !== null) {
                      this.errorText.vercode = response.data.errors.code.pop();
                    } else {
                      this.errorText.vercode = response.data.message;
                    }
                  }
                }
            })
        },
        lastStep(){
            let data = {
                email: this.email,
                password: this.password,
                password_repeat: this.password_repeat,
            }

            axios.post(this.$api_address + '/users/change_password', data, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            }).then(response => {
              if(typeof(response.data.data.response) != "undefined" && response.data.data.response !== null) {
                    console.log(response);
                    this.step = 1;
                    this.password = '';
                    this.forgotPass = false;
                } else {
                    if(typeof(response.data.errors.password) != "undefined" && response.data.errors.password !== null){
                        this.errorText.password = response.data.errors.password.pop();
                    }
                    if(typeof(response.data.errors.password_repeat) != "undefined" && response.data.errors.password_repeat !== null){
                        this.errorText.password_repeat = response.data.errors.password_repeat.pop();
                    }
                }
            })
        },
        forgot_pass() {
            if(this.$metrika !== undefined) {
                this.$metrika.reachGoal('password_reset');
            }
            let data = {
                email: this.email,
            }
            axios.post(this.$api_address + '/users/check_email', data, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            }).then(response => {
                if(typeof(response.data.data) != "undefined" && response.data.data !== null) {
                    console.log(response);
                    this.step = 1;
                    this.password = '';
                    this.sessionId = response.data.data.session_id;
                    this.forgotPass = true;

                } else {
                    if(typeof(response.data.message) != "undefined" && response.data.message !== null){
                        this.errorText.emailForget = response.data.errors.email.pop();
                    }
                }
            })
        },
        enter() {
            let data = {
                email: this.email,
                password: this.password,
            }
            console.log(data);
            console.log('Enter');
            axios.post(this.$api_address+'/users/auth', data, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            })
                .then(response => {
                    this.errorText.general = '';
                    this.errorText.email = '';
                    this.errorText.password = '';
                    if(response.data.data) {
                        if(response.data.data.status.id == 5) {
                            this.errorText.general = 'Ваш аккаунт был заблокирован. Обратитесь в поддержку';
                            return;
                        }
                        //Отправляем событие родителю и закрываем модалку
                        sessionStorage.setItem('user_ID', response.data.data.id);
                        sessionStorage.setItem('user_token', response.data.data.token['token']);
                        sessionStorage.setItem('status_id', response.data.data.status.id);

                        this.$emit('login_user');
                        this.$close_modal('enterModal');
                    } else {
                        if(response.data.message === 'Неверный пароль') {
                            this.errorText.password = response.data.message;
                        }
                        if(response.data.errors.email){
                            if(response.data.errors.email[0] == "Неверные учетные данные.") {
                                this.errorText.general = 'Неверный логин или пароль';
                            } else {
                                this.errorText.email = response.data.errors.email.pop();
                            }
                        }
                        if(response.data.errors.password){
                            this.errorText.password = response.data.errors.password.pop();
                        }

                       
                    }
                })
                .catch(function (error){
                    console.log(error.response);
                });
        },
    }
}
</script>


