import Vue from 'vue';
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueYandexMetrika from 'vue-yandex-metrika'
import { createApp, reactive } from 'vue';
// import Form from 'vform';
// import Paginate from 'vuejs-paginate'
import VueMask from 'v-mask'
import VueQRCodeComponent from 'vue-qrcode-component'
import Fragment from 'vue-fragment'
import Pusher from 'pusher-js'
import { eventBus } from '@/eventBus.js';
// import './mocks';

window.Pusher = Pusher;
window.axios = axios;
window.eventBus = eventBus;

Vue.use(Fragment.Plugin)

// window.Form = Form;
// Vue.component(Button.name, Button)
// Vue.component(HasError.name, HasError)
// Vue.component(AlertError.name, AlertError)
// Vue.component(AlertErrors.name, AlertErrors)
// Vue.component(AlertSuccess.name, AlertSuccess)
// Vue.component('paginate', Paginate)
// Vue.component('pagination', require('laravel-vue-pagination'));


//Pusher
Pusher.logToConsole = true;
var pusher = new Pusher('31d0a782425bb73ada2c', {
    cluster: 'mt1'
});

var channelCourses = pusher.subscribe('courses');
channelCourses.bind('coursesUpdate', function(data) {
    app.$pusherInfo.courses = data;
    eventBus.$emit('coursesUpdate', Vue.prototype.$pusherInfo.courses);
});

eventBus.$on('turnOnPusher', (callback) => {
    var channelUser = pusher.subscribe('user');
    channelUser.bind('userUpdate', function(data) {
        app.$pusherInfo.user = data.user;
        eventBus.$emit('userUpdate', Vue.prototype.$pusherInfo.user);
    });

    var channelTransaction = pusher.subscribe('transaction');
    channelTransaction.bind('transactionUpdate', function(data) {
        app.$pusherInfo.transaction = data.transaction;
        eventBus.$emit('transactionUpdate', Vue.prototype.$pusherInfo.transaction);
        eventBus.$emit('historyUpdate', Vue.prototype.$pusherInfo.transaction);
    });
    var channelDeposit = pusher.subscribe('deposit');
    channelDeposit.bind('depositUpdate', function(data) {
        console.log('datas', data);
        app.$pusherInfo.deposit = data.deposit;
        eventBus.$emit('depositUpdate', Vue.prototype.$pusherInfo.deposit);
        eventBus.$emit('historyUpdate', Vue.prototype.$pusherInfo.deposit);
    });
    var channelPayout = pusher.subscribe('payout');
    channelPayout.bind('payoutUpdate', function(data) {
        app.$pusherInfo.payout = data.payout;
        eventBus.$emit('payoutUpdate', Vue.prototype.$pusherInfo.payout);
        eventBus.$emit('historyUpdate', Vue.prototype.$pusherInfo.payout);
    });
    var channelWithdrawal = pusher.subscribe('withdrawal');
    channelWithdrawal.bind('withdrawalUpdate', function(data) {
        app.$pusherInfo.withdrawal = data.withdrawal;
        eventBus.$emit('withdrawalUpdate', Vue.prototype.$pusherInfo.withdrawal);
        eventBus.$emit('historyUpdate', Vue.prototype.$pusherInfo.withdrawal);
    });
    var channelCash = pusher.subscribe('cash');
    channelCash.bind('cashUpdate', function(data) {
        app.$pusherInfo.cash = data.cash;
        eventBus.$emit('cashUpdate', Vue.prototype.$pusherInfo.cash);
        eventBus.$emit('historyUpdate', Vue.prototype.$pusherInfo.cash);
    });
    var channelBalance = pusher.subscribe('balance');
    channelBalance.bind('balanceUpdate', function(data) {
        app.$pusherInfo.balance = data.balance;
        eventBus.$emit('balanceUpdate', Vue.prototype.$pusherInfo.balance);
    });

    var channelpaymentViaLink = pusher.subscribe('paymentViaLink');
    channelpaymentViaLink.bind('paymentViaLinkUpdate', function(data) {
        console.log('paymentViaLink', data);
        app.$pusherInfo.paymentViaLink = data.paymentViaLink;
        eventBus.$emit('paymentViaLinkUpdate', Vue.prototype.$pusherInfo.paymentViaLink);
    });

    var channelCheck = pusher.subscribe('check');
    channelCheck.bind('checkUpdate', function(data) {
        app.$pusherInfo.check = data.check;
        eventBus.$emit('checkUpdate', Vue.prototype.$pusherInfo.check);
    });
});


Vue.use(VueMask);
Vue.component('qr-code', VueQRCodeComponent)

Vue.component('cards-component', require('./components/Cards/CardsComponent.vue').default);
Vue.component('wallets-component', require('./components/Wallets/WalletsComponent.vue').default);
// Vue.component('actives-component', require('./components/Actives/ActivesComponent.vue').default);
// Vue.component('history-component', require('./components/HistoryComponent.vue').default);
// Vue.component('courses-component', require('./components/CoursesComponent.vue').default);
// Vue.component('header-component', require('./components/General/HeaderComponent.vue').default);
// Vue.component('footer-component', require('./components/General/FooterComponent.vue').default);
Vue.component('app-spinner', require('./components/General/AppSpinner.vue').default);
Vue.component('admin-component', require('./components/AdminComponent.vue').default);
Vue.component('invoice-payment', require('./components/InvoicePayment.vue').default);

Vue.use(VueYandexMetrika, {
    id: 94615955,
    env: window.location.hostname == 'valutix.io' ? 'production' : 'development',
    debug: false,
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    trackHash: true
    // other options
});

//Глобальные переменные
Vue.prototype.$api_address = window.location.hostname == 'valutix.io' || window.location.hostname == 'www.valutix.io' ? 'https://valutix.io/api' : 'https://staging.valutix.io/api';

Vue.prototype.$globalCurrencies = [];
Vue.prototype.$globalBanks = [];
Vue.prototype.$globalEnabledCards = [];

//user_ID
Vue.prototype.$user_ID = Vue.observable({ value: null });
if (sessionStorage.getItem('user_ID')) {
    Vue.prototype.$user_ID.value = sessionStorage.getItem('user_ID');
}

//status_ID
Vue.prototype.$status_id = Vue.observable({ value: null });
if (sessionStorage.getItem('status_id')) {
    Vue.prototype.$status_id.value = sessionStorage.getItem('status_id');
}

//session_id
Vue.prototype.$session_id = Vue.observable({ value: null });
if (sessionStorage.getItem('session_id')) {
    Vue.prototype.$status_id.value = sessionStorage.getItem('session_id');
}

//user_token
Vue.prototype.$user_token = Vue.observable({ value: null });
if (sessionStorage.getItem('user_token')) {
    Vue.prototype.$user_token.value = sessionStorage.getItem('user_token');
}

Vue.prototype.$close_modal = (ID)=> {
    var modalID = document.getElementById(ID);
    var modalInstance = bootstrap.Modal.getInstance(modalID);
    modalInstance.hide();
}

Vue.prototype.$show_or_clean_error = (field, response, context) => {
    if (response.data.errors[field]) {
      context.errorText[field] = response.data.errors[field].pop();
    } else {
      context.errorText[field] = '';
    }
}

Vue.prototype.$pusherInfo = {
    transaction: null,
    deposit: null,
    payout: null,
    withdrawal: null,
    cash: null,
    balance: null,
    courses: null,
    user: null,
    check: null,
};


const app = new Vue({
    el: '#app',
    router,
    render: h => h(App),
}).$mount('#app');


Vue.config.productionTip = false;

