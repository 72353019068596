<template>
    <transition name='modal-fade'>
    <div class="modal fade new_custom_modal" style='display: block; opacity: 1; background: rgba(29, 28, 43, 0.9);' id="sale" aria-hidden="true" tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Вывод {{crypto.short_title}}</h5>
                    <button class="btn-close btn-close-white shadow-none" type="button" data-bs-dismiss="modal" @click="close" aria-label="Close"></button>
                </div>
                <div class="modal-body" v-if="step == 1">
                    <div class="error-block-text" v-if="error"><p>{{error}}</p></div>
                    <div class="custom-modal__text mb-2 text-start">Куда вывести средства?</div>
                    <div class='custom-modal__select-card mb-4'>
                        <wallet-for-pop-up :wallets="wallets" :title="title" :crypto="crypto" @new_wallet="newWallet" @put="putCardNumber"></wallet-for-pop-up>
                        <span v-if="wallets.length == 0">Добавьте кошельки, чтобы совершить вывод</span>
                    </div>
                    <div class="custom-modal__wrapper mb-sm-2 mb-4 align-items-center">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start">Сколько вывести? {{crypto.short_title}}</div>
                        <div class="custom-modal__wrapper-right">
                            <input class="o-input" style="text-align: center" v-model="form.amount" @keyup='upd' value="0" type="number" />
                            <div @click="saleAll" v-if="this.form.comission != 0 && this.form.amount != (this.balance - this.form.comission)" class="position-relative">
                                <span style="font-size: 14px; color: #35634F; position: absolute; top: -34px; right: 5px; cursor: pointer;">Вывести все</span>
                            </div>
                        </div>
                    </div>
                    <div class="custom-modal__wrapper mb-sm-2 mb-4">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start u-font-weight-600">Текущий баланс:</div>
                        <div class="custom-modal__wrapper-right u-font-size-14"><span>{{parseFloat(balance).toFixed(crypto.count_after_point)}} {{crypto.short_title}}</span></div>
                    </div>
                    <div class="custom-modal__wrapper mb-sm-2 mb-4">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start u-font-weight-600">Комиссия сети:</div>
                        <div class="custom-modal__wrapper-right u-font-size-14 u-font-weight-600"><span>{{form.comission}} {{crypto.short_title}}</span></div>
                    </div>
                    <div v-if="form.ost" class="custom-modal__wrapper mb-sm-2 mb-4">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start">Остаток баланса:</div>
                        <div class="custom-modal__wrapper-right u-font-size-14 u-font-weight-600"><span>{{parseFloat(form.ost).toFixed(crypto.count_after_point)}} {{crypto.short_title}}</span></div>
                    </div>
                    <div class="custom-modal__wrapper">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start"></div>
                        <div v-if="user.status.id == 3" class="custom-modal__wrapper-right u-font-size-14 u-font-weight-600">
                            <button :disabled="query ? true: false" @click="secondStep" class="button button--full-width mb-3" >Отправить заявку на вывод</button>
                        </div>
                        <div v-else class="custom-modal__wrapper-right u-font-size-14 u-font-weight-600">
                            <span style="color: rgb(172, 175, 255);">
                                Пройдите <span class="cursor-pointer text-light" @click="showVerification">верификацию</span>, чтобы совершать выводы
                            </span>
                        </div>
                    </div>
                    <div class="custom-modal__wrapper mb-sm-2 mb-4">
                        <p style="font-size: 12px;margin-top: 10px;">Заявка будет обработана в течение 15 минут. Если средства не придут, обратитесь
                            в поддержку. Наши операторы работают 24/7. Время ответа — до 1 часа.</p>
                    </div>
                </div>
                <div class='modal-body' v-else>
                    <div style='text-align: left; margin-bottom: 20px'>
                        <span class='me-3' style='font-size: 16px'>Ваша заявка на вывод {{this.form.amount}} {{crypto.short_title}} отправлена</span>
                    </div>
                    <button class='button button--full-width mb-3' @click='lastStep()'>Новый вывод</button>
                </div>
            </div>
        </div>
    </div>
    </transition>
</template>

<script>
import WalletForPopUp from "../Wallets/WalletForPopUp.vue";

export default {
    components: {WalletForPopUp},
    props: ['balance', 'wallets', 'courses', 'crypto', 'user'],
    data() {
        return {
            form : {
                bank: '',
                amount: null,
                file: '',
                cardNumber: '',
                ost: this.balance,
                giv: 0,
                comission: 0,
                wallet_id:0
            },
            step: 1,
            title: 'concl' + this.crypto.short_title,
            error: '',
            query: false,
        }
    },
    methods: {
        showVerification() {
                this.$emit('showVerification');
            },
        saleAll() {
            this.form.amount = this.balance - this.form.comission;
            this.upd();
        },
        close(event) {
            this.$emit('close');
        },
        newWallet(){
            this.$emit('new_wallet');
        },
        sale(data) {
            this.$emit('concl', data);
        },
        upd() {
            setTimeout(() => {
                // Преобразование в число
                let amount = parseFloat(this.form.amount);
                // Проверка на число
                amount = parseFloat(amount.toFixed(this.crypto.count_after_point));
                this.form.amount = amount;
                this.form.ost = this.balance - amount - this.form.comission;
            }, 2500);
        },
        firstStep() {
            this.step = 2;
        },
        secondStep() {
            this.query = true;
            // this.state = this.stateTwo;
            let data = {
                amount: this.form.amount,
                // commission:this.form.comission,
                // card_number: this.form.cardNumber,
                wallet_id: this.form.wallet_id,
                cryptocurrency_id: this.crypto.id,
                user_id: this.$user_ID.value,
                token: sessionStorage.getItem('user_token'),
            }
            // console.log(data);
            let resp = '';
            axios.post(this.$api_address +'/withdrawals', data, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            })
            .then(response => {
                console.log(response.data) ;
                if(response.data.message === 'Операция превышает баланс') {
                     alert(response.data.message);
                    this.query = false;

                }
                if(response.data.error) {
                    this.error = response.data.error;
                    this.query = false;
                } else if (response.data.data) {
                    this.sale(response.data);
                    this.step = 2;
                    this.query = false;
                }
            });
        },
        lastStep() {
            this.step = 1;
        },
        putCardNumber(data) {
            console.log(data);
            this.form.cardNumber = data.number;
            this.form.wallet_id = data.id;
            this.form.comission = parseFloat(data.commission);
            this.upd();
        }

    },
}
</script>
