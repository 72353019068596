var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fragment',[_c('section',{staticClass:"first_cash_section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-9 z-1 order-2 order-md-1"},[_c('h1',[_vm._v("Купить или продать валюту за "),_c('span',{staticClass:"text-green"},[_vm._v("наличные")])]),_c('span',{staticClass:"h2 text-green subtitle d-block"},[_vm._v("В удобном для Вас городе")]),_c('div',{staticClass:"d-flex flex-column flex-md-row gap-3"},[_c('router-link',{staticClass:"myButton text-nowrap",attrs:{"exact":"","to":{
                        path: _vm.user && _vm.user.length !== 0 ? '/cash-exchange' : '/registration',
                        query: { action: 'buy' }
                    }}},[_vm._v("Купить за наличные")]),_c('router-link',{staticClass:"myButton text-nowrap",attrs:{"exact":"","to":{
                        path: _vm.user && _vm.user.length !== 0 ? '/cash-exchange' : '/registration',
                        query: { action: 'sale' }
                    }}},[_vm._v("Продать за наличные")])],1)]),_c('div',{staticClass:"col-12 col-md-3 d-flex justify-content-end justify-content-md-start order-1 order-md-2"},[_c('img',{staticClass:"first_img z-0",attrs:{"src":"/static/img/cash/cash_first_section.png"}})])])])]),_c('section',{staticClass:"second_cash_section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('img',{staticClass:"second_cash_section_map position-relative z-0",attrs:{"src":"/static/img/cash/map.png","alt":"map"}}),_c('div',{staticClass:"second_section_info position-relative z-1"},[_c('span',{staticClass:"border border-white rounded px-3 py-1 mb-2 mb-md-1 d-inline-block"},[_vm._v("Безопасно и конфиденциально")]),_c('h2',[_vm._v("Операции с наличными")]),_c('ul',{staticClass:"second_section_list custom_ul p-0 gap-3 flex-wrap"},[_c('li',[_vm._v("Доставка средств или получение в офисе. Гарантируем полную конфиденциальность.")]),_c('li',[_vm._v("Выгодный курс, минимальные комиссии и любые объемы.")]),_c('li',[_vm._v("Работаем 24/7, поддержка всегда на связи.")]),_c('li',[_vm._v("Безупречный информационный сервис, удобный интерфейс.")])])])])])])]),_c('Numbers')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }