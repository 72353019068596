<template>
    <header>
    <nav class="navbar navbar-dark navbar-expand-lg d-none d-lg-block shadow-none" data-bs-theme="dark">
        <div class="container d-flex justify-content-end">
            <ul class="navbar-nav flex-row" v-if="user && user.length !== 0">
                <li class="nav-item">
                    <router-link exact class="nav-link"  to="/">Криптобанк</router-link>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Возможности
                  </a>
                  <ul class="dropdown-menu dropdown-menu-dark border-0 shadow">
                    <li><router-link exact class="dropdown-item"  to="/cash">Наличные</router-link></li>
                    <li><router-link exact class="dropdown-item"  to="/api">API интеграция</router-link></li>
                    <li><router-link exact class="dropdown-item"  to="/invoices">Счета</router-link></li>
                    <li><router-link exact class="dropdown-item"  to="/payment-link">Оплата по ссылке</router-link></li>
                  </ul>
                </li>
                <li class="nav-item">
                    <a target="_blank" class="nav-link" href="https://faq.valutix.io">FAQ</a>
                </li>
                <li class="nav-item">
                    <router-link exact class="nav-link"  to="/contact">Контакты</router-link>
                </li>
            </ul>
        </div>
    </nav>
    <nav class="navbar navbar-expand-lg navbar-dark shadow-none" data-bs-theme="dark">
        <div class="container">
              <router-link exact class="navbar-brand" to="/"><img src="/static/img/logo.svg" alt="logo"></router-link>
          <button class="navbar-toggler border-0 shadow-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="rounded-start offcanvas offcanvas-end text-bg-dark d-flex" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
            <div class="offcanvas-header">
                <div class="d-flex gap-1 align-items-center">
                    <router-link exact class="navbar-brand"  to="/"><img src="/static/img/mobile_logo.png" alt="logo"></router-link>
                    <a target="_blank" href="https://t.me/valutix" class="telegram d-block d-lg-none"><img width="26" height="26" src="/static/img/logos_telegram.svg" alt="telegram"></a>
                </div>
                <button type="button" class="btn-close btn-close-white shadow-none" id="offcanvasClose" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body d-flex flex-column flex-lg-row">
                <!-- Если авторизован -->
                <ul class="navbar-nav flex-grow-1 pe-3 justify-content-lg-center" v-if="user && user.length !== 0">
                    <li class="nav-item">
                        <router-link @click.native="closeOffcanvas" exact class="nav-link"  to="/account">Активы</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link @click.native="closeOffcanvas" exact class="nav-link"  to="/pay-link">Оплата по ссылке</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link @click.native="closeOffcanvas" class="nav-link"  to="/cash-exchange">Наличные</router-link>
                    </li>
                    <li class="nav-item">
                        <a target="_blank" class="nav-link d-block d-lg-none" href="https://faq.valutix.io">FAQ</a>
                    </li>
                    <li class="nav-item">
                        <router-link @click.native="closeOffcanvas" exact class="nav-link d-block d-lg-none"  to="/contact">Контакты</router-link>
                    </li>
                    <li class="nav-item">
                        <hr class="bg_green color_green w-100 d-block d-sm-none">
                      </li>
                    <li class="nav-item">
                        <router-link @click.native="closeOffcanvas" exact class="nav-link d-block d-lg-none"  to="/">Криптобанк</router-link>
                    </li>

                    <li class="nav-item dropdown d-block d-lg-none">
                      <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Возможности
                      </a>
                      <ul class="dropdown-menu dropdown-menu-dark border-0 shadow">
                        <li><router-link @click.native="closeOffcanvas" exact class="dropdown-item"  to="/cash">Наличные</router-link></li>
                        <li><router-link @click.native="closeOffcanvas" exact class="dropdown-item"  to="/api">API интеграция</router-link></li>
                        <li><router-link @click.native="closeOffcanvas" exact class="dropdown-item"  to="/invoices">Счета</router-link></li>
                        <li><router-link @click.native="closeOffcanvas" exact class="dropdown-item"  to="/payment-link">Оплата по ссылке</router-link></li>

                      </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" aria-current="page" :href="path == 'valutix.io' ? 'http://instant.valutix.io' : 'https://staging.instant.valutix.io'">Мгновенный обмен</a>

                    </li>
                    <li class="nav-item">
                        <a target="_blank" href="https://t.me/valutix_bot"  class="nav-link" aria-current="page">Поддержка</a>
                    </li>
                  </ul>
               <!-- Если не авторизован -->
              <ul class="navbar-nav flex-grow-1 pe-3 justify-content-lg-center" v-else>
                <li class="nav-item">
                    <router-link @click.native="closeOffcanvas" exact class="nav-link"  to="/">Криптобанк</router-link>
                </li>
                <li class="nav-item">
                    <a class="nav-link" aria-current="page" :href="path == 'valutix.io' ? 'http://instant.valutix.io' : 'https://staging.instant.valutix.io'">Мгновенный обмен</a>

                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Возможности
                  </a>
                  <ul class="dropdown-menu dropdown-menu-dark border-0 shadow">
                    <li><router-link @click.native="closeOffcanvas" exact class="dropdown-item"  to="/cash">Наличные</router-link></li>
                    <li><router-link @click.native="closeOffcanvas" exact class="dropdown-item"  to="/api">API интеграция</router-link></li>
                    <li><router-link @click.native="closeOffcanvas" exact class="dropdown-item"  to="/invoices">Счета</router-link></li>
                    <li><router-link @click.native="closeOffcanvas" exact class="dropdown-item"  to="/payment-link">Оплата по ссылке</router-link></li>
                  </ul>
                </li>
                <li class="nav-item">
                    <a target="_blank" class="nav-link" href="https://faq.valutix.io">FAQ</a>
                </li>
                <li class="nav-item">
                    <router-link @click.native="closeOffcanvas" exact class="nav-link" to="/contact">Контакты</router-link>
                </li>
              </ul>
            <!-- Если авторизован -->
            <div class='d-flex align-items-start gap-3 mt-auto mt-lg-0' v-if="user && user.length !== 0">
                <router-link @click.native="closeOffcanvas" exact class="nav__lk cursor-pointer d-flex"  to="/account">
                    <img width="34" height="34" src='/img/ico/ico-user.svg' />
                    <div class="d-flex flex-column">
                        <span class="bold_white_16 d-block wrap">{{ user.nickname }}</span>
                        <span class="bold_white_12 d-block">{{ 'ID:' + this.$user_ID.value }}</span>
                    </div>
                </router-link>
                <button class='nav__lk d-flex flex-row d-lg-none' @click="closeOffcanvas_and_logout">
                    <img  width="34" height="34" src='/img/ico/ico-logout.svg' /> 
                    <span class="bold_white_16">Выйти</span>
                </button>
                <button class='nav__lk d-none flex-row d-lg-flex' @click="logout">
                    <img  width="34" height="34" src='/img/ico/ico-logout.svg' /> 
                    <span class="bold_white_16">Выйти</span>
                </button>
                <a target="_blank" href="https://t.me/valutix" class="telegram d-none d-lg-block"><img src="/static/img/logos_telegram.svg" alt="telegram"></a>
            </div>
            <!-- Если не авторизован -->
            <div class="d-flex align-items-center gap-3 flex-wrap flex-lg-nowrap mt-auto mt-lg-0" v-else>
                <router-link @click.native="closeOffcanvas" exact class="border_lightgreen rounded px-3 py-2 text-white text-decoration-none"  to="/login">Вход</router-link>
                <router-link @click.native="closeOffcanvas" exact class="border_lightgreen rounded px-3 py-2 text-white text-decoration-none"  to="/registration">Регистрация</router-link>
                <a target="_blank" href="https://t.me/valutix" class="telegram d-none d-lg-block"><img src="/static/img/logos_telegram.svg" alt="telegram"></a>
            </div>

            </div>
          </div>
        </div>
      </nav>
    </header>
</template>
<script>
import AppSpinner from "./AppSpinner.vue";
export default {
    props: ['user'],
    components: {
        AppSpinner,
    },
    data: () => ({
        nickname: '',
        email: '',
        referal_code: '',
        password: '',
        mobileMenu: false,
        password_repeat: '',
        index: false,
        prod: false,
        errors: {
            'email': '',
            'nickname': '',
            'referal_code': '',
            'password': '',
            'password_repeat': '',
            'general': '',
        },
        isNalShow: false,
        path: window.location.hostname,
    }),
    methods: {
        switchMobileMenu() {
            if (this.mobileMenu) {
                this.mobileMenu = false;
                document.body.style.overflow = 'auto';
            } else {
                this.mobileMenu = true;
                document.body.style.overflow = 'hidden';
            }
        },
        close_nal() {
            this.isNalShow = false;
        },
        showNal(modal) {
            this.isNalShow = true;
        },
        logout() {
            this.$emit('logout');
        },
        login_user() {
            this.$emit('login_user');
        },
        closeOffcanvas() {
            let myOffcanvas = document.getElementById('offcanvasDarkNavbar');
            if(myOffcanvas) {
                let bsOffcanvas = bootstrap.Offcanvas.getInstance(myOffcanvas);
                bsOffcanvas.hide();
            }
        },
        closeOffcanvas_and_logout() {
            this.closeOffcanvas();
            this.logout();
        }
    }
}
</script>

<style>
    .navbar {
        font-size: 16px;
        line-height: normal;
    }
    .navbar li {
        margin-bottom: 0;
    }
    .router-link-active {
        color: #4AA485 !important;
    }
    .dropdown-menu.show {
        background: #35634F;
    }
    .nav-link {
        color: #fff;
        text-wrap: wrap;
        word-break: break-all;
    }
    .navbar-nav .nav-link.active {
        color: #4AA485;
    }
    .text-bg-dark {
        background-color: #35634F !important;
    }
    .border_lightgreen  {
        border: 1px solid #4AA485;
    }
    .bold_white_16 {
        color: #FFF;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .bold_white_12 {
        color: #FFF;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    .wrap {
        overflow-wrap: wrap;
        text-wrap: balance;
        word-break: break-all;
    }
    

    @media(max-width: 768px){

        header .bg_green {
            background: #4AA485;
        }

    }

</style>



