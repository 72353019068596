<template>
    <section class="registration_section vh-100">
        <div class="container">
            <div class="row">
                <div class="col-5 d-none d-md-block">

                    <div class="top">
                        <h2 class="list_title">Бонусы от регистрации</h2>
                        <ul>
                            <li>Работа с инвойсами (многоразовые счета)</li>
                            <li>Более выгодные условия обмена</li>
                            <li>Мгновенная конвертация</li>
                            <li>Приоритетная поддержка</li>
                        </ul>
                    </div>

                    <div class="d-flex flex-column align-items-center flex-lg-row">
                        <div class="left w-100">
                            <h2>Подписывайся <br> на наш телеграм</h2>
                            <a target="_blank" href="https://t.me/valutix" class="button w-100 fs-5">Подписаться</a>
                        </div>
                        <div class="right">
                            <img src="/static/img/reg_tg2.png" alt="telegram">
                        </div>
                    </div>

                </div>

                <!-- Шаг 1 -->
                <div class="col-12 col-md-5 mx-auto z-index-2 step_1" v-if="step_1">
                    <h1>Создать аккаунт</h1>
                    <div class="mb-4 position-relative">
                        <label for="exampleFormControlInput1" class="form-label">Адрес электронной почты</label>
                        <input autocomplete="email" type="email" class="form-control shadow-none" id="exampleFormControlInput1" v-model="email" @focus="error = ''">
                        <div class="form-text text_warning" v-if="error !== ''">{{ error }}</div>
                    </div>
                    <div class="mb-4 position-relative">
                        <label for="exampleFormControlInput1" class="form-label">Реферальный код (необязательно)</label>
                        <input autocomplete="off" type="number" class="form-control shadow-none" id="exampleFormControlInput1" v-model="ref_code" @input="checkReferal">
                        <div class="form-text text_warning" v-if="errorRef">Неверный реферальный код</div>
                    </div>
                    <div class="mb-4">
                        <button class="button w-100 mw-100" :disabled="email === '' || errorRef === true || agree === false" @click="checkMail">Создать аккаунт</button>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input shadow-none" type="checkbox" value="" id="flexCheckDefault" checked v-model="agree">
                        <label class="form-check-label" for="flexCheckDefault">
                            Создавая аккаунт, я принимаю 
                            <router-link to="/terms_of_use">Условия предоставления услуг</router-link>, 
                            <router-link to="/aml-kyc">Информацию о рисках и соответствии нормативным требованиям</router-link>
                             и
                             <router-link to="/privacy-police">Заявление о политике конфиденциальности valutix</router-link>
                        </label>
                    </div>
                    <span class="d-block text-center">Уже есть аккаунт? <router-link @click.native="closeOffcanvas" exact to="/login">Войти</router-link></span>
                </div>

                <!-- Шаг 2 -->
                <div class="col-12 col-md-5 mx-auto z-index-2 step_2" v-if="step_2">
                    <h1>Введите код из <br> электронной почты</h1>
                    <div class="mb-4"><span class="me-2">Мы отправили код на {{ email }}</span><a href="#" @click="step_1 = true, step_2 = false">Изменить</a></div>
                    <div class="pincode mb-4 position-relative">
                        <input
                            v-for="(item, index) in pinInputs"
                            :key="index"
                            type="number"
                            v-model="pinInputs[index]"
                            @input="handleInput(index)"
                            ref="inputs"
                            maxlength="1"
                        >
                        <div class="form-text text_warning" v-if="errorPincode"> Неверный код</div>
                      </div>
                    <button class="button w-100 mw-100 next_2" :disabled="!pinInputs.every(input => input.length > 0)" @click="checkCode">Далее</button>
                    <span>Ничего не получили? <a class="cursor-pointer" @click="checkMail">Отправить еще раз</a> {{ i > 0 ? '('+i+')' : '' }}</span>
                </div>

                <!-- Шаг 3 -->
                <div class="col-12 col-md-5 mx-auto z-index-2 step_3"  v-if="step_3">
                    <h1 class="mb-4">Создайте пароль</h1>
                    <div class="mb-4 position-relative">
                        <div class="input-group">
                            <input type="password" autocomplete="new-password" v-model="password" class="form-control border-0 shadow-none">
                            <span class="input-group-text bg-white">
                                <img src="/static/img/remove_red_eye.svg" class="cursor-pointer" @click="showPassword($event.target)">
                            </span>
                        </div>
                        <div class="form-text text_warning" v-if="error !== ''">{{ error }}</div>
                    </div>
                    <div class="mb-4">
                        <span class="fs_16 ul_title">Ваш пароль должен содержать:</span>
                        <ul>
                            <li :class="{ 'text_warning': !isLengthValid }">Длина от 8 до 32 символов</li>
                            <li :class="{ 'text_warning': !hasLowercaseLetter }">Хотя бы одну строчную букву</li>
                            <li :class="{ 'text_warning': !hasUppercaseLetter }">Хотя бы одну прописную букву</li>
                            <li :class="{ 'text_warning': !hasDigit }">Хотя бы одну цифру</li>
                        </ul>
                    </div>
                    <div class="mb-4 position-relative" v-if="isPasswordValid">
                        <label for="basic-url" class="form-label">Повторите пароль</label>
                        <div class="input-group">
                            <input type="password" class="form-control border-0 shadow-none" v-model="password_repeat">
                            <span class="input-group-text bg-white">
                                <img src="/static/img/remove_red_eye.svg" class="cursor-pointer"  @click="showPassword($event.target)">
                            </span>
                        </div>
                        <span class="form-text text_warning fs_13" v-if="isPasswordValid && (password !== password_repeat)">Пароли не совпадают</span>
                      </div>
                    <button class="next_btn button w-100 mw-100" :disabled="!isShowNext" @click="register">Зарегистрироваться</button>
                </div>
                
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Registration",
        data() {
            return {
                pinInputs: ['', '', '', '', '', ''],
                email: '',
                ref_code: '',
                password: '',
                password_repeat: '',
                step_1: true,
                step_2: false,
                step_3: false,
                error: '',
                errorPincode: false,
                errorRef: false,
                agree: true,
                i: 0,
            };
        },
        computed: {
        // Вычисляемое свойство для объединения значений из всех инпутов
            pincode() {
                return this.pinInputs.join('');
            },
            isLengthValid() {
                return this.password.length >= 8 && this.password.length <= 32;
            },
            hasLowercaseLetter() {
                return /[a-z]/.test(this.password);
            },
            hasUppercaseLetter() {
                return /[A-Z]/.test(this.password);
            },
            hasDigit() {
                return /\d/.test(this.password);
            },
            isPasswordValid() {
                return this.isLengthValid && this.hasLowercaseLetter && this.hasUppercaseLetter && this.hasDigit ? true : false;
            },
            isShowNext() {
                return this.isPasswordValid && (this.password === this.password_repeat) ? true : false;
            },
        },
        methods: {
            handleInput(index) {
                if (this.pinInputs[index].length > 1) {
                    // Если введено более одного символа, переносим остаток в следующие инпуты
                    const overflow = this.pinInputs[index].slice(1); // Получаем остаток строки
                    this.pinInputs[index] = this.pinInputs[index][0]; // Оставляем в текущем инпуте только первую цифру
                    for (let i = 0; i < overflow.length && (index + i + 1) < this.pinInputs.length; i++) {
                    this.$set(this.pinInputs, index + i + 1, overflow[i]);
                    }
                }

                // Перемещаем фокус на следующий инпут, если текущий заполнен
                if (this.pinInputs[index].length === 1 && index < this.pinInputs.length - 1) {
                    this.$refs.inputs[index + 1].focus();
                }
            },
            showPassword(eventTarget) {
                let myInput = $(eventTarget).closest('.input-group').find('.form-control');
                myInput.attr('type', function(index, currentType) {
                    return currentType === 'password' ? 'text' : 'password';
                });
            },

            //Проверка почты
            checkMail() {
                this.i++;
                let data = { email: this.email }
                axios.post(this.$api_address + '/users/register_email', data, {
                    validateStatus: function (status) {
                        return status < 500;
                    }
                }).then(response => {
                    if(response.data.errors) {
                        this.error = 'Email занят';
                        return;
                    }
                    else {
                        if(response.data.data) {
                            this.step_1 = false;
                            this.step_2 = true;
                            this.session_id = response.data.data.session_id;
                        } else {
                            console.log('Ошибочка мыла');
                            this.error = 'Email занят';
                        }
                    }
                       
                }).catch(function (error){
                    console.log(error.response);
                });
            },

            checkReferal() {
                if(this.ref_code == '' || this.ref_code == null) {
                    this.errorRef = false;
                    return;
                }
                let data = { ref_code: this.ref_code }
                axios.post(this.$api_address + '/users/check_referral', data, {
                    validateStatus: function (status) {
                        return status < 500; 
                    }
                }).then(response => {
                if(typeof(response.data.errors) != "undefined" && response.data.errors !== null) {
                    this.errorRef = true;
                }
                else {
                    this.errorRef = false;
                }
                }).catch(function (error){
                    this.errorRef = true;
                });
            },

            //Проверка кода
            checkCode() {
                let data = {
                    code: this.pincode,
                    session_id: this.session_id
                }
                axios.post(this.$api_address + '/users/check_code', data, {
                    validateStatus: function (status) {
                        return status < 500; // Resolve only if the status code is less than 500
                    }
                }).then(response => {
                    if(response.data.message) {
                        this.errorPincode = true;
                    } else {
                        this.step_2 = false;
                        this.step_3 = true;
                        sessionStorage.setItem('session_id', this.session_id);
                    }
                }).catch(function (error){
                    this.errorPincode = true;
                });
            },

            //Регистрация
            register() {
                let data = {
                    nickname: this.email,
                    email: this.email,
                    password: this.password,
                    password_repeat: this.password_repeat
                }
                if (this.ref_code !== '') {
                    data.ref_code = this.ref_code;
                }
                axios.post(this.$api_address + '/users', data, {
                    validateStatus: function (status) {
                        return status < 500; // Resolve only if the status code is less than 500
                    }
                })
                .then(response => {
                    if(response.data.data) {
                        if(this.$metrika !== undefined) {
                            this.$metrika.reachGoal('registration_success');
                        }
                        sessionStorage.setItem('user_ID', response.data.data.id);
                        sessionStorage.setItem('user_token', response.data.data.token['token']);
                        sessionStorage.setItem('status_id', response.data.data.status['id']);
                        this.$emit('login_user');
                    } else {
                        if(response.data.errors.password) {
                            this.error = true;
                        }
                        if(response.data.errors.password_repeat) {
                            this.error = true;
                        }

                    }
                })
                .catch(function (error){
                    this.error = true;
                });
            },
        },
    }

</script>

<style scoped>

    h1, .h1 {
        font-size: 30.5px;
        font-weight: 600;
        margin-bottom: 17px;
        text-transform:unset;
    }

    a {
        font-size: inherit;
        color: #69E278;
    }

    h2, .h2 {
        font-size: 25px;
        font-weight: 600;
    }

    .fs_16 {
        font-size: 16px;
    }

    .list_title {
        margin-bottom: 16px;
    }

    .registration_section {
        padding: 74px 0 84px;
        background: left 200px bottom 0 no-repeat url(/public/static/img/gradient.png);
        position: relative;
    }

    .registration_section::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 54%;
        background-color: #35634F;
        z-index: 0;
    }

    .registration_section .top {
        margin-bottom: 125px;
    }

    button:disabled {
        opacity: 0.3;
    }

    .form-check-input {
        width: 17px;
        height: 17px;
        padding: 2px;
        margin-top: 7px;
        border-radius: 50%;
        border: 2px solid #fff;
        background: transparent;
        cursor: pointer;
    }

    .form-check-input:checked[type=checkbox] {
        background-image: url(/public/static/img/ellipse.svg);
        background-repeat: no-repeat;
        background-position: center;
    }

    .registration_section ul li {
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    .registration_section .top ul li:before {
        content: url(/public/static/img/ellipse.svg);
        margin-right: 10px;
    }

    .registration_section .step_3 ul li {
        color: #74ED79;
    }

    .registration_section .top ul li:not(:last-child) {
        margin-bottom: 10px;
    }

    .registration_section .step_1 ul li:before {
        content: url(/public/static/img/ellipse.svg);
        margin-right: 10px;
        margin-bottom: 2px;
        display: block;
    }

    .form-check {
        margin-bottom: 44px;
    }

    .form-check-label {
        font-size: 13px;
    }

    .pincode { 
	    text-align: center;
        justify-content: space-between;
        display: flex;
        gap: 10px;
    }
    .pincode input[type="number"] {
        display: inline-block;
        border: none;
        border-radius: 5px;
        width: 68px;
        height: 68px;
        line-height: 50px;
        text-align: center;
        color: #212121;
        font-size: 30.5px;
        font-style: normal;
        font-weight: 400;
        padding: 16px 0px;
        outline: none;
        background-color: #fff;
        -moz-appearance: textfield;
        -webkit-appearance: textfield;
        appearance: textfield;
    }
    .pincode input[type="number"]::-webkit-outer-spin-button,
    .pincode input[type="number"]::-webkit-inner-spin-button {
        display: none;
    }
    
    .pincode input[type="number"]:focus {
        border: 2px solid #1dd216;
    }

    .next_2 {
        margin-bottom: 156px;
    }

    .text_warning {
        color: #FF6D6D !important;
    }

    .fs_13 {
        font-size: 13px;
    }

    .form-text {
        font-size: 13px;
        position: absolute;
        left: 0;
        bottom: -20px;
    }

    .next_btn {
        margin-bottom: 110px;
    }

    @media(max-width: 1024px) {
        .pincode input[type="number"] {
            width: 50px;
            height: 50px;
        }
    }



    @media(max-width: 768px) {
        .registration_section {
            padding: 50px 0;
        }
        .registration_section::after {
            display: none;
        }
    }

    @media(max-width: 425px) {
        h1 {
            margin-bottom: 57px;
            font-size: 25px;
        }
        .registration_section {
            padding: 30px 26px 50px;
            background: left -200px bottom -150px no-repeat url(/public/static/img/gradient.png);
        }

        .form-check {
            margin-bottom: 144px;
        }

        .step_3 ul li:not(:last-child) {
            margin-bottom: 4px;
        }

        .step_3 .ul_title {
            margin-bottom: 4px;
        }

        .pincode input[type="number"] {
            width: 35px;
            height: 50px;
        }

    }

</style>