<template>
    <div class='row'>
        <div class='col-12 mb-5' id="operation-history-id">
            <div class='o-text-primary u-font-weight-600 mb-2'>История операций</div>
            <div class='history'>
                <app-spinner v-if="history_load == true" :load_item="history_load" :load_page="false"></app-spinner>
                <history-item v-if="history_load !== true" :key="index" v-for="(data, index) in paginatedElements" :data='data' :crypto="crypto" :currencies="currencies"></history-item>
            </div>
            <div class="history_pagen-block">
                <pagination2
                    v-if="totalChecksCount > 10"
                    v-model="page"
                    :records="totalChecksCount"
                    @paginate="clickCallback"
                    :per-page="perPage"
                    :options="{
                         chunk: 4,
                         edgeNavigation: true,
                         texts: {
                            first: '1',
                            last: totalPages.toString(),
                         }
                        }"
                    
                />
            </div>
        </div>
    </div>
</template>
<script>
import HistoryItem from './HistoryItemComponent.vue';
import AppSpinner from "./General/AppSpinner.vue";
import Pagination2 from 'vue-pagination-2';
export default {
    props: ['elements', 'page_count', 'history_load', 'crypto', 'currencies'],
    components: {
        HistoryItem,
        AppSpinner,
        Pagination2,

    },
    filters: {
        format: val => `${val}`.replace(/(\d)(?=(\d{4})+([^\d]|$))/g,
            '$1 '),
    },
    data () {
        return {
            page: 1,
            perPage: 10,
        }
    },
    methods: {
        clickCallback (page)  {
            this.page = page
        }
    },
    computed: {
        totalChecksCount() {
            console.log(this.elements);
            return Object.values(this.elements).length
        },
        paginatedElements() {
            const start = (this.page - 1) * this.perPage;
            const end = start + this.perPage;
        return Object.values(this.elements).slice(start, end);
    },
    totalPages() {
        return Math.ceil(this.totalChecksCount / this.perPage);
    },
    },
}
</script>
