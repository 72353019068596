<template>
    <div class='col-12 mb-4' id='WalletsList'>
        <div class='o-text-primary u-font-weight-600 mb-2'>Криптокошельки</div>
        <div class='u-bg-secondary u-border-radius'>
            <div class='lk__cart'>
                <div class='lk__cart-left' style="max-width: 64vw;">
                    <wallet @wallet_delete='delete_wallet(index)' :key='index' v-for='(data,index) in wallets.slice(0, 2)' :data='data' :networks="networks" :crypto="crypto"></wallet>
                    <span v-if="wallets_count == 0">Добавьте кошельки, чтобы совершать вывод валюты</span>
                    <div v-if="wallets_count > 2">
                        <span class='showAllText' @click='showWalletList'>Показать все ({{wallets_count}})</span>
                    </div>
                </div>
                <button class='lk__cart-right' @click='showAddWallet'>
                    <div class='lk__cart-add'>+</div>
                    <span id='addWalletButton' class='u-font-size-16' >Добавить кошелек</span>
                </button>
                <wallet-list v-show='forModal.isWalletListVisible' :wallets='wallets' :networks="networks" :crypto="crypto" @delete="delete_wallet" @close='closeWalletList' />
                <add-wallet v-show='forModal.isAddWalletVisible' @close='closeAddWallet' :title="title" :networks="networks" :cryptos="crypto"/>
            </div>
        </div>
    </div>
</template>

<script>
import Wallet from './Wallet.vue';
import WalletList from './WalletList.vue';
import AddWallet  from './AddWallet.vue';
export default {
    components: {
        AddWallet ,
        Wallet,
        WalletList,
    },
    created() {
        axios.get(this.$api_address +'/networks').then(response =>
        {
            this.networks = response.data.data;

        });
    },
    props: ['wallets','wallets_count', 'crypto', 'flag'],
    data () {
        return {
            forModal: {
                isWalletListVisible: false,
                isAddWalletVisible: false
            },
            forWallets: {
                string: '123',
                Wallets : [],
                WalletsCount: 0
            },
            networks:[],
            title: 'WalletComponent',
        };
    },
    watch: {
        flag: {
            handler() {
                if(this.flag == true) {
                    this.forModal.isAddWalletVisible = true;
                }
            }
        }
    },
    methods: {
        delete_wallet(index) {
            console.log('index:', index);
            this.$emit('delete', index);
        },
        showWalletList(modal) {
            this.forModal.isWalletListVisible = true;
            document.body.style.overflow = 'hidden';

        },
        closeWalletList() {
            this.forModal.isWalletListVisible = false;
            document.body.style.overflow = 'auto';
        },
        showAddWallet() {
            if(this.$metrika !== undefined) {
                this.$metrika.reachGoal('add_wallet');
            }
            this.forModal.isAddWalletVisible = true;
            document.body.style.overflow = 'hidden';
        },
        closeAddWallet() {
            this.forModal.isAddWalletVisible = false;
            document.body.style.overflow = 'auto';
            this.$emit('close');
        },
    },
}
</script>


