<template>
    <main>
    <section class="first_api_section">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-9 z-1 order-2 order-md-1">
                    <div class="left">
                        <h1 class="mb-3"><span class="text-green">Платежная система</span> нового поколения</h1>
                        <span class="h2 text-green subtitle d-block">Процессинг платежей по API Россия, Казахстан,
                            Узбекистан</span>
                        <span class="sub_subtitle">Софт по приему выплат, автоматизации,
                            конвертации в криптовалюту, P2P платежный виджет.</span>
                        <button class="myButton"  data-bs-toggle="modal" data-bs-target="#supportModal">Подключить</button>
                    </div>
                    <div class="right">

                    </div>
                </div>
                <div class="col-12 col-md-3 d-flex justify-content-end justify-content-md-start order-1 order-md-2">
                    <img src="/static/img/api/api_first_section.png" class="first_img z-0">
                </div>
            </div>
        </div>
    </section>
    <section class="second_api_section">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-4">
                    <div class="card border_green bg-transparent">
                        <img width="96" src="/static/img/api/lock.png" alt="">
                        <h6>Valutix payments это</h6>
                        <span>
                            Безопасность. Контроль над
средствами полностью
принадлежит вам, а не 3-й стороне
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="card border_green bg-transparent">
                        <img width="171" src="/static/img/api/skate_2.png" alt="">
                        <h6>Valutix payments это</h6>
                        <span>
                            Платежный шлюз, мерчант аккаунт с защищенной и надежной системмой платежей
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="card border_green bg-transparent">
                        <img width="209" src="/static/img/api/diagramm.png" alt="">
                        <h6>Valutix payments это</h6>
                        <span>
                            Минимальные комиссии для
обработки криптоплатежей. Снижение
затрат на обработку транзакций
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="how_it_works_api_section">
        <div class="container">
            <div class="card">
                <div class="row mb-4 mb-sm-5">
                    <div class="col-12 col-md-6 col-lg-3">
                        <h2 class="mb-3 m-sm-0">Уникальный <span class="text-green">платежный виджет</span></h2>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3 d-flex align-items-end">
                        <span class="fs-5">Как это работает?</span>
                    </div>
                </div>
                <div class="row gap-3 gap-md-0">
                    <div class="col-12 col-md-6 col-lg-3">
                        <div class="how_it_works_list_item">
                            <span class="rounded-circle bg_lightgreen center mb-2">1</span>
                            <p class="m-0">Вы устанавливаете специальный скрипт на свой сайт и подключаете кнопку
                                “Пополнить баланс” к нему.</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <div class="how_it_works_list_item">
                            <span class="rounded-circle bg_lightgreen center mb-2">2</span>
                            <p class="m-0">Пользователь совершает перевод в нашу платежную систему и подтверждает
                                отправку чеком.</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <div class="how_it_works_list_item">
                            <span class="rounded-circle bg_lightgreen center mb-2">3</span>
                            <p class="m-0">После успешной оплаты, Вы получаете уведомление в виде callback ответа,
                                который Вы можете обработать любым образом.</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <div class="how_it_works_list_item">
                            <span class="rounded-circle bg_lightgreen center mb-2">4</span>
                            <p class="m-0">Вы получаете средства в криптовалюте USDT (в ссети TRC-20) в течении 5 минут
                                после успешной транзакции.</p>
                        </div>
                    </div>
                    <div class="col d-flex d-md-none my-4">
                        <div class="myButton">Попробовать</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="how_use_api_section text-white">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <h2>Как начать пользоваться платежным модулем?</h2>
                    <img class="d-none d-lg-block" src="/static/img/api/Modernblack.png" width="448" height="478" alt="rocket">
                </div>
                <div class="col-12 col-lg-6">
                    <div class="header_block">
                        <p>Начав работу с Valutix payments, Вы получите полноценный
                            софт по процессингу платежей: прием, автоматизация
                            пополнения балансов, отправка заказов в работу и другое.
                        </p>
                        <p>Вы сможете принимать оплату с клиентов по всему миру.</p>
                    </div>
                    <div class="info d-flex flex-column gap-3">
                        <div class="border_green rounded py-3 px-4">
                            <h6>Свяжитесь с нами</h6>
                            <span>Заполните форму и наш аккаунт-менеджер свяжется с Вами и расскажет про интеграцию софта и детали его работы.</span>
                        </div>
                        <div class="border_green rounded py-3 px-4">
                            <h6>Получите КП</h6>
                            <span>Оставьте контакты для связи и мы вышлем коммерческое
                                предложение для ознакомления.</span>
                        </div>
                        <div class="border_green rounded py-3 px-4">
                            <h6>Начните приём платежей</h6>
                            <span>После быстрой интеграции, Ваш мерчант-аккаунт будет готов. Вы сможете принимать платежи в удобном формате.</span>
                        </div>
                    </div>
                    <button class="myButton mb-5 mb-lg-0" data-bs-toggle="modal" data-bs-target="#supportModal">Подключить</button>
                    <img class="d-block d-lg-none" src="/static/img/api/Modernblack_mobile.png" alt="rocket">
                </div>
            </div>
        </div>
    </section>
</main>

</template>

<script>
    export default {
        name: 'API',

    }
</script>

<style scoped>
    h1 {
        max-width: 788px;
        font-size: 70.5px;
        font-weight: 700;
        line-height: 90.49%;
        text-transform: none;
    }
    
    h2, .h2 {
        font-size: 30.5px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    
    h6 {
        font-size: 20px;
        font-weight: 600;
    }

    .subtitle {
        font-weight: 500;
        max-width: 492px;
    }

    .sub_subtitle {
        display: inline-block;
        max-width: 383px;
        margin-bottom: 78px;
    }

    .first_api_section {
        padding: 116px 0 22px;
    }

    .first_img {
        margin-left: -300px;
        margin-top: -50px;
    }

    .card {
        padding: 27px;
        color: #fff;
        height: 100%;
    }

    .card img {
        margin: -140px auto 60px;
    }

    .second_api_section {
        padding: 164px 0 121px;
    }

    .how_it_works_api_section {
        padding: 30px 0 84px;
    }

    .how_it_works_api_section .card {
        background: right bottom no-repeat url(/public/static/img/api/third_section_gradient.png), #35634F;
    }

    .how_it_works_list_item span {
        width: 31px;
        height: 31px;
    }

    .how_use_api_section {
        background: left bottom no-repeat url(/public/static/img/api/gradient.png);
        padding: 87px 0 48px;
    }

    .how_use_api_section h2 {
        margin-bottom: 107px;
    }

    .header_block {
        margin-bottom: 55px;
    }

    .border_green {
        border: 1px solid #35634F;
    }

    .how_use_api_section .info {
        margin-bottom: 74px;
    }

    @media(max-width: 768px) {
        .second_api_section .row {
            gap: 144px;
        }

        .card img {
            margin: -140px auto 40px;
        }

        .first_img{
            margin-left: unset;
            margin-top: unset;
            margin-right: -100px;
            margin-bottom: -100px;
            max-width: 374px;
        }

        section {
            padding: 50px 0 50px;
        }

        .how_use_api_section h2 {
            margin-bottom: 15px;
        }

        .how_use_api_section .info {
            margin-bottom: 30px;
        }
    }

    @media(max-width: 500px) {
        h1 {
            font-size: 30.5px;
            max-width: 232px;
        }

        .container {
            padding-right: 38px;
            padding-left: 38px;
        }

        .subtitle {
            margin-bottom: 35px;
        }

        .first_api_section {
            padding: 100px 0 50px;
        }

        .second_api_section .container, .how_it_works_api_section .container, .how_use_api_section .container {
            padding: 0 18px;
        }

        .second_api_section {
            padding-bottom: 50px;
        }

        .how_it_works_api_section {
            padding: 50px 0 50px;
        }

        .how_it_works_api_section h2 {
            max-width: 240px;
        }

        .how_use_api_section {
            padding: 73px 0 50px;
        }

        .header_block {
            margin-bottom: 32px;
        }
        
    }
</style>