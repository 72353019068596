<template>
    <div class='row md-mb-5'>
        <div class='col-lg-5 col-12 mb-5 mb-md-0' id='MyActives'>
            <div class='o-text-primary u-font-weight-600 mb-2'>Мои активы</div>
            <div class='mb-3'>
                <CryptoElementComponent v-for="crypto in cryptos"
                  :crypto="crypto"
                  @buy="buy"
                  @sale="sale"
                  @concl="concl"
                  @deposit="deposit"
                  @cash="cash"
                  @new_buy="newBuy"
                  @new_deposit="newDeposit"
                  @new_card="newCard"
                  @new_wallet="newWallet"
                  @showVerification="showModal"
                  :cards="cards"
                  :wallets="wallets"
                  :key="crypto.id"
                  :balances="balances"
                  :user="user"
                  :courses="crypto.short_title == 'USDT' ? '1' : courses_BTC2USDT_rate"
                  :courses_list="courses"
                  :for_buy="for_buy"
                  :for_deposit="for_deposit"
                  :bank_list="bank_list"
                  :currencies="currencies"
                />
            </div>
             <div class="row">
                <div class="col-12 mb-3">
                    <div class="row">
                        <div class="col-7 pt-1 pt-lg-0 text-start align-items-center">
                            <span class="o-text-primary u-font-weight-600 mb-2" id="myChecks">
                                Выставленные счета
                            </span>
                        </div>
                        <div class="col-5 text-end">
                            <button  @click="showNewCheck" data-bs-toggle="modal" data-bs-target="#newCheckModal" class="button button-new-check button--full-width">Новый счет</button>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row checks-header cursor-pointer">
                        <div @click="changeCheckTypeShow" class="col pb-3 pt-3 text-center" :class="checkTypeShow == 'Единоразовый' ? 'active' : ''">Единоразовый</div>
                        <div @click="changeCheckTypeShow" class="col pb-3 pt-3 text-center" :class="checkTypeShow == 'Многоразовый' ? 'active' : ''">Многоразовый</div>
                    </div>
                    <div class="row row-cols-1 mb-2">
                        <div class="col d-none d-lg-block no_jump_bills" :class="checksList.length === 0 ? 'no_min_height' : ''">
                            <div class="row checks-body-header">
                                <div class="col-3 checks-body-header-text pb-2 pt-2">ID счета</div>
                                <div v-if="checkTypeShow == 'Единоразовый'" class="col-3 checks-body-header-text pb-2 pt-2">Дата</div>
                                <div v-if="checkTypeShow == 'Многоразовый'" class="col-3 checks-body-header-text pb-2 pt-2">Оплат</div>
                                <div class="col-3 checks-body-header-text pb-2 pt-2">Сумма</div>
                                <div class="col-3 checks-body-header-text pb-2 pt-2">Статус</div>
                            </div>
                            <p class="w-100 text-center" v-if="checksList.length === 0">Вы пока не выставляли счёт</p>

                            <div @click="showCheck(check.id)" class="row cursor-pointer checks-body-header" v-for="check in checksList" :key="check.id">
                                <div class="col-3 checks-body-text pb-1 pt-1">{{check.user_id}}-{{check.id}}</div>
                                <div v-if="checkTypeShow == 'Единоразовый'" class="col-3 checks-body-text pb-1 pt-1">{{moment(check.created_at).format("DD-MM-YYYY H:mm")}}</div>
                                <div v-if="checkTypeShow == 'Многоразовый'" class="col-3 checks-body-text pb-1 pt-1">{{check.pay_count}}</div>
                                <div class="col-3 checks-body-text pb-1 pt-1">{{check.commission_type == 1 ? parseInt(check.amount/(1 - 0.005)) : check.amount}} {{check.currency.short_title}}</div>
                                <div class="col-3 checks-body-text pb-1 pt-1">{{check.status}} <p class="d-none d-lg-block m-0 opacity-0">0</p></div>
                            </div>
                        </div>
                        <div class="col d-block d-lg-none">
                            <div class="row row-cols-1  ms-0 me-0 checks-body">
                                <div class="col p-4">
                                    <div @click="showCheck(check.id)" class="row row-cols-2 checks-border pt-2" v-for="check in checksList" :key="check.outer_id">
                                        <div class="col checks-body-header-text pb-1 pt-1">ID счета</div>
                                        <div class="col checks-body-text pb-1 pt-1">{{check.user_id}}-{{check.id}}</div>
                                        <div v-if="checkTypeShow == 'Единоразовый'" class="col checks-body-header-text pb-1 pt-1">Дата</div>
                                        <div v-if="checkTypeShow == 'Единоразовый'" class="col checks-body-text pb-1 pt-1">{{moment(check.created_at).format("DD-MM-YYYY H:mm")}}</div>
                                        <div v-if="checkTypeShow == 'Многоразовый'" class="col checks-body-header-text pb-1 pt-1">Оплат</div>
                                        <div v-if="checkTypeShow == 'Многоразовый'" class="col checks-body-text pb-1 pt-1">{{check.pay_count}}</div>
                                        <div class="col checks-body-header-text pb-1 pt-1">Сумма</div>
                                        <div class="col checks-body-text pb-1 pt-1">{{check.commission_type == 1 ? parseInt(check.amount/(1 - 0.005)) : check.amount}} {{check.currency.short_title}}</div>
                                        <div class="col checks-body-header-text pb-1 pt-1">Статус</div>
                                        <div class="col checks-body-text pb-1 pt-1">{{check.status}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <pagination2
                        :key="totalPages"
                        v-if="totalChecksCount > 1"
                        v-model="page"
                        :records="totalChecksCount"
                        @paginate="clickCallback"
                        :per-page="perPage"
                        :options="{
                            chunk: 4,
                            edgeNavigation: true,
                            texts: {
                               first: '1',
                               last: totalPages.toString(),
                            }
                        }"
                    />
                </div>
            </div> 
        </div>
         <div class='col-lg-7 col-md-12 col-12'>
            <div class='row'>
                <div class='col-12 col-md-7 mb-4'>
                    <conversion-component :courses="courses" :crypto="cryptos" @convert="convert" :balances="balances"></conversion-component>
                </div>
                <div class='col-12 col-md-5 mb-4 ver'>
                    <div  class='o-text-primary u-font-weight-600 mb-2'>
                        <span v-if="user && user.status.id !== 3">Верификация </span>
                        <span v-if="user && user.status.id === 3">Профиль </span>
                        <img v-if="user && user.status.id == 2" src="/static/img/verification/ver_step_2.svg" width="16" height="16">
                        <img v-else-if="user && user.status.id == 3" src="/static/img/verification/ver_step_3.svg" width="16" height="16">
                        <img v-else src="/static/img/verification/ver_step_other.svg" width="16" height="17">
                    </div>
                    <app-spinner v-if="user_load == true" :load_item="user_load" :load_page="false"></app-spinner>
                    <div class='u-bg-secondary p-3 u-border-radius size_fix' v-if="user && user.status.id == 1 && user_load == false">
                        <div class='mb-3 f15'>
                            <span>Необходима верификация</span>
                        </div>
                        <div class='lk__list'>
                            <p>Для совершения сделок по покупке и продаже криптовалюты на суммы свыше 100 USDT или эквивалента в других валютах необходимо пройти процедуру верификации документов</p>
                            <button class='button button--full-width ver_btn' @click="showModal">Верификация</button>
                        </div>
                    </div>
                    <div class='u-bg-secondary p-3 u-border-radius size_fix' v-else-if="user && user.status.id == 2 && user_load == false">
                        <div class='mb-3 f15'>
                            <span>Данные проверяются</span>
                        </div>
                        <div class='lk__list'>
                            <p>Срок верификации от 30 до 60 минут</p>
                            <button class='button button--full-width ver_btn' @click="showModal">Верификация</button>
                        </div>
                    </div>
                    <div class='u-bg-secondary px-3 py-1 u-border-radius size_fix f14 user_block overflow-hidden' v-else-if="user && user.status.id == 3 && user_load == false">
                        <div class='mb-4 text-start'>
                            <b>Пользователь:</b>
                            <span>{{user.full_name}}</span>
                            <!-- <div class='lk__list justify-content-start align-items-start gap-4' style="height:104px !important"> -->
                                <b>Email:</b>
                                <span>{{user.email}}</span>
                                <b>Телефон:</b>
                                <span>{{user.phone_number}}</span>
                                <b>Реферальный код:</b>
                                <span>{{user.referral_code}}</span>
                                <b>Дата регистрации:</b>
                                <span>{{moment(user.created_at).format("DD-MM-YYYY H:mm")}}</span>
                                <!-- <pre>{{user}}</pre> -->
                            <!-- </div> -->
                        </div>
                    </div>
                    <div class='u-bg-secondary p-3 u-border-radius size_fix' v-else-if="user && user.status.id == 4 && user_load == false">
                        <div class='mb-3 f15'>
                            <span>Верификация не пройдена</span>
                        </div>
                        <div class='lk__list'>
                            <p>{{user.verification_error}}</p>
                            <button class='button button--full-width ver_btn' @click="showModal">Верификация</button>
                        </div>
                    </div>
                </div>
                <!-- <div class='col-12'>
                    <hr class='hr'>
                </div> -->
            </div>
            <div class="col-12">
                <a href="https://t.me/valutix" target="_blank">
                    <picture>
                        <source media="(min-width:500px)" srcset="/static/img/go_telegram/go_telegram.jpg">
                        <img src="/static/img/go_telegram/mobile_go_telegram.jpg" style="border-radius:7px; width: 100%">
                    </picture>
                </a>
            </div>
            <cards-component
                @close="closeCard"
                :cards="cards"
                :cards_count="cards_count"
                :flag="newCardFlag"
                :bank_list="bank_list"
            ></cards-component>
            <wallets-component
                @delete="delete_wallet"
                @close="closeWallet"
                :crypto="cryptos"
                :wallets="wallets"
                :wallets_count="wallets_count"
                :flag="newWalletFlag"
            ></wallets-component>
        </div> 
        <verification v-show='isModalVisible' @close='closeModal' @verify='verify' :user="user"></verification>
        <new-check v-show='isNewCheckVisible' @close='closeNewCheck' @new_check="newCheck" :course="courses" :check="check" :cryptos="cryptos" :currencies="currencies"></new-check>
    </div>
</template>
<script>
    import CryptoElementComponent from "./CryptoElementComponent.vue";
    import Verification from "./Verification.vue";
    import ConversionComponent from "./ConversionComponent.vue";
    import AppSpinner from "../General/AppSpinner.vue";
    import Vue from "vue";
    import NewCheck from "./NewCheck.vue";
    import Pagination2 from 'vue-pagination-2';
    import moment from "moment";
    Vue.component('cards-component', require('../Cards/CardsComponent.vue').default);
    Vue.component('wallets-component', require('../Wallets/WalletsComponent.vue').default);

    export default {
        props: [
            'courses',
            'user',
            'cards',
            'wallets',
            'cards_count',
            'wallets_count',
            'user_load',
            'cryptos',
            'balances',
            'checks',
            'page_one',
            'page_lot',
            'for_buy',
            'for_deposit',
            'bank_list',
            'cryptos',
            'currencies'
        ],
        components: {
            NewCheck,
            CryptoElementComponent,
            ConversionComponent,
            Verification,
            AppSpinner,
            Pagination2,
        },
        data: () => ({
                isModalVisible: false,
                isNewCheckVisible: false,
                usdtAmount:0,
                btcAmount:0,
                loadTether: false,
                loadBitcoin: false,
                loadEthereum: false,
                loadToncoin: false,
                loadBinancecoin: false,
                loadLitecoin: false,
                checkTypeShow: 'Единоразовый',
                newCardFlag: false,
                newWalletFlag: false,
                check: '',
                page: 1,  // Начальная страница
                perPage: window.innerWidth > 992 ? 10 : 3,
                moment: moment,
        }),
        watch: {
            checkTypeShow(newVal, oldVal) {
              if (newVal !== oldVal) {
                this.page = 1; // Сброс текущей страницы
                this.clickCallback(this.page); // Обновление списка элементов
              }
            },
            // checksList: {
            //     handler() {
            //         if(this.check) {
            //             if(this.check.type == 'Единоразовый') {
            //                 let index = this.checks['one'].findIndex(h => h.id == this.check.id);
            //                 if(index == -1) {
            //                     return;
            //                 }
            //                 this.checks['one'].splice(index, 1, this.check);
            //             } else {
            //                 let index = this.checks['lot'].findIndex(h => h.id == this.check.id);
            //                 if(index == -1) {
            //                     return;
            //                 }
            //                 this.checks['lot'].splice(index, 1, this.check);
            //             }
            //         }
            //     }
            // },
        },
        computed: {
            totalOneChecksCount() {
                    return Object.values(this.checks['one']) ? Object.values(this.checks['one']).reverse().length : 0;
                },
            totalLotChecksCount() {
                    return Object.values(this.checks['lot']) ? Object.values(this.checks['lot']).reverse().length : 0;
                },
            totalChecksCount() {
                    return this.checkTypeShow == 'Единоразовый' ? this.totalOneChecksCount : this.totalLotChecksCount;
                },
            totalPages() {
                    return Math.ceil(this.totalChecksCount / this.perPage);
            },     
            checksList: function() {
                const checksArray = this.checkTypeShow === 'Единоразовый'
                ? Object.values(this.checks['one'])
                : Object.values(this.checks['lot']);
                const start = (this.page - 1) * this.perPage;
                const end = start + this.perPage;
                let arrayCheks = checksArray.slice(start, end);
                // if(this.check !== ''){
                //     this.check = arrayCheks.find(c => c.id == this.check.id)
                // }
                console.log('this.checkss', this.check);
                if(typeof(this.check) != "undefined" && this.check !== null) {
                    let chekk = arrayCheks.find(c => c.id == this.check.id);
                    if(chekk !== undefined) {
                        this.check = chekk;
                    }
                }
                return arrayCheks;
            },
            // page_count: function () {
            //     if(this.checkTypeShow == 'Единоразовый') {
            //         return this.page_one_count;
            //     }  else {
            //         return this.page_lot_count;
            //     }
            // },
            courses_BTC2USDT_rate() {
                return this.courses['BTC2USDT'] ? this.courses['BTC2USDT'].rate : 0;
            },
        },
        methods: {
            changeCheckTypeShow() {
              if(this.checkTypeShow == 'Единоразовый') {
                  this.checkTypeShow = 'Многоразовый';
              }  else {
                  this.checkTypeShow = 'Единоразовый';
              }
              this.$forceUpdate();
            },
            convert(data){
                this.$emit('convert');
            },
            newCard() {
                this.newCardFlag = true;
            },
            closeCard(){
              this.newCardFlag = false;
            },
            closeWallet(){
                this.newWalletFlag = false;
            },
            newWallet() {
                this.newWalletFlag = true;
            },
            clickCallback(page)  {
                this.page = page
                            },
            buy(data) {
                this.$emit('buy', data);
            },
            newBuy() {
                this.$emit('new_buy');
            },
            newDeposit() {
                this.$emit('new_deposit');
            },
            sale(data) {
                this.$emit('sale', data);
            },
            concl(data) {
                this.$emit('concl', data);
            },
            deposit() {
                this.$emit('deposit');
            },
            cash() {
                this.$emit('cash');
            },
            newCheck(data){
                this.check = data;
                this.$emit('check');
            },
            verify(){
                this.$emit('verify');
            },
            showModal() {
                if(this.$metrika !== undefined) {
                    this.$metrika.reachGoal('pop_up_verification');
                }
                this.isModalVisible = true;
                document.body.style.overflow = 'hidden';
            },
            closeModal() {
                this.isModalVisible = false;
                document.body.style.overflow = 'auto';
            },
            showCheck(id) {
                
                this.check = this.checksList.find(check => check.id == id)

                if(this.$metrika !== undefined) {
                    this.$metrika.reachGoal('invoice_pop_up');
                }
                this.isNewCheckVisible = true;
                var modalElement = document.getElementById('newCheckModal');
                if (modalElement) {
                    var myModal = new bootstrap.Modal(modalElement);
                    myModal.show();
                }
            },
            showNewCheck() {
                this.isNewCheckVisible = true;
                this.check = '';
            },
            closeNewCheck() {
                this.isNewCheckVisible = false;
                this.check = '';
            },
            delete_wallet(data) {
                this.$emit('del_wallet', data)
            },
        }
    }
</script>

<style scoped>
    .lk__list p {
        font-size: 12px;
        font-family: Montserrat;
        Line-height: 17px;
    }
    .lk__list span {
        font-size: 15px;
    }

    .f14, .f14 span {
        font-size: 14px;
    }

    .f15, .f15 span {
        font-size: 15px;
    }
    .f17 .f17 span {
        font-size: 17px;
    }
    .ver_btn {
        height: 40px;
        font-size: 16px;
    }
    .user_block span{
        display: block;
    }

    .no_min_height {
        min-height: unset !important;
    }
</style>
