<template>
    <div style="height: 500px;">
        <h3 style="padding-left:40px;">Це адмінка</h3>
        <div style="display:flex; flex-direction:column; padding-left:40px; width:100%;">
            <label for="buyCourse">
                Курс покупки</br>
                <span>Текущий курс: {{courses['USDT2rub'].rate[0]}} P</span></br>
                <input id="buyCourse" v-model="buy_course">
            </label>
            <button @click="changeCourse('buy')" style="width:200px;" class="button button--full-width mb-3">Подтвердить</button>
        </div>
        <div style="display:flex; flex-direction:column; padding-left:40px; width:100%;">
            <label for="saleCourse">
                Курс продажи</br>
                <span>Текущий курс: {{courses['USDT2rub'].rate[1]}} P</span></br>
                <input id="saleCourse" v-model="sale_course">
            </label>
            <button @click="changeCourse('sale')" style="width:200px;" class="button button--full-width mb-3">Подтвердить</button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['courses'],
    data () {
        return {
            buy_course: 0,
            sale_course: 0,
        }
    },
    methods: {
        changeCourse(type) {
            let data = {buy_course: this.buy_course, sale_course: this.sale_course}
            if(type == 'buy') {
                axios.post('/courses/buy_course', data).then(response => {
                    alert(response.data.answer);
                    this.$emit('upd_course');
                });
            } else {
                axios.post('/courses/sale_course', data).then(response => {
                    alert(response.data.answer);
                    this.$emit('upd_course');
                });
            }
        }
    }
}
</script>
