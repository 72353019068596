<template>
    <transition name='modal-fade'>
        <div class='modal fade new_custom_modal' style='display: block; opacity: 1; background: rgba(29, 28, 43, 0.9);' id='addWallet' aria-hidden='true' tabindex='-1' >
            <div class='modal-dialog modal-dialog-centered'>
                <div class='modal-content w-500'>
                    <div class='modal-header'>
                        <h4 class='modal-title'>Мои кошельки</h4>
                        <button class='btn-close btn-close-white shadow-none' type='button' data-bs-dismiss='modal' aria-label='Close' @click='close'></button>
                    </div>
                    <div class="modal-body">
                        <div class='cardBlock'>
                            <div class='cardList'>
                                <wallet @wallet_delete='delete_Wallet(index)' :key='index' v-for='(data,index) in wallets' :data='data' :crypto="crypto" :networks="networks"></wallet>
                            </div>
                        </div>
                    </div>
                    <button class='lk__cart-right' style='width: 100%' @click='showAddWallet'>
                        <div class='lk__cart-add'>+</div>
                        <span id='addWalletButton' class='u-font-size-16'>Добавить кошелек</span>
                    </button>
                </div>
            </div>
            <add-wallet v-show='forModal.isAddWalletVisible' @add='add_Wallet' @close='closeAddWallet' :title="title" :networks="networks" :cryptos="crypto"/>
        </div>
    </transition>
</template>

<script>
import Wallet from './Wallet.vue';
import AddWallet from './AddWallet.vue';
import WalletsComponent from "./WalletsComponent.vue";
export default {
    props: ['wallets', 'networks', 'crypto'],
    components: {
        Wallet,
        AddWallet,
    },
    updated() {
        //this.getList()
    },
    data() {
        return {
            forWallets: {
                string: '123',
                new_Wallet: {
                    title: '',
                    number: '',
                },
            },
            forModal: {
                isAddWalletVisible: false
            },
            title: 'WalletList',
        }
    },
    methods: {
        add_Wallet(data) {
            this.$emit('add',data);
        },
        delete_Wallet(index) {
            //let data = {id: id}
            this.$emit('delete', index);
        },
        showAddWallet() {
            this.forModal.isAddWalletVisible = true;
        },
        closeAddWallet() {
            this.forModal.isAddWalletVisible = false;
        },
        close(event) {
            this.$emit('close');
        },
        listenChange(data) {
            //console.log(data);
            this.forWallets.Wallets = data;
            this.forWallets.WalletsCount = this.forWallets.Wallets.length;
        }
    }
}
</script>


