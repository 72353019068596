var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-5",attrs:{"id":"operation-history-id"}},[_c('div',{staticClass:"o-text-primary u-font-weight-600 mb-2"},[_vm._v("История операций")]),_c('div',{staticClass:"history"},[(_vm.history_load == true)?_c('app-spinner',{attrs:{"load_item":_vm.history_load,"load_page":false}}):_vm._e(),_vm._l((_vm.paginatedElements),function(data,index){return (_vm.history_load !== true)?_c('history-item',{key:index,attrs:{"data":data,"crypto":_vm.crypto,"currencies":_vm.currencies}}):_vm._e()})],2),_c('div',{staticClass:"history_pagen-block"},[(_vm.totalChecksCount > 10)?_c('pagination2',{attrs:{"records":_vm.totalChecksCount,"per-page":_vm.perPage,"options":{
                     chunk: 4,
                     edgeNavigation: true,
                     texts: {
                        first: '1',
                        last: _vm.totalPages.toString(),
                     }
                    }},on:{"paginate":_vm.clickCallback},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }