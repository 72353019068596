<template>
    <transition name='modal-fade'>
    <div class='modal fade new_custom_modal' style='display: block; opacity: 1; background: rgba(29, 28, 43, 0.9);' aria-hidden='true' tabindex='-1'>
        <div class='modal-dialog modal-lg modal-dialog-centered'>
            <div class='modal-content'>
                <div class='modal-header flex-wrap'>
                    <div class="d-flex justify-content-between align-items-center w-100">
                        <h5 class='modal-title w-100'>Депозит {{crypto.short_title}}</h5>
                        <button class='btn-close btn-close-white shadow-none' type='button' data-bs-dismiss='modal' aria-label='Close' @click="close"></button>
                    </div>
                    <div class='custom-modal__tab o-tab my-2'>Комиссия — 0%</div>
                </div>
                <div class='modal-body' v-if="step == 1">
                    <p style="font-size: 14px;">Выберите сеть кошелька и введите сумму депозита для простоты идентификации.<br>
                        Депозит будет зачислен после необходимого количества подтверждений сети.<br>
                        Не используйте эти реквизиты для следующих переводов!</p>
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <p style="font-size: 14px;">Сеть кошелька</p>
                        <select style="width: 60%" class='o-input' v-model="selectedWallet" name='card_bank'>
                            <option disabled="true" selected="true">Выберите сеть</option>
                            <option v-for='wal in depositWallets' :value="wal">
                                {{wal.title}}
                            </option>
                        </select>
                    </div>
                    <div class="error-block-text" v-show="errorText != ''"><p>{{errorText}}</p></div>
                    <div class="mt-2" style="display: flex; justify-content: space-between; align-items: center;">
                        <p style="font-size: 14px;">Сумма депозита</p>
                        <input style="width: 60%" class='o-input' type="number" name='number_card' v-model='amount' placeholder='Сумма депозита' />
                    </div>
                    <button class='button button--full-width mb-3 mt-3' @click="sendDeposit">Получить адрес кошелька</button>
                </div>
                <div class='modal-body' v-if="step == 2 || (step == 3 && createdDeposit.status == 'Подтверждена')">
                    <div class="mb-2 text-center">Сумма депозита: {{amount}} {{crypto.short_title}}</div>
                    <div class='custom-modal__text o-text-primary mb-4'>{{selectedWallet.network}}</div>
                    <div class='custom-modal__text mb-4 d-flex' style="display: flex !important; justify-content: center; align-items: center;">
                        <qr-code :text="selectedWallet.wallet"></qr-code>
                    </div>
                    <div v-if="step == 3 && createdDeposit.status == 'Подтверждена'" class='custom-modal__text mb-2' style="text-align: left">
                        <h3 class="mb-2">Ваш депозит подтвержден</h3>
                        <p>Пока депозит обратывается вы можете соврешать другие операции</p>
                    </div>
                    <div v-else>
                        <div class='custom-modal__text mb-4' @click="copyWallet">
                            <span class="for-cut">{{selectedWallet.wallet}}</span> 
                            <img style="cursor: pointer" class='ms-2 js-copy' src='/img/ico/ico-copy.svg' />
                        </div>
                        <input @change="sentProof" id='js-file-1' style='cursor: pointer; display: none;' @input="addChek" slot=''  class='image-file js-file custom-file__input' type='file' name='uploadFile'/>
                        <label for='js-file-1' class='button button--full-width mb-3 display-flex flex-direction-row justify-content-center'>
                        <img src="/img/ico/ico-upload.svg" alt="upload" class="me-1">
                        <span>Подтвердить депозит</span></label>
                        <div @click="changeNetwork" class='custom-file__label' style='cursor: pointer; min-width:300px; color: #3EA075;display: flex; justify-content: center;
                        cursor: pointer; margin-bottom: 15px;' >
                        <span class='custom-file__text' style='color: #3EA075; font-size: 16px; text-decoration: underline;'>
                                <span>Получить кошелек в другой сети</span></span></div>
                        <div class='custom-modal__text mb-2'>Обратите внимание, данный кошелек не принадлежит Вам. При каждом депозите необходимо получать новый адрес кошелька.</div>
                        <div class='custom-modal__text d-flex align-items-center justify-content-center'>Время на совершение депозита: <span class="d-flex align-items-center gap-1 ms-1"><img src='/img/ico/ico-time.svg' /><span class="time">{{ formattedTime }}</span></span></div>
                    </div>
                </div>

                <div class='modal-body' v-if="step == 3 && createdDeposit.status == 'Успех'">
                    <div style='text-align: left; margin-bottom: 20px;'>
                        <span class='me-3' style='font-size: 16px'>Депозит на {{this.amount}} {{crypto.short_title}} совершен успешно</span>
                    </div>
                    <button class='button button--full-width mb-3' @click='lastStep()'>Новый депозит</button>
                </div>
                <div class='modal-body' v-if="step == 3 && createdDeposit.status == 'Отмена'">
                    <div style='text-align: left; margin-bottom: 20px;'>
                        <span class='me-3' style='font-size: 16px'>Депозит на {{this.amount}} {{crypto.short_title}} завершился неудачей</span>
                    </div>
                    <button class='button button--full-width mb-3' @click='lastStep()'>Новый депозит</button>
                </div>
            </div>
        </div>
    </div>
    </transition>
</template>

<script>
export default {
    props: ['balance',  'cards', 'courses', 'crypto', 'for_deposit'],
    data() {
        return {
            step: 1,
            title: 'deposit' + crypto.short_title,
            amount:  0,
            selectedWallet:[],
            wallet:'',
            wallets:[],
            status:'',
            proof: '',
            errorText: '',
            deposit_id:0,
            remainingTime: 30 * 60, // 30 минут в секундах
            intervalId: null,
            networks: [],
            createdDeposit: [],
            maxSize: 5242880,
        }
    },
    computed: {
        formattedTime() {
          const minutes = Math.floor(this.remainingTime / 60);
          const seconds = this.remainingTime % 60;
          return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        },
        newNetworks() {
            return this.networks.filter(network => network.id );
        },
        depositWallets() {
            let data = [];
            this.wallets.forEach((value, index) => {
                    let temp = this.networks.find(network => network.id == value.network_id && network.crypto_id == this.crypto.id);
                    if(temp){
                        let netWallet = {
                            ...temp,
                            ...value
                        }
                        if(data.find(network => network.network_id == netWallet.network_id) == null){
                            data.push(netWallet);
                        }
                    }
            });
            return data;
        }

    },

    created() {
        eventBus.$on('depositUpdate', (pusherInfo) => {
            if(this.createdDeposit.id == pusherInfo.id)
            {
                this.step = 3;
                this.createdDeposit = pusherInfo;
            }
        });
        this.getWallets();
        axios.get(this.$api_address +'/networks').then(response =>
        {
            this.networks = response.data.data;

        });
        },
    
    methods: {
        getWallets(){
            this.startTimer();
            axios.get(this.$api_address + '/deposit_wallets').then(response =>
            {
                this.wallets = response.data.data;
                this.selectedWallet = (this.wallets[this.crypto] && this.wallets[this.crypto][0]) ? this.wallets[this.crypto][0] : 0;

            });
        },

        addChek(event) {
            if (
                (event.target.files[0].type == "image/png" 
                || event.target.files[0].type == "image/jpeg"
                /*|| event.target.files[0].type == "application/pdf"*/) 
                && event.target.files[0].size < this.maxSize
            ) {
                this.proof = event.target.files[0];
            } else {
                alert("Неверный формат (нужен jpeg или png) или размер файла превышает 5Мб");
                this.proof = "";
                event.target.value = '';
            }
        },

        sendDeposit(){
            let data = {
                amount: this.amount,
                cryptocurrency_id: this.crypto.id,
                wallet_id: this.selectedWallet.id,
                user_id: this.$user_ID.value,
                token: sessionStorage.getItem('user_token'),
            }
            axios
            .post(this.$api_address + '/deposits', data)
            .then(response =>
            {
                this.createdDeposit = response.data.data;
                this.step = 2;
                this.$emit('deposit');

            })
            .catch(function (error){
                console.log(error.response);
            });
        },
        startTimer() {
            if (!this.intervalId) {
                this.intervalId = setInterval(() => {
                if (this.remainingTime > 0) {
                this.remainingTime--;
                } else {
                    clearInterval(this.intervalId);
                    this.intervalId = null;
                    this.step = 3;
                    this.status = 'Отмена';
              }
            }, 1000);
          }
        },
        close(event) {
            this.$emit('close');
        },
        sale(data) {
            this.$emit('concl', data);
        },
        copyWallet() {
            navigator.clipboard.writeText(this.selectedWallet.wallet);
        },
        getWallet() {
            this.startTimer();
            if(this.amount == 0) {
                this.errorText = 'Кол-во криптовалюты должно быть больше 0';
                return;
            }
            let data = {crypto: this.crypto, wallet: this.selectedWallet, amount: this.amount}
            axios.post('/deposit', data).then(response =>
            {
                if(response.data.error) {
                    this.errorText = response.data.error;
                } else {
                    this.deposit_id = response.data;
                    this.step = 2;
                    this.$emit('deposit');
                }
            });

        },
        sentProof() {
            let formData = new FormData();
            formData.append("proof", this.proof);
            formData.append("token", sessionStorage.getItem('user_token'));
            formData.append("_method", 'PATCH');
            axios.post(this.$api_address + '/deposits/' + this.createdDeposit.id + '/proof', formData,  {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }).then(response =>
            {
                    this.createdDeposit = response.data.data;
                    this.step = 3;
                    this.$emit('deposit');
            });
        },
        changeNetwork() {
            axios.delete(this.$api_address + '/deposits/' + this.createdDeposit.id, {
            params: {
                token: sessionStorage.getItem('user_token'),
            },
            }).then(response => {
                this.$emit('deposit');
                this.step = 1;
                getWallet();
            }).catch(function (error) {
                this.errorText.mail = 'Что-то пошло не так, попробуйте снова';
            });
            this.remainingTime = 30 * 60;

        },
        lastStep() {
            this.step = 1;
            this.amount =  0;
            this.proof = '';
            this.errorText = '';
            this.deposit_id = 0;
            this.status = '';
            this.$emit('new_deposit');
            this.remainingTime = 30 * 60;
        },
    },
}
</script>

<style scoped>
.time {
    width: 40px;
}
</style>
