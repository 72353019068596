<template>
    <transition name='modal-fade'>
        <div class="modal fade custom-modal" style='opacity: 1; z-index: 3; display: block; background: rgba(29, 28, 43, 0.9);' aria-hidden="true" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content w-500">
                    <div class="modal-header">
                        <h5 class="modal-title">Запрос на сделку с наличными</h5>
                        <button class="btn-close btn-close-white" type="button" data-bs-dismiss="modal" @click="close" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="custom-modal__text mb-3 o-text-primary text-start">
                            Тип сделки:
                            <div class="d-flex my-2">
                                <label class='control control-radio'>
                                     Купить
                                    <input :id="'buy'" type='radio' v-model='type' :value="'Купить'" />
                                    <div class='control_indicator'></div>
                                </label>
                                <label class='control control-radio' style='margin-left: 30px;'>
                                    Продать
                                    <input :id="'sell'" v-model='type' :value="'Продать'" type="radio" />
                                    <div class='control_indicator'></div>
                                </label>
                            </div>
                        </div>
                        <input class="o-input mb-2" type="text" v-model='telegram' placeholder="Telegram (@nickname)" />
                        <input class="o-input mb-2" type="number" v-model='amount' placeholder="Сумма" />
                        <input class="o-input mb-2" type="text" v-model='city' placeholder="Город встречи" />
                        <input class="o-input mb-2" type="text" v-model='description' placeholder="Описание кошелька" />
                        <input class="o-input mb-2" type="date" v-model='date' /><button class="button button--full-width mb-3" @click="close">Оставить заявку</button>
                        <div class="custom-modal__text">Нажимая на кнопку “Зарегистрироваться”, вы соглашаетесь с <a class="custom-modal__link" href="/terms_of_use">правилами сервиса</a> и <a class="custom-modal__link" href="/privacy-police">политикой конфедициальности</a></div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    data() {
        return {
            type:'Купить',
            telegram: '',
            amount: null,
            city: '',
            description: '',
            date: '',
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        checkCode() {
            let data = {
                vercode: this.verCode,
            }
            console.log(data);
            axios.post('/vercode', data, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            }).then(response => {
                console.log(response.data);
                if(response.data.success == true) {
                    this.$emit('login_user');
                    this.step = 1;
                    this.close();
                } else {
                    if(response.data.error){
                        this.errorText.verCode = response.data.error
                    }
                }
            })
        },
        register() {
            let data = {
                nickname: this.nickname,
                email: this.email,
                referal_code: this.referal_code,
                password: this.password,
                password_repeat: this.password_repeat
            }
            console.log(data);
            axios.post('/register', data, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            })
            .then(response => {
                console.log(response.data);
                if(response.data.success == true) {
                    this.step = 2;
                } else {
                    if(response.data.errors.email.length){
                        this.errorText.email = response.data.errors.email.pop();
                        console.log(this.errorText.email);
                    }
                    if(response.data.errors.nickname){
                        this.errorText.nickname = response.data.errors.nickname.pop();
                    }
                    if(response.data.errors.referal_code){
                        this.errorText.referal_code = response.data.errors.referal_code.pop();
                    }
                    if(response.data.errors.password){
                        this.errorText.password = response.data.errors.password.pop();
                    }
                    if(response.data.errors.password_repeat){
                        this.errorText.password_repeat = response.data.errors.password_repeat.pop();
                    }
                }
            })
            .catch(function (error){
                console.log(error.response);
            });
            //this.close();
        },
    }
}
</script>


