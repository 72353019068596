<template>
  <fragment>
  <div class="wrapper">
      <section class="first_contacts_section">
        <div class="container">
            <h1 class="h1">Техническая <span class="text-green">поддержка</span>,
                сотрудничество и PR</h1>
             <div class="row info">
                 <div class="col-12 col-md-6">
                     <div class="card border_green bg-transparent p-3 text-white gap-1">
                        <div class="d-flex align-items-center">
                            <div class="contacts_img_wrap"><img src="/static/img/contacts/tabler_mail-filled.svg"></div>
                            <span>
                                <a class="text-darkgreen fs-5" href="mailto:hello@valutix.io">hello@valutix.io</a><br class="d-block d-sm-none">
                                (Вопросы и предложения)
                            </span>
                        </div>
                        <div class="d-flex align-items-center">
                            <div class="contacts_img_wrap"><img src="/static/img/contacts/tabler_mail-filled.svg"></div>
                            <span>
                                <a class="text-darkgreen fs-5" href="mailto:partner@valutix.io">partner@valutix.io</a><br class="d-block d-sm-none">
                                (Для партнерских запросов)
                            </span>
                        </div>
                        <div class="d-flex align-items-center">
                            <div class="contacts_img_wrap"><img src="/static/img/contacts/logos_telegram.svg"></div>
                            <a target="_blank" class="text-darkgreen fs-5" href="https://t.me/valutix_bot">Телеграм бот</a>
                        </div>
                        <div class="d-flex align-items-center">
                            <div class="contacts_img_wrap"><img src="/static/img/contacts/icon-park-solid_time.svg"></div>
                            <span class="fs-5">Время работы: Круглосуточно 24/7</span>
                        </div>
                     </div>
                 </div>
             </div>
             <div class="d-none d-sm-flex gap-4">
                 <button class="myButton text-nowrap" data-bs-toggle="modal" data-bs-target="#supportModal">Отправить письмо</button>
                 <a target="_blank" class="myButton" href="https://jivo.chat/atoktboaJ3">Написать в чат</a>
             </div>
             <!-- <div class="d-flex d-sm-none flex-column gap-3">
                <a href="#" class="myButton text-nowrap mx-auto">Купить за наличные</a>
                <a href="#" class="myButton text-nowrap mx-auto">Продать за наличные</a>
            </div> -->
        </div>
      </section>
      <section class="second_contacts_section">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-8 col-lg-5 ms-lg-auto mb-4 mb-lg-0">
                    <a target="_blank" href="https://t.me/valutix" class="custom_card center border_green rounded">
                        <div class="d-flex gap-3 text-white">
                            <img width="47" height="47" src="/static/img/contacts/logos_telegram.svg">
                            <span>Для того чтобы быть в курсе последних новостей, рекомендуем подписаться на наш Telegram канал</span>
                        </div>
                    </a>
                </div>
                <div class="col-12 d-block d-sm-none mb-4">
                    <a target="_blank" href="https://t.me/valutix" class="myButton mx-auto">Перейти</a>
                </div>
                <div class="col-12 col-md-8 col-lg-5 me-lg-auto">
                    <a target="_blank" href="https://www.trustpilot.com/review/valutix.io" class="custom_card center border_green rounded text-white">
                        <div class="d-flex flex-column justify-content-center">
                            <span class="mb-2 text-center fs-14">Больше отзывов на сайте</span>
                            <img src="/static/img/contacts/trustpilot.png" alt="trustpilot">
                        </div>
                    </a>
                </div>
                <div class="col-12 d-block d-sm-none mt-4">
                    <a target="_blank" href="https://www.trustpilot.com/review/valutix.io" class="myButton mx-auto">Оставить отзыв</a>
                </div>
            </div>
        </div>
      </section>
  </div>
  </fragment>
</template>

<script>
export default {
    name: 'Contacts',
}
</script>

<style scoped>

    h1 {
        margin-bottom: 30px;
    }

    .h1 {
        font-size: 70.5px;
        font-weight: 700;
        line-height: 90.49%;
        text-transform: none;
    }
    
    h2, .h2 {
        font-size: 30.5px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    
    h6 {
        font-size: 20px;
        font-weight: 600;
    }
    .first_contacts_section {
        padding: 116px 0 55px;
    }
    .text-darkgreen {
        color: #4AA485;
    }
    .contacts_img_wrap {
        width: 30px;
        height: 30px;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .info {
        margin-bottom: 54px;
    }

    .second_contacts_section {
        margin: 81px 0;
    }

    .custom_card {
        padding: 45px;
        height: 154px;
    }

    .fs-14 {
        font-size: 14px;
    }

    @media (max-width: 768px) {
        .h1 {
            font-size: 35px;
        }
    }

    @media (max-width: 500px) {
        .card {
            font-size: 14px;
        }

        .second_contacts_section {
            margin: unset;
            padding: 50px 0 100px;
        }

        .info {
            margin-bottom: 0;
        }

        .first_contacts_section {
            padding-bottom: 0;
        }

    }
</style>