<template>
    <!-- <transition name='modal-fade'> -->
        <div class='modal fade new_custom_modal p-0' id='newCheckModal' aria-hidden='true' tabindex='-1'>
            <div v-if="showHistory == false" class='modal-dialog modal-lg modal-dialog-centered'>
                <!-- Шаг 1 -->
                <div v-if="step == 1" class='modal-content no-transition'>
                    <div class='modal-header'>
                        <div class="row row-cols-1">
                            <div class="d-flex align-items-center justify-content-between">
                                <h4 class='modal-title'>Новый счет</h4>
                                <button class="btn-close btn-close-white shadow-none" type="button" @click="close" data-bs-dismiss='modal' aria-label='Close'></button>
                            </div>
                            <p class="underheader_text">Укажите все параметры выставляемого счета и сформируйте его</p>
                        </div>

                    </div>
                    <div class="modal-body">
                        <div class="row mb-2">
                            <div class="col-12 col-sm-5">
                                <span>
                                    Получаемая валюта
                                    <img src="/static/img/ico/ico-info.svg" width="20" height="20" @mouseover="showTooltip('currency')" @mouseout="hideTooltip('currency')">
                                </span>    
                            </div>
                            <div  class="col-12 col-sm-7">
                                <div class="tooltip-commission" :class="tooltips.currency == true ? 'd-block' : 'd-none'">
                                    <div class="tooltip-custom">
                                        Выберите валюту, в которой хотите выставить счет. Пользователь увидит сумму именно в выбранной тут валюте. При оплате счета зачисление на Ваш баланс произойдет в валюте USDT по курсу на момент оплаты счета.
                                    </div>
                                </div>
                                <select class='o-input' v-model="selectedCurrency">
                                    <option value="" selected="true">Выберите валюту...</option>
                                    <option v-for="(cur, index) in currency" :key="index" :value="cur.name">{{cur.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-sm-5">
                                <span>Сумма счета</span>
                            </div>
                            <div class="col-12 col-sm-7">
                                <div class="row row-cols-1">
                                    <div class="col">
                                        <input type="number" @focus="clearError('amount')" style="width: 100%; border-radius: 5px; margin-top: 0px; cursor: text " v-model="amount" class="inputAmount">
                                        <div class="error-block-text" v-show="errors.amount != ''"><p>{{errors.amount}}</p></div>
                                    </div>
                                    <div class="col">
                                        <span class="underheader_text">Минимум — {{selectedCurrency != 'USDT' ? parseFloat(10 * currency_course).toFixed(2) + " " + selectedCurrency : 10 + " " + selectedCurrency}}</span>
                                    </div>
                                    <div class="col">
                                        <span class="underheader_text">Максимум — {{selectedCurrency != 'USDT' ? parseFloat(100000 * currency_course).toFixed(2) + " " + selectedCurrency : 100000 + " " + selectedCurrency}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-sm-5">
                                <span>Комментарий к счету</span>
                            </div>
                            <div  class="col-12 col-sm-7">
                                <textarea @focus="clearError('description')" class="inputAmount" v-model="description" style="width:100%; min-height:100px;"></textarea>
                                <div class="error-block-text" v-show="errors.description != ''"><p>{{errors.description}}</p></div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-sm-5">
                                <span>
                                    Комиссию платит
                                    <img src="/static/img/ico/ico-info.svg" width="20" height="20" @mouseover="showTooltip('commission')" @mouseout="hideTooltip('commission')">
                                </span>
                            </div>
                            <div class="col-12 col-sm-7">
                                <div class="tooltip-commission" :class="tooltips.commission == true ? 'd-block' : 'd-none'">
                                    <div class="tooltip-custom">
                                        Выберите кто платит комиссию сервиса. Если выбран плательщик счета, то сумма счета будет увеличена на 2%. Если выбран получатель, то Вы получите зачисление на 2% меньше.
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label class='control control-radio'>
                                            Плательщик
                                            <input :id="'cp1'" type='radio' v-model='commissionPayer' :value="'Плательщик'" />
                                            <div class='control_indicator'></div>
                                        </label>
                                    </div>
                                    <div class="col">
                                        <label class='control control-radio'>
                                            Получатель
                                            <input :id="'cp2'" v-model='commissionPayer' :value="'Получатель'" type="radio" />
                                            <div class='control_indicator'></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-5">
                            <div class="col-12 col-sm-5">
                                <span>Сколько раз можно оплатить счет</span>
                            </div>
                            <div class="col-12 col-sm-7">
                                <div class="row">
                                    <div class="col">
                                        <label class='control control-radio'>
                                            Единоразовый
                                            <input :id="'ct1'" type='radio' v-model='checkType' :value="'Единоразовый'" />
                                            <div class='control_indicator'></div>
                                        </label>
                                    </div>
                                    <div class="col">
                                        <label class='control control-radio'>
                                            Многоразовый
                                            <input :id="'ct2'" v-model='checkType' :value="'Многоразовый'" type="radio" />
                                            <div class='control_indicator'></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row row-cols-1 mb-2 pb-1">
                            <div class="col-12 col-sm-6 text-start offset-sm-3 pb-1">
                                <span>К оплате — {{commissionPayer != 'Получатель' ? parseFloat(parseFloat(amount) + parseFloat(amount) * 0.02).toFixed(2) + " " + selectedCurrency : parseFloat(amount).toFixed(2) + " " + selectedCurrency}}</span>
                            </div>
                            <div class="col-12 col-sm-6 text-start offset-sm-3 pb-1">
                                <span>К зачислению — {{commissionPayer == 'Получатель' ? parseFloat((parseFloat(amount) - parseFloat(amount) * 0.02) / currency_course).toFixed(2) + " USDT" : parseFloat(amount / currency_course).toFixed(2) + " USDT"}}</span>
                            </div>
                            <div class="col-12 col-sm-8 text-center offset-sm-2">
                                <button @click="createCheck" class="button button--full-width ">Сформировать счет</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Шаг 2 -->
                <div v-if="step == 2" class='modal-content no-transition'>
                    <div class='modal-header flex-wrap'>
                            <div class="d-flex align-items-end">
                                <h4 class='modal-title'>Счет №{{checkInfo.user_id}}-{{checkInfo.id}}</h4>
                                <span class="ms-3 pb-1">{{check.status ?? checkInfo.status}}</span>
                            </div>
                            <button class="btn-close btn-close-white shadow-none" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div class="col-12">
                                <p class="underheader_text">{{checkInfo.type}} платеж</p>
                            </div>
                    </div>
                    <div v-if="checkInfo.status == 'Ожидает'|| checkInfo.status == 'В процессе' || checkInfo.status == 'Активен'" class="modal-body">
                        <div class="row mb-2">
                            <div class="col-12 col-sm-3">
                                <span class="fw-lighter">Сумма счета</span>
                            </div>
                            <div  class="col-12 col-sm-9">
                                <span class="fw-lighter">{{ checkInfo.amount + " " + selectedCurrency.short_title }}</span>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-sm-3">
                                <span class="fw-lighter">К зачислению</span>
                            </div>
                            <div  class="col-12 col-sm-9">
                                <span class="fw-lighter">{{commissionPayer == 'Получатель' ? parseFloat((parseFloat(checkInfo.amount) - parseFloat(checkInfo.amount) * 0.02) / currency_course).toFixed(2) + " USDT" : parseFloat(checkInfo.amount / currency_course).toFixed(2) + " USDT"}}</span>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-sm-3">
                                <span class="fw-lighter">Описание</span>
                            </div>
                            <div  class="col-12 col-sm-9">
                                <span class="fw-lighter">{{checkInfo.description}}</span>
                            </div>
                        </div>
                        <div class="row mb-5">
                            <div class="col-12 col-sm-3">
                                <span class="fw-lighter">Ссылка на счет</span>
                            </div>
                            <div class="col-12 col-sm-9">
                                <div class="row row-cols-1">
                                    <div class="col">
                                        <input style="width: 100%; border-radius: 5px; margin-top: 0px; cursor: text " disabled="true" v-model="checkInfo.link" class="inputAmount">
                                    </div>
                                    <div class="col">
                                        <span @click="copyCard" class="copy">
                                            <img src="/img/ico/ico-copy.svg" width="12" height="15">
                                            <span @click="changeText">Скопировать</span>
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row mb-5">
                            <div class="col-12 col-sm-3">
                                <qr-code :render-as="'svg'" :text="checkInfo.link + checkInfo.user_id + '-' + checkInfo.id" :size="150"></qr-code>
                            </div>
                            <div class="col-12 col-sm-9 mt-3 mt-sm-0">
                                <div class="row row-cols-1">
                                    <div  class="col pb-2 cursor-pointer" @click="clickWA">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1522_7746)">
                                        <path d="M0.683431 15.8086C0.682681 18.4972 1.39064 21.1224 2.73682 23.4363L0.554688 31.3423L8.70826 29.2209C10.9634 30.4391 13.4902 31.0775 16.0579 31.0776H16.0646C24.5411 31.0776 31.4411 24.2332 31.4447 15.8206C31.4463 11.7441 29.8479 7.91076 26.9438 5.02679C24.0402 2.14307 20.1785 0.554107 16.064 0.552246C7.58657 0.552246 0.687056 7.39628 0.683556 15.8086" fill="url(#paint0_linear_1522_7746)"/>
                                        <path d="M0.133743 15.8035C0.132868 18.5889 0.866205 21.308 2.26038 23.7048L0 31.8942L8.44593 29.6967C10.7731 30.9558 13.3932 31.6196 16.0593 31.6206H16.0662C24.8467 31.6206 31.9946 24.53 31.9983 15.8162C31.9998 11.5932 30.3439 7.62208 27.3361 4.63479C24.3279 1.64788 20.3281 0.00173643 16.0662 0C7.28412 0 0.137243 7.08961 0.133743 15.8035ZM5.16348 23.292L4.84812 22.7953C3.52244 20.7036 2.82273 18.2865 2.82373 15.8045C2.8266 8.56174 8.76679 2.66915 16.0712 2.66915C19.6085 2.67064 22.9328 4.03895 25.4332 6.52155C27.9334 9.0044 29.3092 12.3049 29.3083 15.8152C29.3051 23.058 23.3648 28.9513 16.0662 28.9513H16.0609C13.6844 28.9501 11.3537 28.3168 9.32101 27.12L8.83729 26.8353L3.8253 28.1393L5.16348 23.2919V23.292Z" fill="url(#paint1_linear_1522_7746)"/>
                                        <path d="M12.0862 9.19662C11.788 8.53888 11.4741 8.52561 11.1905 8.51407C10.9583 8.50415 10.6928 8.5049 10.4275 8.5049C10.162 8.5049 9.73069 8.604 9.36608 8.99904C9.0011 9.39445 7.97266 10.35 7.97266 12.2934C7.97266 14.237 9.39921 16.1152 9.59807 16.379C9.79719 16.6423 12.3521 20.7582 16.3983 22.3415C19.7612 23.6574 20.4455 23.3957 21.1753 23.3297C21.9053 23.264 23.5307 22.3744 23.8623 21.452C24.1942 20.5297 24.1942 19.7391 24.0947 19.5739C23.9952 19.4093 23.7297 19.3105 23.3316 19.113C22.9334 18.9154 20.9762 17.9598 20.6114 17.8279C20.2464 17.6962 19.981 17.6305 19.7155 18.026C19.4501 18.4209 18.6877 19.3105 18.4554 19.5739C18.2232 19.838 17.9909 19.8708 17.5929 19.6733C17.1945 19.4751 15.9125 19.0584 14.3914 17.7128C13.208 16.6658 12.409 15.3727 12.1768 14.9772C11.9446 14.5823 12.1519 14.3682 12.3516 14.1714C12.5304 13.9944 12.7498 13.7101 12.949 13.4795C13.1475 13.2488 13.2138 13.0842 13.3465 12.8208C13.4794 12.5571 13.4129 12.3264 13.3135 12.1288C13.2138 11.9313 12.4402 9.97764 12.0862 9.19662Z" fill="white"/>
                                        </g>
                                        <defs>
                                        <linearGradient id="paint0_linear_1522_7746" x1="1545.06" y1="3079.56" x2="1545.06" y2="0.552246" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#1FAF38"/>
                                        <stop offset="1" stop-color="#60D669"/>
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_1522_7746" x1="1599.92" y1="3189.42" x2="1599.92" y2="0" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#F9F9F9"/>
                                        <stop offset="1" stop-color="white"/>
                                        </linearGradient>
                                        <clipPath id="clip0_1522_7746">
                                        <rect width="32" height="32" fill="white"/>
                                        </clipPath>
                                        </defs>
                                        </svg>
                                        <span class="ms-2 fw-lighter">Отправить в WhatsApp</span>
                                    </div>
                                    <div @click="clickTg" class="col pb-2 cursor-pointer">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1522_7742)">
                                        <path d="M16 0C11.7575 0 7.685 1.68675 4.6875 4.68625C1.68691 7.68694 0.000831175 11.7565 0 16C0 20.2417 1.6875 24.3142 4.6875 27.3137C7.685 30.3132 11.7575 32 16 32C20.2425 32 24.315 30.3132 27.3125 27.3137C30.3125 24.3142 32 20.2417 32 16C32 11.7583 30.3125 7.68575 27.3125 4.68625C24.315 1.68675 20.2425 0 16 0Z" fill="url(#paint0_linear_1522_7742)"/>
                                        <path d="M7.24116 15.8311C11.9062 13.7991 15.0162 12.4593 16.5712 11.8121C21.0162 9.96381 21.9387 9.64281 22.5412 9.63193C22.6737 9.62981 22.9687 9.66256 23.1612 9.81818C23.3212 9.94943 23.3662 10.1269 23.3887 10.2516C23.4087 10.3761 23.4362 10.6598 23.4137 10.8813C23.1737 13.4113 22.1312 19.5508 21.6012 22.3846C21.3787 23.5836 20.9362 23.9856 20.5087 24.0248C19.5787 24.1103 18.8737 23.4108 17.9737 22.8211C16.5662 21.8978 15.7712 21.3233 14.4037 20.4226C12.8237 19.3816 13.8487 18.8093 14.7487 17.8743C14.9837 17.6296 19.0787 13.9058 19.1562 13.5681C19.1662 13.5258 19.1762 13.3683 19.0812 13.2853C18.9887 13.2021 18.8512 13.2306 18.7512 13.2531C18.6087 13.2851 16.3612 14.7721 12.0012 17.7138C11.3637 18.1523 10.7862 18.3661 10.2662 18.3548C9.69616 18.3426 8.59616 18.0318 7.77866 17.7663C6.77866 17.4406 5.98116 17.2683 6.05116 16.7151C6.08616 16.4271 6.48366 16.1323 7.24116 15.8311Z" fill="white"/>
                                        </g>
                                        <defs>
                                        <linearGradient id="paint0_linear_1522_7742" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#2AABEE"/>
                                        <stop offset="1" stop-color="#229ED9"/>
                                        </linearGradient>
                                        <clipPath id="clip0_1522_7742">
                                        <rect width="32" height="32" fill="white"/>
                                        </clipPath>
                                        </defs>
                                        </svg>
                                        <span class="ms-2 fw-lighter">Отправить в Telegram</span>
                                    </div>
                                    <div class="col cursor-pointer" style="margin-left: 32px;">
                                        <a :href="checkInfo.qr_file" download class="underheader_text ms-3" style="text-decoration: underline">
                                            Скачать QR code
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="checkInfo.type == 'Многоразовый'" class="row mb-4">
                            <div class="col-12 col-sm-4">
                                <span @click="openHistory" class="button">История оплат</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm mb-2 mb-sm-0">
                                <span @click="cancelCheck" class="py-1 px-3 check_cancel_button">
                                    <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 15.4053L5.94666 10.4524L10.8933 15.4053" stroke="white"/>
                                        <path d="M11 5.45264L6.05333 10.4055L1.10667 5.45264" stroke="white"/>
                                    </svg>
                                    <span class="ms-2 fw-lighter cursor-pointer">Отменить счет</span>
                                </span>
                            </div>
                            <div class="col-12 col-sm text-start text-sm-end eye">
                                <img src="/static/img/ico/ico-eye.svg">
                                <span class="fw-lighter" style="font-size: 14px;">Просмотров: {{checkInfo.watch_count}}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="checkInfo.status == 'Отмена'" class="modal-body">
                        <div class="row mb-2">
                            <div class="col-12 col-sm-3">
                                <span class="fw-lighter">К зачислению</span>
                            </div>
                            <div  class="col-12 col-sm-9">
                                <span class="fw-lighter">{{commissionPayer == 'Получатель' ? parseFloat((parseFloat(amount) - parseFloat(amount) * 0.02) / currency_course).toFixed(2) + " USDT" : parseFloat(amount / currency_course).toFixed(2) + " USDT"}}</span>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-sm-3">
                                <span class="fw-lighter">Описание</span>
                            </div>
                            <div  class="col-12 col-sm-9">
                                <span class="fw-lighter">{{checkInfo.description}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col"></div>
                            <div class="col text-end eye">
                                <img src="/static/img/ico/ico-eye.svg">
                                <span class="fw-lighter" style="font-size: 14px;">Просмотров: {{checkInfo.watch_count}}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="checkInfo.status == 'Успех'" class="modal-body">
                        <div class="row mb-2">
                            <div class="col-12 col-sm-3">
                                <span class="fw-lighter">Зачислено</span>
                            </div>
                            <div  class="col-12 col-sm-9">
                                <span class="fw-lighter">{{parseFloat(checkInfo.amount / currency_course).toFixed(2) + " USDT"}}</span>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-sm-3">
                                <span class="fw-lighter">Описание</span>
                            </div>
                            <div  class="col-12 col-sm-9">
                                <span class="fw-lighter">{{checkInfo.description}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col text-end eye">
                                <img src="/static/img/ico/ico-eye.svg">
                                <span class="fw-lighter" style="font-size: 14px;">Просмотров: {{checkInfo.watch_count}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Показать историю -->
            <div v-if="showHistory == true" class='modal-dialog modal-lg modal-dialog-centered'>
                <div class='modal-content no-transition'>
                    <div class='modal-header'>
                        <h4 class='modal-title'>История оплат</h4>
                        <button class="btn-close btn-close-white shadow-none" type="button" data-bs-dismiss="modal" aria-label="Close" @click="closeHistory"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-3 fw-bolder">Дата</div>
                            <div class="col-6 fw-bolder">Баланс</div>
                            <div class="col-3 fw-bolder">Статус</div>
                        </div>
                        <div class="row mb-2" v-for="h in check.payments">
                            <div class="col-3">{{moment(h.operation.updated_at).format("DD-MM-YYYY H:mm")}}</div>
                            <div class="col-6">{{parseFloat(h.operation.balance_before).toFixed(2)}} <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#3EA075"/>
                            </svg>
                                {{parseFloat(h.operation.balance_after).toFixed(2)}} USDT
                            </div>
                            <div class="col-3">{{h.operation.status}}</div>
                        </div>
                        <paginate  v-if="pageCount > 1"
                                   v-model="page"
                                   :page-count="pageCount"
                                   :click-handler="clickCallback"
                                   :container-class="'history_pagen'"
                                   :hide-prev-next=true
                                   :margin-pages=1
                                   :page-range=3
                                   :disabled-class="'pagen_text-disabled'"
                                   :active-class="'pagen_text-active'"
                                   :prev-text="'&#10140'"
                                   :next-text="'&#10140;'"
                                   :next-link-class="'pagen_text-arrow'"
                                   :prev-link-class="'pagen_text-arrow-revert'"
                                   :page-link-class="'pagen_text'"
                                   :no-li-surround=true
                        >
                        </paginate>
                    </div>
                </div>
            </div>
        </div>
    <!-- </transition> -->
</template>
<script>
    import moment from "moment";
    export default {
        props:['course', 'check', 'cryptos', 'currencies'],
        created() {
            this.url = window.location.href
        },
        watch: {
            check: function(newVal, oldVal) {
                console.log(newVal);
            if(newVal == '') {
                this.step = 1;
                this.amount = 0;
                this.description = '';
                this.selectedCurrency = 'USDT';
            } else {
                this.checkInfo = this.check;
                this.step = 2;
                this.amount = parseFloat(this.check.amount);
                this.selectedCurrency = this.check.currency;
                //   this.getHistory();
                //   this.getHistoryCount();
            }
            }
        },
        computed: {
            currency_course: function () {
                let crs = 1
                switch (this.selectedCurrency.short_title ?? this.selectedCurrency) {
                    case 'USDT':
                        crs = 1;
                        break;
                    case 'UZS':
                        crs = this.course['Покупка'].find(c => c.title == 'USDT2UZS_buy').rate;
                        break;
                    case 'EUR':
                        crs = this.course['Покупка'].find(c => c.title == 'USDT2EUR_buy').rate;
                        break;
                    case 'RUB':
                        crs = this.course['Покупка'].find(c => c.title == 'USDT2RUB_buy').rate;
                        break;
                    case 'KZT':
                        crs = this.course['Покупка'].find(c => c.title == 'USDT2KZT_buy').rate;
                        break;
                }
                return crs;
            },
        },
        data () {
            return {
                moment: moment,
                step:1,
                selectedCurrency:'USDT',
                amount: 0,
                url:'',
                page: 1,
                pageLimit: 10,
                pageCount: 1,
                showHistory: false,
                errors:{
                    description: '',
                    amount: '',
                },
                currency: {
                    1:{
                        name:'USDT',
                    },
                    2:{
                        name:'RUB',
                    },
                    3:{
                        name:'EUR',
                    },
                    4:{
                        name:'KZT',
                    },
                    5:{
                        name:'UZS',
                    },
                },
                commissionPayer: 'Плательщик',
                checkType: 'Единоразовый',
                description: '',
                history: '',
                tooltips: {
                    commission: false,
                    currency: false,
                },
                checkInfo: {
                    id: '10000-3',
                    type: 'Единоразовый',
                    status: "Ожидание",
                    amount: 10000,
                    currency: 'USDT',
                    description: 'sdafsfasdfasf',
                    link: 'asdfsadfsadfasdf',
                    watch_count : 1,
                }
            }
        },
        methods: {
            showTooltip(type){
                if(type == 'commission') {
                    this.tooltips.commission = true;
                } else {
                    this.tooltips.currency = true;
                }
            },
            hideTooltip(type){
                if(type == 'commission') {
                    this.tooltips.commission = false;
                } else {
                    this.tooltips.currency = false;
                }
            },
            clearError(field){
            if(field == 'amount') {
                this.errors.amount = '';
            }
            if(field == 'description') {
                this.errors.description = '';
            }
            },
            createCheck(){
                if(this.description.replaceAll(" ","") == "") {
                    this.errors.description = 'Заполните описание счета';
                }
                if(parseFloat(this.amount).toFixed(
                    this.selectedCurrency === 'USDT' 
                    ? this.cryptos.find(c => c.short_title == this.selectedCurrency).count_after_point 
                    : this.currencies.find(c => c.short_title == this.selectedCurrency).count_after_point) 
                    < 10 * parseFloat(this.currency_course).toFixed(this.selectedCurrency == 'USDT' 
                    ? this.cryptos.find(c => c.short_title == this.selectedCurrency).count_after_point 
                    : this.currencies.find(c => c.short_title == this.selectedCurrency).count_after_point)) {
                    this.errors.amount = 'Значение не должно быть меньше минимального';
                }
                if(this.amount > 100000 * this.currency_course) {
                    this.errors.amount = 'Значение не должно быть больше максимального';
                }
                if(this.errors.amount != '' || this.errors.description != ''){
                    return true;
                }

                /*Создаём новый счёт через post*/
                let data = {
                    amount: this.amount,
                    currency: {},
                    commission_type: this.commissionPayer,
                    type: this.checkType,
                    description: this.description,
                    user_id: this.$user_ID.value,
                    token: sessionStorage.getItem('user_token'),
                }
                let selectedCurrencyName = this.selectedCurrency;
                let matchedCurrency = this.cryptos.find(c => c.short_title === selectedCurrencyName);
                if(!matchedCurrency){
                    matchedCurrency = this.currencies.find(c => c.short_title === selectedCurrencyName);
                }
                if (matchedCurrency) {
                    data.currency = '{"id":' + matchedCurrency.id + ',"short_title":"' + matchedCurrency.short_title + '"}'
                } else {
                console.error('Соответствующая валюта не найдена');
                }

                axios.post(this.$api_address + '/checks', data).then(response => {
                    this.checkInfo = response.data.data;
                    this.step = 2;
                    this.getInvoice(this.checkInfo.id);
                    if(this.$metrika !== undefined) {
                        this.$metrika.reachGoal('invoice_success');
                    }
                    this.$emit('new_check', this.checkInfo);
                })
            },
            // getHistory(){
            //     axios.get(this.$api_address + '/payment_history/list?id=' + this.checkInfo.id + '&page=' + this.page + '&page_limit=' + this.pageLimit).then(response => {
            //         this.history = response.data;
            //     })
            // },
            // getHistoryCount(){
            //     axios.get('/payment_history/count?id=' + this.checkInfo.id).then(response => {
            //         this.pageCount = Math.ceil(response.data / this.pageLimit);
            //     })
            // },
            clickCallback(page)  {
                this.page = page
                // this.getHistory();
            },

            /*Удаляем счёт через delete*/
            cancelCheck(){
                axios.delete(this.$api_address + '/checks/' + this.checkInfo.id, {
                    params: {
                        token: sessionStorage.getItem('user_token'),
                    }
                }).then(response => {
                    this.checkInfo.status = 'Отменен';
                    this.$emit('new_check', this.checkInfo);
                })
            },
            clickTg(){
                window.open('https://telegram.me/share/url?url=' + this.checkInfo.link, '_blank');
            },
            clickWA(){
                window.open('https://wa.me/?text=' +  this.checkInfo.link, '_blank');
            },
            close() {
                this.step = 1;
                this.amount = 0;
                this.description = '';
                this.selectedCurrency = 'USDT';
                this.$emit('close');
            },
            openHistory() {
                this.showHistory = true;
            },
            closeHistory() {
                this.showHistory = false;
            },
            getInvoice(id){
                axios.get(this.$api_address + '/checks/' + id)
                    .then(response => {
                        if(response.data.status == 'Успех' || response.data.status == 'Отмена') {
                            this.checkInfo.status = response.data.status;
                        } else {
                            setTimeout(() => {
                                this.getInvoice(id)
                            }, 5000)
                        }

                    });
            },
            copyCard() {
                navigator.clipboard.writeText(this.checkInfo.link);
            },
            changeText(event) {
                const originalText = event.target.textContent;
                event.target.textContent = 'Скопировано!';
                setTimeout(() => {
                    event.target.textContent = originalText;
                }, 1000);
            }
        }
    }
</script>
<style scoped>
.copy {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
    text-decoration:underline;
    font-size: 14px;
    font-weight:300;
    cursor: pointer;
}
.eye {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
}
</style>
