<template>
    <transition name="modal-fade">
        <div class="modal fade new_custom_modal" id="buyCryptoModal" aria-hidden="true"
            style="display: block; opacity: 1; background: rgba(29, 28, 43, 0.9)" tabindex="-1">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header d-flex align-items-center justify-content-between flex-wrap">
                        <h5 class="modal-title order-1">Купить {{ crypto.short_title }}</h5>

                        <div
                            class="mx-0 mx-md-auto order-3 order-md-2 col-12 col-md-4 px-0 py-3 py-md-0 d-flex align-items-center">
                            <span :class="step > 0 ? 'operation_steps_active' : 'operation_steps'">1</span>
                            <img width="49" height="2" src="/static/img/buy/line.svg" />
                            <span :class="step > 1 ? 'operation_steps_active' : 'operation_steps'">2</span>
                            <img width="49" height="2" src="/static/img/buy/line.svg" />
                            <span :class="step > 2 ? 'operation_steps_active' : 'operation_steps'">3</span>
                            <img width="49" height="2" src="/static/img/buy/line.svg" />
                            <span :class="step > 3 ? 'operation_steps_active' : 'operation_steps'">4</span>
                        </div>

                        <button v-if="step == 2"
                            class="d-flex flex-row align-items-center flex-nowrap text-white order-2 order-md-3"
                            type="button" @click="prev">
                            <img src="/img/ico/white-arrow.svg" alt="стрелка влево" class="me-1"/>
                            <span>Назад</span>
                        </button>
                        <button v-else class="btn-close btn-close-white shadow-none order-2 order-md-3" type="button"
                             @click="close"></button>
                    </div>

                    <!-- Шаг 1 -->
                    <div class="modal-body" v-if="step == 1">
                        <card-for-pop-up
                         :cards="cards"
                         :title="title"
                         @new_card="newCard"
                         :bank_list="bank_list"
                         @put="putCardNumber">
                        </card-for-pop-up>
                        <span v-if="cards.length == 0">Добавьте карты, чтобы купить криптовалюту</span>
                        <button :disabled="this.form.cardNumber ? false : true" class="button button--full-width mb-3" @click="firstStep">
                            Выбрать
                        </button>
                    </div>

                    <!-- Шаг 2 -->
                    <div class="modal-body" v-if="step == 2">
                        <div class="error-block-text" v-if="errorText">
                            <p>{{ errorText }}</p>
                        </div>
                        <div class="row mb-3">
                            <div v-if="errorText == ''" class="col-12 col-sm-4">
                                <span class="me-3 u-font-size-16">Сколько купить?</span>
                            </div>
                            <div v-if="errorText == ''" class="col-12 col-sm-8">
                                <div class="row-cols-1">
                                    <div class="col">
                                        <span class="walletDesc">Текущий курс: <br><b style="font-weight: bold">1 {{
                                            crypto.short_title }} = {{ currencyCourse }}
                                                {{ currency }}</b></span>
                                    </div>
                                    <div class="col">
                                        <input @keyup="upd(currency == 'RUB' ? 'rub' : 'eur')" class="inputAmount w-100" type="number"
                                            v-model="form.amount" name="address_wallet" autocomplete="off"
                                            :placeholder="'Кол-во ' + currency" />
                                    </div>
                                    <div class="col">
                                        <input @keyup="upd('crypto')" class="inputAmount w-100" type="number"
                                            v-model="form.cryptoAmount" name="address_wallet" autocomplete="off"
                                            :placeholder="'Кол-во ' + crypto.short_title" />
                                    </div>
                                    <div class="error-block-text" v-show="errorInput != ''">
                                        <p>{{ errorInput }}</p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="errorText == ''" class="col-12 mt-2">
                                <button :disabled='this.form.amount == 0  || form.amount == null || errorInput != "" ? true : false || !showButton' class='button button--full-width mt-3' @click='secondStep()'>Подтвердить</button>
                            </div>
                            <div v-if="errorText != ''" class="col-12 mt-2">
                                <button class='button button--full-width mb-3' @click='prev()'>Назад</button>
                            </div>
                        </div>
                    </div>

                    <!-- Шаг 3 -->
                    <div class="modal-body" v-else-if="step == 3">
                        <div class="error-block-text" v-if="errorText">
                            <p>{{ errorText }}</p>
                        </div>
                        <div style="text-align: left; color: #3EA075">
                            <span class="walletDesc">Вы покупаете {{ this.form.cryptoAmount }}
                                {{ crypto.short_title }}</span>
                        </div>
                        <div class="d-flex">
                            <div v-if="form.sbp != null">
                                <img width="120" src="/img/banks/logo-sberbank.svg" />
                            </div>
                            <div>
                                <img width="120" :src="bank_list[country].find(bank => bank.id === this.form.bank).logo" />
                            </div>
                        </div>
                        <div style="display: flex">
                            <span class="me-3 u-font-size-30">{{ ourCard }}</span>
                            <button class="copyCard_button" @click="copyCard">
                                <img width="12" height="15" src="/static/img/buy/copy_card.svg"> <span>Скопировать</span>
                            </button>
                        </div>
                        <div style="text-align: left; color: #3EA075">
                            <span class="me-3 u-font-size-18">{{ placeHolder }}</span>
                        </div>
                        <div style="text-align: left">
                            <span class="me-3" style="font-size: 40px">{{ parseFloat(this.form.amount).toFixed(2) }}
                                {{ currency }}</span>
                        </div>
                        <div style="text-align: left; width: 80%; margin-bottom: 15px">
                            <span v-if="form.sbp != null" class="infoText me-3">Выполните перевод по указанным реквизитам
                                методом
                                <b style="font-size:17px; font-weight: 600">СБП В БАНК СБЕРБАНК.</b>
                                Перевод в другой банк может быть зачислен с большой задержкой
                                или через техническую поддержку.</span>
                            <span v-else class="infoText me-3">Пожалуйста, переведите указанную сумму по указанным реквизитам,
                                прикрепите чек перевода, а после – подтвердите перевод, нажав на
                                кнопку ниже.</span>
                        </div>
                        <div class="custom-file" style="margin-bottom: 30px">
                            <input @change="checkALlFields" id="js-file-1" style="cursor: pointer" @input="addChek" slot=""
                                class="image-file js-file custom-file__input" type="file" name="uploadFile" />
                            <label class="custom-file__label" for="js-file-1">
                                <i class="custom-file__icon"></i>
                                <span class="custom-file__text">
                                    <img src="/static/img/buy/custom-file.svg" width="26" height="24">
                                    <span v-if="chek != ''">{{ chek.name }}</span>
                                    <span v-else>Прикрепить чек перевода</span></span>
                            </label>
                        </div>
                        <button :disabled="proverka == true ? false : true" class="button button--full-width mb-3" @click="confirmPayment">
                            Подтвердить перевод
                        </button>
                    </div>

                    <!-- Шаг 4 -->
                    <div class="modal-body" v-if="step == 4 && status == ''">
                        <div class="text-left mb-3">
                            <span class="me-3" style="font-size: 16px">Ваша заявка на покупку {{ this.form.cryptoAmount }}
                                {{ crypto.short_title }} отправлена</span>
                        </div>
                        <button class="button button--full-width mb-3" @click="lastStep()">
                            Новая покупка
                        </button>
                    </div>

                    <!-- Успех -->
                    <div class="modal-body" v-if="step == 4 && status == 'Успех'">
                        <div style="text-align: left; margin-bottom: 20px">
                            <span class="me-3" style="font-size: 16px">Покупка {{ this.form.cryptoAmount }}
                                {{ crypto.short_title }} произошла успешно</span>
                        </div>
                        <button class="button button--full-width mb-3" @click="lastStep()">
                            Новая покупка
                        </button>
                    </div>

                    <!-- Неудача -->
                    <div class="modal-body" v-if="step == 4 && status == 'Отмена'">
                        <div style="text-align: left; margin-bottom: 20px">
                            <span class="me-3" style="font-size: 16px">Покупка {{ this.form.cryptoAmount }}
                                {{ crypto.short_title }} завершилась неудачей</span>
                        </div>
                        <button class="button button--full-width mb-3" @click="lastStep()">
                            Новая покупка
                        </button>
                    </div>
                    
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import CardForPopUp from "../Cards/CardForPopUp.vue";

export default {
    components: { CardForPopUp },
    props: ["cards", "courses", "crypto", "user", "courses_list", "for_buy", "bank_list", 'currencies'],
    data() {
        return {
            form: {
                bank: 0,
                bankName: "",
                sbp: null,
                amount: null,
                cryptoAmount: null,
                file: "",
                cardNumber: "",
            },
            proverka: false,
            chek: "",
            maxSize: 5242880,
            step: 1,
            bank_id: "",
            status: "",
            ourCard: "XXXX XXXX XXXX XXXX",
            title: "buy" + this.crypto.short_title,
            errorText: "",
            errorInput: "",
            placeHolder: "",
            query: false,
            country: "",
            transactionId: 0,
            showButton: false,
        };
    },
    filters: {
        format: (val) => `${val}`.replace(/(\d)(?=(\d{4})+([^\d]|$))/g, "$1 "),
    },
    created() {
        eventBus.$on('transactionUpdate', (pusherInfo) => {
            if(this.transactionId == pusherInfo.id)
            {
              this.step = 4;
              this.status = pusherInfo.status;
              this.form.cryptoAmount = pusherInfo.cryptocurrency_amount;
            }
        });
    },
    computed: {
        currency: function () {
            if (this.country == "kz") {
                return "KZT";
            } else {
                if (this.country == "uz") {
                    return "UZS";
                } else if (this.country == "ru") {
                    return "RUB";
                } else {
                    return "EUR";
                }
            }
        },

        currencyCourse() {
            if (this.courses_list && this.courses_list['Покупка']) {
                let course = this.courses_list['Покупка'].find(c => c.cryptocurrency_id_from === this.crypto.id 
                && c.currency_id_to === this.currencies.find(c => c.short_title === this.currency).id).rate;
                return parseFloat(course).toFixed(2);
            } else {
                return 1;
            }
        },

        courses_USDT2rub_rate() {
            console.log('courses_list', this.courses);
            if (this.courses_list && this.courses_list['Покупка']) {
                const btc2usdt = Object.values(this.courses_list['Покупка']).find(course => course.id == 1);
                return btc2usdt ? btc2usdt.rate : 0;
            }
            return 0;
        },
        courses_USDT2kzt_rate() {
            if (this.courses_list && this.courses_list['Покупка']) {
                const usdt2kzt = Object.values(this.courses_list['Покупка']).find(course => course.id == 3);
                return usdt2kzt ? usdt2kzt.rate : 0;
            }
            return 0;
        },
        courses_USDT2uzs_rate() {
            if (this.courses_list && this.courses_list['Покупка']) {
                const usdt2uzs = Object.values(this.courses_list['Покупка']).find(course => course.id == 5);
                return usdt2uzs ? usdt2uzs.rate : 0;
            }
            return 0;
        },
        courses_USDT2eur_rate() {
            if (this.courses_list && this.courses_list['Покупка']) {
                const usdt2eur = Object.values(this.courses_list['Покупка']).find(course => course.id == 54);
                return usdt2eur ? usdt2eur.rate : 0;
            }
            return 0;
        },

        currency_course: function () {
            let crs = 1;
            switch (this.currency) {
                case "UZS":
                    crs = this.courses_USDT2uzs_rate;
                    break;
                case "RUB":
                    crs = this.courses_USDT2rub_rate;
                    break;
                case "KZT":
                    crs = this.courses_USDT2kzt_rate;
                    break;
                case "EUR":
                    crs = this.courses_USDT2eur_rate;
                    break;
            }
            console.log('crs', crs);
            return crs;
        },

        // courseNow() {
        //     if (this.currency_course && this.courses) {
        //         return parseFloat(this.courses * this.currency_course).toFixed(2);
        //     } else {
        //         return 1;
        //     }
        // },
    },
    methods: {
        prev() {
            this.step--;
            this.errorText = "";
        },
        upd(type) {
            console.log(`Converting with type: ${type}, amount: ${this.form.amount}, currencyCourse: ${this.currencyCourse}`);
            this.showButton = false;
            setTimeout(() => {
                if (type == "rub" || type == "eur") {
                        this.form.cryptoAmount = (this.form.amount / this.currencyCourse).toFixed(this.crypto.count_after_point);
                        this.form.amount = Math.ceil(this.form.amount);
                } else {
                    this.form.amount = this.form.cryptoAmount * this.currencyCourse;
                }
                if (parseFloat(this.form.cryptoAmount) < 10 && this.crypto.id == 1) {
                    this.errorInput = "Минимальная сумма покупки 10 USDT";
                    return;
                } else {
                    this.errorInput = "";
                }
                if (parseFloat(this.form.cryptoAmount) < 0.0005 && this.crypto.id == 2) {
                    this.errorInput = "Минимальная сумма покупки 0.0005 BTC";
                    return;
                } else {
                    this.errorInput = "";
                }
                if (this.user.status['id']== 3) {
                    if (
                        parseFloat(this.form.cryptoAmount) > 100000 &&
                        this.crypto.id == 1
                    ) {
                        this.errorInput = "Максимальная сумма покупки 100000 USDT";
                        return;
                    } else {
                        this.errorInput = "";
                    }
                    if (parseFloat(this.form.cryptoAmount) > 0.3 && this.crypto.id == 2) {
                        this.errorInput = "Максимальная сумма покупки 0.3 BTC";
                        return;
                    } else {
                        this.errorInput = "";
                    }
                } else {
                    if (parseFloat(this.form.cryptoAmount) > 100 && this.crypto.id == 1) {
                        this.errorInput =
                            "Пройдите верификацию, чтобы совершать операции свыше 100 USDT";
                        return;
                    } else {
                        this.errorInput = "";
                    }
                    if (parseFloat(this.form.cryptoAmount) > 0.005 && this.crypto.id == 2) {
                        this.errorInput =
                            "Пройдите верификацию, чтобы совершать операции свыше 0.005 BTC";
                        return;
                    } else {
                        this.errorInput = "";
                    }
                }
                this.showButton = true;
                console.log(`Result - cryptoAmount: ${this.form.cryptoAmount}, amount: ${this.form.amount}`);
            }, 2500);
        },
        close(event) {
            this.$emit("close");
        },
        copyCard() {
            if (this.ourCard !== "XXXX XXXX XXXX XXXX") {
                navigator.clipboard.writeText(this.ourCard);
            }
        },
        buy(data) {
            this.$emit("buy", data);
        },
        firstStep() {
            if (this.form.bank != 0) {
                console.log("Выбранный банк: ", this.form.bank);
                this.step = 2;
            }
        },

        /*Второй шаг*/
        secondStep() {
            console.log('Второй шаг');
            if (this.errorInput != "" || this.form.amount == 0) {
                return;
            }
            this.query = true;
            let data = {
                bank_id: this.form.bank,
                amount: this.form.cryptoAmount,
                cryptocurrency_id: this.crypto.id,
                currency_id: this.currencies.find(c => c.short_title === this.currency).id,
                user_id: this.$user_ID.value,
                token: sessionStorage.getItem('user_token'),
            };
            let resp = "";
            axios
            .post(this.$api_address + "/transactions", data, {
                validateStatus: function (status) {
                    return status < 500;
                }
            }).then((response) => {
                resp = response.data;
                if (resp.message) {
                    this.errorText = resp.message;
                } else {
                    this.step = 3;
                    this.errorText = "";
                    this.bank_id = resp.data.bank_id;
                    this.ourCard = resp.data.card_number;
                    this.placeHolder = resp.data.card_owner;
                    this.transactionId = resp.data.id;
                    this.buy(resp);
                }
                this.query = false;
                
            });
        },

        /*Третий шаг*/
        confirmPayment() {
            let formData = new FormData();
            formData.append('proof', this.chek);
            formData.append("token", sessionStorage.getItem('user_token'));
            formData.append('_method', 'PATCH');
            axios
            .post(this.$api_address + "/transactions/" + this.transactionId + '/proof', formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                if (response.data.message) {
                    this.errorText = response.data.message;
                } else {
                    this.chek = '';
                    this.step = 4;
                    this.errorText = "";
                    this.buy(response.data.data);
                }
            });
        },

        addChek(event) {
            if (
                (event.target.files[0].type == "image/png" 
                || event.target.files[0].type == "image/jpeg"
                /*|| event.target.files[0].type == "application/pdf"*/) 
                && event.target.files[0].size < this.maxSize
            ) {
                this.chek = event.target.files[0];
            } else {
                alert("Неверный формат (нужен jpeg или png) или размер файла превышает 5Мб");
                this.chek = "";
                event.target.value = '';
            }
        },
        checkALlFields() {
            if (this.chek != "") {
                this.proverka = true;
            } else {
                this.proverka = false;
            }
        },

        lastStep() {
            this.step = 1;
            this.bank = 0;
            this.form.amount = null;
            this.form.cryptoAmount = null;
            this.form.file = "";
            this.cardNumber = "";
            this.errorText = "";
            this.error = "";
            this.form.bank = "";
            this.errorInput = "";
            this.proverka = false;
            this.$emit("new_buy");
        },
        putCardNumber(data) {
            this.form.cardNumber = data.number;
            this.form.bank = data.bank_id;
            this.form.sbp = data.sbp_bank_id;
            this.country = data.country;
        },
        newCard() {
            this.$emit("new_card");
        },
    },
};
</script>

<style scoped>
.copyCard_button {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    text-decoration: underline;
    text-underline-offset: 2px;
    gap: 5px;
}

.custom-file__text {
    color: #4AA485;
    text-decoration: underline;
    text-underline-offset: 4px;
    display: flex;
    align-items: flex-end;
    gap: 4px;
}
/*.custom-file__text span {
    width: 500px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
}*/
</style>
